import React, {useState, useEffect, Fragment} from 'react';
import {useParams, useNavigate, Link} from 'react-router-dom';
import {ToastContainer, toast} from "react-toastify";
import classNames from 'classnames';
import 'react-toastify/dist/ReactToastify.css';
import {useAppContext} from "../../context/app-context";
import OrderSummary from '../common/OrderSummary';
import variables from '../../assets/sass/variables.scss';
import routes from '../../config/routes';
import {addServer, getConfigureInitialData} from "../../config/ajax";

const ConfigureServer = () => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const [eye, setEye] = useState({
    icon: "eye-slash.svg",
    type: "password"
  });
  const [errors, setErrors] = useState({
    username: '',
    password: '',
    name: '',
    osImageId: '',
    periodId: '',
    ssh_key: ''
  });
  const [checkpoint, setCheckpoint] = useState(0);

  const navigate = useNavigate();
  const {id} = useParams();
  const {initialData, setInitialData, setNewOrder, formData, setFormData} = useAppContext();
  const {name, OS, username, connectionRadiosId, sshKeyContent, password, periodPrice, periodString, periodId, osImageId, sshAuth} = formData;

  const regexNumInPass = /\d/;                                    // Contains at least one number    
  const regexCapLet = /(?<!^|\s)[A-Z](?![A-Z]|\s|$)/;             // Contains an uppercase letter that is not the first or last character
  const regexLastNotDigOrSym = /[^0-9!@#$%^&*()_+=-]$/i;          // Last character cannot be number or special symbol

  useEffect(() => {
    setFormData({
      osImageId: 0,
      OS: '',
      periodId: 0,
      periodString: '',
      periodPrice: '',
      name: '',
      connectionRadiosId: 0,
      username: '',
      sshAuth: 0,
      sshKeyContent: '',
      password: ''
    })

    getConfigureInitialData(id)
      .then((res) => {
        setInitialData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = e => {
    setFormData({
      ...formData, 
      [e.target.id]: e.target.value
    })
  };

  const handleSelectChange = e => {
    const foundIndex = initialData.osImages.findIndex(el => el.id === +e.target.value);
    setFormData({
      ...formData,
      osImageId: +e.target.value,
      OS: initialData.osImages[foundIndex].os,
    });
  };

  const handlePeriodRadiosChange = (e) => {
    setErrors({
      ...errors,
      periodId: ''
    })

    setFormData({
      ...formData,
      periodId: +e.target.id,
      periodString: e.target.dataset.name,
      periodPrice: e.target.dataset.price
    });
  }; 

  const handleConnectionRadioChange = e => {
    setFormData({
      ...formData, 
      connectionRadiosId : +e.target.id,
      sshAuth: +e.target.id
    })
  };

  const onClickCopy = e => {

    toast('Copied to clipboard', {
      className: "toast-login",
      position: "bottom-center",
      autoClose: 5000,
      closeButton: false,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    navigator.clipboard.writeText(e.target.dataset.text)
  };

  const onMouseOverTooltipIcon = e => {
    setIsTooltipVisible(true);
  };

  const onMouseLeaveTooltipIcon = e => {
    setIsTooltipVisible(false);
  };

  const changePasswordInputIcon = e => {
    if (eye.type === "password") {
      setEye({
        icon: "eye.svg",
        type: "text"
      })
    } else {
      setEye({
        icon: "eye-slash.svg",    
        type: "password"
      })
    }
  };

  const redirectTo = (id, orderId) => (
    navigate(`/products/${id}/configure/${orderId}`)
  );

  const removeErrors = (e) => {
    setErrors({
      ...errors,
      [e.target.name]: ''
    })
  }

  const createPostData = (e) => {
    e.preventDefault();

    setIsLoadingNext(true);

    const dataPass = {
      productId: +id,
      periodId: periodId, 
      osImageId: osImageId,
      name: name,
      username: username,
      ssh_auth: sshAuth,
      ssh_key: sshKeyContent,
      password: password
    };

    const dataSSH = {
      productId: +id,
      periodId: periodId, 
      osImageId: osImageId,
      name: name,
      username: username,
      ssh_auth: sshAuth,
      ssh_key: sshKeyContent,
      password: password
    };

    setNewOrder(+sshAuth === 1 ? dataSSH : dataPass);

    addServer(+sshAuth === 1 ? dataSSH : dataPass)
      .then((res) => {
        setIsLoadingNext(false);
        redirectTo(id, res.data.id);
      })
      .catch((err) => {
        setIsLoadingNext(false);
        err.response.data.data && (
          setErrors(err.response.data.data)
        )

        if (err.response.data.code === 15030) {
          setCheckpoint(1);
        } 
      })
  };

  const selectClasses = classNames('input', {
    'is-danger': errors.osImageId
  });

  const machineNameClasses = classNames('machine-name', 'input', {
    "is-danger": errors.name
  });

  const usernameClasses = classNames('username', 'input', {
    "is-danger": errors.username
  });

  const passwordClasses = classNames('password', 'input', {
    "is-danger": errors.password
  });

  const sshKeyClasses = classNames('input', 'is-fullheight', {
    'is-danger': errors.ssh_key
  });

  const eyeClasses = classNames("eye", 'is-clickable', {
    // "go-up": errors.password 
  });

  const connectionTickClasses = (id) => classNames('tick', {
    'active' : id === connectionRadiosId
  });

  const tickClasses = (id) => classNames('tick', {
    'active' : id === periodId
  });

  const tooltipIconClasses = classNames('tooltip-icon', 'is-relative', {
    'has-background-creme': isTooltipVisible,
    'has-background-paleblue' : !isTooltipVisible,
  });

  const passTickClasses = classNames('password-requirements', {
    'is-dot': password?.length === 0,
    'is-tick': password?.length >= 8,
    'is-x': password?.length < 8 && password?.length !== 0
  });

  const passTickClasses2 = classNames('password-requirements', {
    'is-dot' : password?.length === 0,
    'is-tick' : regexCapLet.test(password),
    'is-x' : !regexCapLet.test(password) && password?.length !== 0
  });

  const passTickClasses3 = classNames('password-requirements', {
    'is-dot' : password?.length === 0,
    'is-tick' : regexNumInPass.test(password),
    'is-x' : !regexNumInPass.test(password) && password?.length !== 0
  });

  const passTickClasses4 = classNames('password-requirements', {
    'is-dot' : password?.length === 0,
    'is-tick' : regexLastNotDigOrSym.test(password),
    'is-x' : !regexLastNotDigOrSym.test(password) && password?.length !== 0
  });

  const passBorderClasses = classNames('password-info', {
    'is-passed' : password?.length >= 8 && regexCapLet.test(password) && regexNumInPass.test(password) && regexLastNotDigOrSym.test(password)
  });

  const nextButtonClasses = classNames('button', 'is-large', {
    "is-loading": isLoadingNext
  });

  return (
    <div className='ConfigureServer section'>
      {checkpoint === 1 ? (
        <div className="is-fit-content is-fullheight mx-auto">
          <div className="has-text-centered mb-6">
            <div className="is-size-3 has-text-sky luck">ooops...</div>
            <div className="is-size-2 is-bold has-text-marine">Unable to create server</div>
          </div>
          <div className='has-text-centered is-fullheight my-auto'>
            <span className='is-bold is-size-3-half'>We're sorry, currently we're unable to create your machine.</span>
            <Link to={routes.contacts} className='button is-large is-fit-content mx-auto mt-4'>Contact us</Link>
          </div>
        </div>
      ) : (
        <Fragment>
          {/* <div className='container'> */}
            <div className='left'>              
              <h1 className='is-size-3-half has-text-marine is-bold mb-3'>Configure your machine</h1>
              <form action="" autoComplete="off">
                <Fragment>
                  <label className='label'>Choose the period</label>
                  <div 
                    className='radio-wrapper' 
                    name='periodId'
                  >
                    {initialData?.product?.prices?.map((el) => (
                      <div 
                        key={el.periodId}
                        className='radio-panel'
                        id={el.periodId}
                        data-price={el.price}
                        data-name={el.period}
                        onClick={handlePeriodRadiosChange}
                      >
                        <div className='is-flex is-align-items-center pointer-events-none'>
                          <div className={tickClasses(el.periodId)}/>
                          <span className='ml-2'>{el.period}</span>
                        </div>
                        <span className='is-bold is-size-4' id={el.periodId}>{el.price}</span>
                        {/* {el.periodId === 1 && <span className='tip button is-small'>Best value</span>} */}
                      </div>
                    ))}
                    {errors.periodId && (<span className="errors">{errors.periodId}</span>)}
                  </div>
                </Fragment>
                <div className='field'>
                  <div className="select">
                    <label htmlFor="OS" className="label">Choose your OS</label>
                    <select
                      defaultValue={osImageId}
                      onChange={handleSelectChange}
                      className={selectClasses}
                      onFocus={removeErrors}
                      name="osImageId" 
                      id="OS" 
                      required
                    >
                      <option 
                        disabled 
                        value={0}
                      >
                        Select
                      </option>
                      {initialData?.osImages?.map((el) => (
                        <option 
                          key={el.id}
                          value={el.id}
                          data-os={el.os}
                        >
                          {el.os}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors.osImageId && (<div className="errors">{errors.osImageId}</div>)}
                </div>

                <div className="field">
                  <label htmlFor="name" className="label">Name your machine</label>
                  <div className="control">
                    <input 
                      onChange={handleInputChange}
                      onFocus={removeErrors}
                      className={machineNameClasses}
                      value={name}
                      type="text" 
                      name="name" 
                      id="name" 
                      placeholder="Enter name"
                    />
                    {errors.name && (<span className="errors">{errors.name}</span>)}
                  </div>
                </div>

                <div htmlFor="authMethod" className="auth-method">Authentication Method</div>

                <div className='auth-wrapper'>  
                  <div 
                    className='radio-panel'
                    onClick={handleConnectionRadioChange}
                    id={1}
                    name='authMethod'
                  >
                    <div className='is-flex is-align-items-center pointer-events-none'>
                      <div 
                        className={connectionTickClasses(1)} 
                      />
                      <div className='ml-3'>
                        <div className='is-size-6 nowrap is-bold'>Public SSH Key</div>
                        <div className='is-size-7 nowrap'>Connect via an SSH key pair</div>
                      </div>
                    </div>
                    <img
                      className={tooltipIconClasses}
                      onMouseOver={onMouseOverTooltipIcon}
                      onMouseLeave={onMouseLeaveTooltipIcon}
                      alt='bulb'
                      width={42}
                      height={42}
                      src="/images/lightbulb.svg#bulb-svg"
                    />
                    {isTooltipVisible && (
                      <div className='bulb-tooltip is-size-6 '>
                        Secure Socket Shell (SSH) is a cryptographic protocol, used to enable secure access to your remote machines. 
                        It operates on public key cryptography that provides a mechanism for mutual authentication between the server 
                        and the client and establishes an encrypted channel of communication between them over an unsecured network.
                      </div>
                    )}
                  </div>

                  <div
                    className='radio-panel'
                    onClick={handleConnectionRadioChange}
                    id={0}
                  >
                    <div className='is-flex is-align-items-center pointer-events-none'>
                      <div className={connectionTickClasses(0)}/>
                      <div className='ml-3'>
                        <div className='is-size-6 nowrap is-bold'>Password</div>
                        <div className='is-size-7 nowrap'>Connect via password</div>
                      </div>
                    </div>
                  </div>
                </div>

                {!!connectionRadiosId && (          
                  <div className='via-ssh-wrapper is-flex'>
                    <div className='ssh-input'>
                      <div className="field">
                        <label htmlFor="username" className="label">Enter username</label>
                        <div className="control">
                          <input 
                            onFocus={removeErrors}
                            onChange={handleInputChange}
                            className={usernameClasses}
                            type="text" 
                            value={username}
                            name="username" 
                            id="username" 
                            placeholder="Type your username..." 
                          />
                          {errors.username && (<span className="errors">{errors.username}</span>)}
                        </div>
                      </div>
                      <div className="field">
                        <label htmlFor="sshKeyContent" className="label">Add public SSH key</label>
                        <textarea 
                          onChange={handleInputChange}
                          onFocus={removeErrors}
                          value={sshKeyContent}
                          className={sshKeyClasses} 
                          name="ssh_key" 
                          id="sshKeyContent" 
                          cols="30" 
                          rows="7" 
                          placeholder='SSH key content'
                        />
                        {errors.ssh_key && (<span className="errors">{errors.ssh_key}</span>)}
                      </div>
                    </div>
                    <div className='info'>
                      <ToastContainer/>
                      <div className='is-bold is-size-6'>SSH Keys</div>
                      <div className='is-size-6-half'>Follow these instructions to create or add SSH keys on Linux, MacOS & Windows. Windows users without OpenSSH can install and use PuTTY instead.</div>
                      <div className='is-bold is-size-6 mt-3'>Create a new key pair, if needed</div>
                      <div className='is-size-6-half'>Open a terminal and run the following command:</div>
                      <div className='is-flex is-justify-content-space-between has-background-paleblue p-3 has-radius mt-3' >
                        ssh-keygen 
                        <span 
                          data-text="ssh-keygen"
                          onClick={onClickCopy}
                          className='is-span effect underlined-animated is-flex is-align-items-center has-text-marine is-bold is-size-5-half is-clickable' 
                        >
                          Copy
                          <img
                            className='ml-1' 
                            src="/images/copy.svg" 
                            alt="copy" 
                            width={16} 
                            height={16}
                          />
                        </span>
                      </div>
                      <div className='is-size-6-half my-3'>You will be prompted to save and name the key.</div>
                      <div className='has-background-paleblue has-radius p-3'>
                        Generating public/private rsa key pair. Enter file in which to save the key (/Users/USER/.ssh/id_rsa):
                      </div>
                      <div className='is-size-6-half my-3'>
                        Next you will be asked to create and confirm a passphrase for the key (highly recommended):
                      </div>
                      <div className='has-background-paleblue has-radius p-3'>
                        Enter passphrase (empty for no`passphrase): Enter same passphrase again:
                      </div>
                      <div className='is-size-6-half my-3'>
                        This will generate two files, by default called <br />
                        <span className='has-background-paleblue has-radius mx-1 p-1'>id_rsa</span>
                        and
                        <span className='has-background-paleblue has-radius mx-1 p-1'>id_rsa.pub</span>
                        Next, add this public key
                      </div>
                      <div className='is-bold is-size-5-half mt-3'>Add the public key</div>
                      <div className='is-size-6-half'>
                        Copy and paste the contents of the 
                        <span className='is-bold mx-1'>.pub</span>
                        file, typically id_rsa.pub, into the 
                        <span className='is-bold mx-1'>SSH key content</span>
                        field on the left.
                      </div>
                      <div className='is-flex is-justify-content-space-between has-background-paleblue p-3 has-radius mt-3' >
                        cat ~/.ssh/id_rsa.pub 
                        <span 
                          data-text="cat ~/.ssh/id_rsa.pub"
                          onClick={onClickCopy}
                          className='is-span effect underlined-animated is-flex is-align-items-center has-text-marine is-bold is-size-5-half is-clickable' 
                        >
                          Copy
                          <img
                            className='ml-1' 
                            src="/images/copy.svg" 
                            alt="copy" 
                            width={16} 
                            height={16}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {!connectionRadiosId && (
                  <div className='via-pass-wrapper'>
                    <div className='fields-wrapper'>
                      <div className="field">
                        <label htmlFor="username" className="label">Enter username</label>
                        <div className="control">
                          <input 
                            onFocus={removeErrors}
                            onChange={handleInputChange}
                            className={usernameClasses}
                            type="text" 
                            value={username}
                            name="username" 
                            id="username" 
                            placeholder="Type your username..." 
                          />
                          {errors.username && (<span className="errors">{errors.username}</span>)}
                        </div>
                      </div>

                      <div className="field">
                        <label htmlFor="password" className="password-label label">Create password</label>
                        <div className="control">
                          <input 
                            onChange={handleInputChange}
                            value={password}
                            className={passwordClasses}
                            onFocus={removeErrors}
                            type={eye.type} 
                            id="password" 
                            name="password" 
                            placeholder="Type your password..." 
                          />
                          <img
                            className={eyeClasses}
                            src={`/images/${eye.icon}`}
                            onClick={changePasswordInputIcon}
                            alt="eye"
                            width="32"
                            height="32"
                          />
                        </div>
                        {errors.password && (<span className="errors">{errors.password}</span>)}
                      </div>
                    </div>
                    <div className={passBorderClasses}>
                      <ToastContainer/>
                      <div className='is-bold is-size-6'>Password requirements</div>
                      <ul className='is-size-6-half'>
                        <li>
                          <span className={passTickClasses}></span>
                          Must be at least 8 characters long
                        </li>
                        <li>
                          <span className={passTickClasses2}></span>
                          Must contain 1 uppercase letter (cannot be first or last character)
                        </li>
                        <li>
                          <span className={passTickClasses3}></span>
                          Must contain 1 number
                        </li>
                        <li>
                          <span className={passTickClasses4}></span>
                          Cannot end in a number or special character
                        </li>
                      </ul>
                      <div className='is-flex is-justify-content-space-between is-size-6-half has-background-paleblue p-3 has-radius mt-3' >
                        <img
                          className='mr-3' 
                          src="/images/shield-exclamation.svg" 
                          alt="shield-exclamation" 
                          width={20} 
                          height={24}
                        />
                        Please store your password securely.
                        You will not be sent an email containing 
                        the machine’s details or password.                    
                      </div>
                    </div>
                  </div>
                )}

                <div className='button-wrapper mt-3'>
                  <button 
                    className={nextButtonClasses}
                    onClick={createPostData}
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>

            <div className='right'>
              <OrderSummary
                icon={initialData?.product?.icon}
                type={initialData?.product?.type}
                color={initialData?.product?.color}
                cpu={initialData?.product?.cpu}
                ram={initialData?.product?.ram}
                disk={initialData?.product?.disk}
                OS={OS}
                periodPrice={periodPrice}
                periodString={periodString}
                name={name}
                connectionRadiosId={connectionRadiosId}
              />
            </div>
          {/* </div>   */}
        </Fragment>
      )}
      <style global jsx>{`
        .ConfigureServer {
          flex-grow: 1;
          margin-top: ${variables.headerMargin};
          background-color: ${variables.paleblue};
          .left {
            width: 60%;
            @media (min-width: ${variables.desktopMin}) and (max-width: ${variables.widescreenMax}) { 
              width: 60%;
            }
            @media all and (max-width: ${variables.desktopMin}) {
              width: 40%;
            }
            .errors {
              color: ${variables.pink};
            }
            .field {
              width: 50%;
              height: 100%; 
              .control {
                width: 100%;
                height: 100%;
              }
            }
            .select {
              width: 100%;
            }
            .auth-method {
              font-weight: bold;
              margin-bottom: 0.5rem;
            }
            .radio-wrapper {
              display: flex;
              gap: 1rem;  
              flex-direction: column;
              margin-bottom: 0.75rem;
              .radio-panel {
                width: 50%; 
                .tip {
                  position: absolute;
                  top: -1rem;
                  right: -0.75rem;
                  cursor: default;
                }
                .discount {
                  font-weight: bold;
                  font-size: 0.875rem;
                  color: ${variables.pink};
                  position: absolute;
                  top: 0.5rem;
                  right: 1.375rem;
                }
              }
            }
            .auth-wrapper {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 1rem;
              margin-bottom: 0.75rem;
              input {
                width: 100%;
              }
              .radio-panel {
                width: 100%; 
                .tooltip-icon {
                  border-radius: 50%; 
                  padding: 0.75rem;
                  transition: all 200ms ease;
                  &.make-white {
                    fill: white;
                  }
                }
                .bulb-tooltip {
                  position: absolute;
                  top: -11.5rem;
                  left: -1rem;
                  background: white;
                  padding: 1rem;
                  border-radius: ${variables.radius};
                  box-shadow: 0px 0px 50px #00000026;
                  z-index: 5;
                }
                .tip {
                  position: absolute;
                  top: -1rem;
                  right: -0.75rem;
                }
                .discount {
                  font-weight: bold;
                  font-size: 0.875rem;
                  color: ${variables.pink};
                  position: absolute;
                  top: 0.5rem;
                  right: 1.375rem;
                }
              }
            }
            .via-pass-wrapper {
              display: flex;
              flex-direction: row;
              .fields-wrapper {
                width: 50%;
                height: fit-content;
                .field {
                  width: 100%;
                }
              }
              .password-info {
                width: 50%;
                margin-left: 1rem;
                padding: 0.625rem 1rem;
                border-radius: ${variables.radius};
                border: 2px dashed ${variables.pink};
                background-color: white;
                transition: all 250ms ease; 
                &.is-passed {
                  border: 2px dashed lightgreen;
                }
              }
            }
            .ssh-input {
              width: 50%;
              .field {
                width: 100%;
                height: fit-content;
              }
            }
            .info {
              width: 50%;
              overflow-y: scroll;
              max-height: 27.25rem; 
              margin-left: 1rem;
              padding: 0.625rem 1rem;
              border-radius: ${variables.radius};
              border: 2px dashed #DADCDF;
              background-color: white;
            }
          }
          .right {
            width: 10%;
          }
          @media all and (max-width: ${variables.mobileMax}) {
            margin-bottom: ${variables.footerHeight}; 
            .container {
              gap: 0;
              .left {
                width: 100%;
                .field {
                  width: 100%;
                }
                .radio-wrapper {
                  .radio-panel {
                    width: 100%;
                  }
                }
                .auth-wrapper {
                  flex-wrap: wrap;
                  .radio-panel {
                    .bulb-tooltip {
                      top: -16rem;
                      left: 0;
                    }
                  }
                }
                .via-pass-wrapper {
                  flex-direction: column-reverse;
                  .password-info {
                    width: 100%;
                    margin: 0 0 0.75rem 0;
                  }
                  .fields-wrapper {
                    width: 100%;
                  }
                }
                .via-ssh-wrapper {
                  flex-direction: column-reverse;
                  .ssh-input {
                    width: 100%;
                  }
                  .info {
                    width: 100%;
                    margin: 0 0 0.75rem 0;
                  }
                }
                .button {
                  margin: 0 auto;
                }
              }
            }
          }
        }
      `}</style>
    </div>
  )
}

export default ConfigureServer;
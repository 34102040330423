import {useState} from 'react';
import {useLocation} from "react-router-dom";
import classNames from 'classnames';
import routes from "../../config/routes";
import variables from "../../assets/sass/variables.scss";

const OrderSummary = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const {
    icon, 
    type, 
    color, 
    cpu, 
    ram, 
    disk, 
    OS, 
    periodString, 
    periodPrice, 
    name, 
    connectionRadiosId, 
    isMobile, 
    notFixed, 
    isCentered, 
    withoutArrow, 
    withoutInfo,
    ip
  } = props;

  const location = useLocation();

  const isPaymentRoute = location.pathname === routes.paymentMethods;

  const toggleOrderSummary = () => {
    setIsCollapsed(!isCollapsed)
  };
  
  const positionClassNames = classNames("OrderSummary", {
    "is-relative": isPaymentRoute || isMobile,
    "is-fixed": !isPaymentRoute && !notFixed,
    'is-collapsed': isCollapsed,
    'mx-auto': isCentered
  });

  const arrowClasses = classNames('arrow-down', 'is-black', 'is-hidden-desktop', {
    'is-reverted': isCollapsed,
    'is-hidden': withoutArrow
  });

  const labelClasses = classNames('has-text-centered', 'is-size-4', 'is-size-6-touch', 'is-bold', {
    'is-hidden': !isCollapsed && isMobile
  });

  return (
    <div className={positionClassNames}>
      <div className='is-flex is-align-items-center is-justify-content-space-between'>
        <div className={labelClasses}>Order summary</div>
        <div className={arrowClasses} onClick={toggleOrderSummary}/>
      </div>
      <div className='is-flex is-justify-content-space-between mt-5'>
        <div>
          <img 
            className="icon-type"
            src={`/images/${icon}.svg`} 
            alt="icon-type" 
            width={36} 
            height={36}
          />
          <span className={`luck has-text-${color} is-size-2 is-size-3-touch`}>{type}</span>
        </div>
        <span className='has-text-marine is-size-4 is-bold'>{periodPrice || "$-.--"}</span>
      </div>
      <hr className='dashed'/>
      <div className='mini-block-wrapper'>
        <div className='mini-block'>
          <div>
            <img 
              src="/images/cpu.svg" 
              alt="cpu"
              width={30}
              height={32}   
            />
            <div className="is-size-6 has-text-sky is-bold">CPU</div>
          </div>
          <span className='is-size-6 has-text-marine is-bold'>{cpu}</span>
        </div>
        <div className='mini-block'>
          <div>
            <img 
              src="/images/ram.svg" 
              alt="ram"
              width={30}
              height={32}   
            />
            <div className="is-size-6 has-text-sky is-bold">RAM</div>
          </div>
          <span className='is-size-6 has-text-marine is-bold'>{ram}</span>
        </div>
        <div className='mini-block'>
          <div>
            <img 
              src="/images/hdd.svg" 
              alt="hdd"
              width={30}
              height={32}   
            />
            <div className="is-size-6 has-text-sky is-bold">Disk</div>
          </div>
          <span className='is-size-6 has-text-marine is-bold'>{disk}</span>
        </div>
        <div className='mini-block'>
          <div>
            <img 
              src="/images/ip.svg" 
              alt="ip"
              width={30}
              height={32}   
            />
            <div className="is-size-6 has-text-sky is-bold">IP</div>
          </div>
          <span className='is-size-6 has-text-marine is-bold'>{ip || '1 Public'}</span>
        </div>
      </div>
     {!withoutInfo && (
        <ul className='characteristics'>
          <li>
            <span className='is-bold'>OS: </span>
            {OS || 'Not selected'}
          </li>
          <li> 
            <span className='is-bold'>Period: </span>
            {periodString || 'Not selected'}
          </li>
          <li>
            <span className='is-bold'>Machine name: </span>
            {name || 'Not selected'}
          </li>
          <li>
            <span className='is-bold'>Authentication method: </span>
            {
              (connectionRadiosId === 1 && ('via SSH')) || 
              (connectionRadiosId === 0 && ('via Password')) || 
              'Not selected'
            }
          </li>
        </ul>
      )}
      <style jsx>{`
        .OrderSummary {
          top: ${!isPaymentRoute && !isMobile ?  `calc(${variables.headerMargin} + 1rem)` : '1rem'};
          right: ${!isPaymentRoute ? '3rem' : 0};
          width: fit-content;
          height: fit-content;
          padding: 1.875rem;
          border-radius: ${variables.radius};
          box-shadow: 0px 0px 20px #0000000D;
          background-color: white;
          .icon-type {
            margin-bottom: -0.33rem;
            margin-right: 0.75rem;
          }
          hr {
            margin: 0.5rem 0;
          }
          .mini-block-wrapper {
            display: flex;
            justify-content: space-between;
            gap: 1rem;
            .mini-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              text-align: center;
              width: 6rem;
              padding: 1.875rem;
              white-space: nowrap;
              background-color: white;
              box-shadow: 0px 0px 30px #0000001A;
              border-radius: ${variables.radiusExtra};
            }
          }
          .characteristics {
            li {
              margin-top: 1rem;
            }
          }
          &.is-fixed {
            position: fixed;
          }
          @media all and (max-width: ${variables.mobileMax}) {
            width: 100%;
            height: 15%;
            top: unset;
            bottom: 0;
            left: 0;
            padding: 1rem;
            z-index: 100;
            border-radius: 0;
            transition: all 500ms ease;
            &.is-collapsed {
              height: ${withoutInfo ? '44%' : '60%'};
            }
            .icon-type {
              width: 1.5rem;
              height: 1.5rem;
              margin: 0;
            }
            hr {
              margin-block: 1.5rem;
            }
            .mini-block-wrapper {
              flex-wrap: nowrap;
              justify-content: center;
              margin: 0;
              gap: 0.5rem;
              .mini-block {
                padding: 0.5rem;
                img {
                  width: 2rem;
                  height: 2rem;
                }
              }
            }
            .arrow-down {
              position: absolute;
              top: 0.5rem;
              right: 1rem;
              transform: rotate(90deg); 
            }
          }
        }
      `}</style>
    </div>
  )
}

export default OrderSummary;
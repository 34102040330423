import React, {Fragment, useState} from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";
import routes from "../../config/routes";
import variables from "../../assets/sass/variables.scss";
import screenVariables from "../../assets/sass/screensVariables.scss";
import {onLoginChangePassword} from "../../config/ajax";
import {getUserId} from "../../config/methods";

const ChangePassword = props => {
  const [data, setData] = useState({
    oldPassword: '',
    password: '',
    confPassword: ''
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [notification, setNotification] = useState(false);
  const [oldPasswordInputRef, setOldPasswordInputRef] = useState({
    icon: "eye-slash.svg",
    type: "password"
  });
  const [newPasswordInputRef, setNewPasswordInputRef] = useState({
    icon: "eye-slash.svg",
    type: "password"
  });
  const [confirmPasswordInputRef, setConfirmPasswordInputRef] = useState({
    icon: "eye-slash.svg",
    type: "password"
  });
  const [timeoutId, setTimeoutId] = useState(0);

  const changeOldPasswordIcon = (e) => {
    if (oldPasswordInputRef.type === "password") {
      setOldPasswordInputRef({
        icon: "eye.svg",
        type: "text"
      })
    } else {
      setOldPasswordInputRef({
        icon: "eye-slash.svg",    
        type: "password"
      })
    }
  }; 

  const changeNewPasswordIcon = (e) => {
    if (newPasswordInputRef.type === "password") {
      setNewPasswordInputRef({
        icon: "eye.svg",
        type: "text"
      })
    } else {
      setNewPasswordInputRef({
        icon: "eye-slash.svg",    
        type: "password"
      })
    }
  }; 

  const changeConfirmPasswordIcon = (e) => {
    if (confirmPasswordInputRef.type === "password") {
      setConfirmPasswordInputRef({
        icon: "eye.svg",
        type: "text"
      });
    } else {
      setConfirmPasswordInputRef({
        icon: "eye-slash.svg",    
        type: "password"
      })
    }
  }; 

  const handleDataChange = (e) => {
    setData({
      ...data,
      [e.target.id]: e.target.value
    });
  };

  const validateForm = () => {
    const obj = {};
    if (data.oldPassword.length === 0) {
      obj.oldPassword = "Old password is required!"
    }

    if (data.password.length === 0) {
      obj.password = "Password is required!"
    }

    if (data.password !== data.confPassword) {
      obj.confPassword = "Passwords doesn't match!"
    } else if (data.password.length === 0) {
      obj.confPassword = "Confirmation password is required!"
    }

    return obj;
  };

  const removeError = (e) => {
    setErrors(prevState => ({                                                      
      ...prevState,
      [e.target.id] : ''
    }))
  };

  const sendData = (e) => {
    e.preventDefault();

    let errors = validateForm();

    if (Object.keys(errors).length === 0) {

      setIsLoading(true);
      let userId = getUserId();

      let obj = {
        currentPassword: data.oldPassword,
        password: data.password,
        repeatPassword: data.confPassword
      };

      onLoginChangePassword(userId, obj)
        .then((res) => {
          setIsSuccessful(true);
          setIsLoading(false);
        })
        .catch((error) => {
            setNotification(true);
            setIsLoading(false);
            const timeout = setTimeout(() => {
              setNotification(false);
            }, 4000);
            setTimeoutId(timeout);
        }) 
    } else {
      setErrors(errors);
    }
      
    clearTimeout(timeoutId)
  };

  const notificationWrapperClasses = classNames('notification-wrapper', {
    'is-hidden': !notification
  });

  const oldPasswordClasses = classNames('input', {
    'is-danger': errors.oldPassword
  });

  const newPasswordClasses = classNames('input', {
    "is-danger": errors.password
  });

  const confirmPasswordClasses = classNames('input', {
    "is-danger": errors.confPassword
  });

  const buttonClasses = classNames('button', 'blue', 'is-large', 'is-fit-content', 'mx-auto', {
    'is-loading': isLoading
  });

  return (
    <div className="ChangePassword">
      <section className="section">
        {isSuccessful ? (
          <Fragment>
            <h1 className="title has-text-centered">
              New password
              <div className="has-text-sky luck is-block">created</div>
            </h1>
            <div className="logo-wrapper">
              <img 
                src="/images/reset-password-animate.svg" 
                alt="change-password" 
                className="change-password"    
                width="360"
                height="334"
              />
            </div>
            <div className="text-wrapper">
              <div className="text2">
                <Link to={routes.myAccount} className="links">
                  Back to Dashboard
                </Link>
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <h1 className="title has-text-centered">
              Create new
              <div className="has-text-sky luck is-block">password</div>
            </h1>
            <div className={notificationWrapperClasses}>
              <div className="notification"> 
                <div className="notification-text">Your old password is incorrect!</div>
              </div>
            </div>
            <form id="myForm" method="get" noValidate onSubmit={sendData}>
              <div className="field">
                <label htmlFor="oldPassword" className="label">Old password</label>
                <div className="control">
                  <input 
                    onChange={handleDataChange}
                    value={data.oldPassword}
                    className={oldPasswordClasses}
                    onFocus={removeError}
                    type={oldPasswordInputRef.type}
                    id="oldPassword" 
                    name="password" 
                    placeholder="Enter your old password"
                    autoComplete="old-password"
                  />
                  <img
                    className="eye"
                    src={"/images/" + oldPasswordInputRef.icon}
                    onClick={changeOldPasswordIcon}
                    alt="eye"
                    width="32"
                    height="32"
                  />
                </div>
                {errors.oldPassword && ( <span className="errors">{errors.oldPassword}</span>)}
              </div>  

              <div className="field">
                <label htmlFor="password" className="label">New password</label>
                <div className="control">
                  <input 
                    onChange={handleDataChange}
                    value={data.password}
                    className={newPasswordClasses}
                    onFocus={removeError}
                    type={newPasswordInputRef.type}
                    id="password" 
                    name="password" 
                    placeholder="Enter new password"
                    autoComplete="new-password"
                  />
                  <img
                    className="eye"
                    src={"/images/" + newPasswordInputRef.icon}
                    onClick={changeNewPasswordIcon}
                    alt="eye"
                    width="32"
                    height="32"
                  />
                </div>
                {errors.password && (<span className="errors">{errors.password}</span>)}
              </div>  

              <div className="field">
                <label htmlFor="confPassword" className="label">Confirm new password</label>
                <div className="control">
                  <input 
                    onChange={handleDataChange}
                    value={data.confPassword}
                    className={confirmPasswordClasses}
                    onFocus={removeError}
                    type={confirmPasswordInputRef.type} 
                    id="confPassword" 
                    name="password" 
                    placeholder="Confirm new password"
                    autoComplete="new-password"
                  />
                  <img
                    className={"eye"+ (errors.password ? " go-up" : "")}
                    src={"/images/" + confirmPasswordInputRef.icon}
                    onClick={changeConfirmPasswordIcon}
                    alt="eye"
                    width="32"
                    height="32"
                  />
                </div>
                {errors.confPassword && (<span className="errors">{errors.confPassword}</span>)}
              </div>
              
              <button 
                className={buttonClasses}
                type="submit" 
                onClick={sendData}
              >
                Submit
              </button>
            </form>
          </Fragment>
        )}
      </section>
      <style jsx>{`
        .ChangePassword {
          flex-grow: 1; 
          background: ${variables.paleblue};
          .section {  
            margin-top: ${variables.headerMargin};
            text-align: center;
            form {
              margin-inline: auto;
              width: 40%;
              text-align: left;
            }
          }
          .notification-wrapper {
            margin-top: 1rem;
            margin-bottom: 1rem;
            .notification {
              background: rgba(189, 25, 96, 0.1);
              border-radius: 1rem;
              .notification-text {
                color: ${variables.burgundy};
              }
            }
          }
          @media all and (max-width: ${screenVariables.mobileMax}) { 
            .section {
              form {
                width: 100%;
              }
            }
          }
        }
      `}</style>
    </div>
  )
}

export default ChangePassword;
import React, {Fragment, useState, useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
import Media from "react-media";
import classNames from "classnames";
import {useAppContext} from "../../context/app-context";
import variables from "../../assets/sass/variables.scss";
import screenVariables from '../../assets/sass/screensVariables.scss';
import routes from "../../config/routes";

const Footer = (props) => {
    const {isLoggedIn} = props;
    const [isOpenFooter, setIsOpenFooter] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const {isMobile} = useAppContext();
    const location = useLocation();

    useEffect(() => {

        return () => {
            clearTimeout(timeoutId)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const collapseFooter = (e) => {
        setIsOpenFooter(!isOpenFooter);    
        let height;    

        const timeout = setTimeout(() => {
            height = document.body.scrollHeight;
            window.scroll({
                top: height,
                left: 0, 
                behavior: 'smooth',
            });   
        }, 200);
        
        setTimeoutId(timeout);
    };

    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();


    const footerClasses = classNames("footer-main", {
        'is-hidden': (location.pathname.split('/').pop() === 'configure' || location.pathname === routes.loginOrSignup) && isMobile
    });

    const containerClasses = classNames('container', {
        'is-open': isOpenFooter
    });

    const arrowClasses = classNames('arrow-down', {
        'is-reverted': isOpenFooter
    });

    const fNameClasses = classNames('is-size-6-half', 'fname', {
       'is-hidden' : isOpenFooter
    });

    const firstColumnClasses = classNames('first-column', {
       'is-paddingless': !isOpenFooter,
       'is-size-7': !isMobile,
       'is-size-5': isMobile
    });

    const secondColumnClasses = classNames('second-column', {
        'is-hidden': !isOpenFooter,
        'is-size-7': !isMobile,
        'is-size-5': isMobile
    });

    const thirdColumnClasses = classNames('third-column', 'mt-5', {
        'is-hidden': !isOpenFooter,
        'is-size-7': !isMobile,
        'is-size-5': isMobile
    });

    return (
        <footer className={footerClasses}>
            <Media            //MOBILE
                query={{maxWidth: variables.tabletMax}}
                defaultMatches={isMobile}
            >
                <div className={containerClasses}>
                    <div className={firstColumnClasses}>
                        <img 
                            src="/images/logo-negative.svg" 
                            alt="footer_logo"
                            width={100}
                            height={40}
                        />
                        <div className={fNameClasses}>
                            © 2023 RmBoto<br/>
                            All Rights Reserved
                        </div>
                        <div className={arrowClasses} onClick={collapseFooter}/>
                    </div>
                    <div className={secondColumnClasses}>
                        <ul className="first-as">
                            <li >
                                <Link className="link underline-effect" to={routes.home}>
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link className="link underline-effect" to={routes.about}>
                                    About us
                                </Link>
                            </li>
                            {!isLoggedIn ? (
                                <Fragment>
                                    <li>
                                        <Link className="first-ul-a link underline-effect" to={routes.login}>
                                            Login
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="first-ul-a link underline-effect" to={routes.signup}>
                                            Sign up
                                        </Link>
                                    </li>
                                </Fragment>
                            ) : (
                                <li>
                                    <Link className="first-ul-a link underline-effect" to={routes.myAccount}>
                                        My Account
                                    </Link>
                                </li>
                            )}
                            <li>
                                <Link className="first-ul-a link underline-effect" to={routes.products}>
                                    Products
                                </Link>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <Link className="first-ul-a link underline-effect" to={routes.contacts}>
                                    Contacts
                                </Link>
                            </li>
                            <li>
                                <Link className="first-ul-a link underline-effect" to={routes.privacy}>
                                    Privacy Policy
                                </Link>
                            </li>
                            <li>
                                <Link className="first-ul-a link underline-effect" to={routes.termsOfUse}>
                                    Terms of use
                                </Link>
                            </li>
                            <li>
                                <Link className="first-ul-a link underline-effect" to={routes.cookiePolicy}>
                                    Cookie Policy
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className={thirdColumnClasses}>
                        <img
                            src='/images/footer-text2x.png'
                            alt='footer'
                            width={120}
                            height={30}
                        />
                        <div className="is-size-6-half has-text-nowrap">
                            © 2023 RmBoto<br/>
                            All Rights Reserved
                        </div>
                    </div>
                </div>
            </Media>


            <Media
                query={{minWidth: variables.desktopMin}}
                defaultMatches={!isMobile}
            >
                <div className="container">
                    <div className="first-column">
                        <img 
                            src="/images/logo-negative.svg" 
                            alt="footer_logo"
                            width={160}
                            height={55}
                        />
                    </div>
                    <div className="second-column">
                        <div className='is-flex has-gap-1half'>
                            <Link className="link underline-effect" to={routes.home}>
                                Home
                            </Link>
                            {!isLoggedIn ? (
                                <Fragment>
                                    <Link className="first-ul-a link underline-effect" to={routes.login}>
                                        Login
                                    </Link>
                                    <Link className="first-ul-a link underline-effect" to={routes.signup}>
                                        Sign up
                                    </Link>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Link className="first-ul-a link underline-effect" to={routes.myAccount}>
                                        My Account
                                    </Link>
                                </Fragment>
                            )}
                            <Link className="first-ul-a link underline-effect" to={routes.contacts} >
                                Contacts
                            </Link>
                            <Link className="first-ul-a link underline-effect" to={routes.termsOfUse}>
                                Terms of use
                            </Link>
                        </div>
                        <div className='is-flex has-gap-1half'>
                            <Link className="link underline-effect" to={routes.about}>
                                About us
                            </Link>
                            <Link className="first-ul-a link underline-effect" to={routes.products}>
                                Products
                            </Link>
                            <Link className="first-ul-a link underline-effect" to={routes.privacy}>
                                Privacy Policy
                            </Link>
                            <Link className="first-ul-a link underline-effect" to={routes.cookiePolicy}>
                                Cookie Policy
                            </Link>
                        </div>
                    </div>
                    <div className="third-column">
                        <img
                            src='/images/footer-text2x.png'
                            alt='footer-text'
                            width={131}
                            height={36}
                        />
                        <div className="is-size-6-half">
                            © {currentYear} RmBoto {<br/>} All Rights Reserved
                        </div>
                    </div>
                </div>
            </Media>

            <style global jsx>{`
                .footer-main {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 2rem 1rem;
                    color: white;
                    margin-top: auto;
                    background: linear-gradient(78deg, #47A8BD 0%, #1D458D 100%);
                    .link {
                        color: white;
                        white-space: nowrap;
                        line-height: 1;
                    }
                    .container {
                        height: inherit;
                        max-width: auto;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 0.5rem;
                    }
                    .first-column {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        align-content: center;
                        min-width: fit-content;
                        gap: 0.5rem;
                        .first-column-t1-1 { 
                            font-weight: bold;
                        }
                    }
                    .second-column {
                        display: flex;
                        flex-direction: column;
                        gap: 1.5rem;
                        .has-gap-1half {
                            gap: 1.5rem;
                        }
                    }
                    .third-column {
                        display: flex;
                        align-items: center;
                        gap: 3rem;
                        height: 36px;
                    }
                    @media all and (min-width: ${screenVariables.tabletMin}) and (max-width: ${screenVariables.tabletMax}) {
                        position: sticky;
                        bottom: 0;
                        height: 100%;
                        .container {
                            &:last-child {
                                padding: 0rem !important;
                                margin: 0rem !important;
                            }
                        }
                    }
                    @media all and (max-width: ${screenVariables.mobileMax}) {
                        width: 100vw;
                        height: 100%;
                        .container {
                            flex-direction: column;
                            transition: all 200ms ease;
                            .first-column {
                                flex-direction: row;
                                justify-content: space-between;
                                align-content: center;
                                width: 100%;
                                padding-bottom: 1rem;
                                &.is-paddingless {
                                    padding-bottom: 0;
                                }
                            }
                            .second-column {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                width: 100%;
                                font-size: 1.25rem;
                                font-weight: 700;
                            }
                            .third-column {
                                display: flex;
                                justify-content: space-between;
                                gap: 0;
                                width: 100%;
                            }
                        }
                    }
                }
            `}</style>
        </footer>
    )
}

export default Footer;
import React from "react";
import Media from "react-media";
import ReCAPTCHA from "react-google-recaptcha";
import classNames from "classnames";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {AppContext} from "../../context/app-context";
import variables from "../../assets/sass/variables.scss";
import screenVariables from '../../assets/sass/screensVariables.scss';
import {sendContactForm} from "../../config/ajax";
import {RECAPTCHA_KEY} from "../../config/constants";
import {isValidEmail} from "../../config/validations";

class Contacts extends React.Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {    
            firstName: "",
            lastName: "",
            email: "",
            inquiry: "",
            errors: {},
            captcha: false,
            loading: false
        }
    };

    showToast = () => {
        toast('You successfully send your inquiry!', {
            className: "toast-login",
            position: "bottom-center",
            autoClose: 5000,
            closeButton: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    handleDataChange = (e) => this.setState({[e.target.name]: e.target.value});

    isValidUsername = firstName => {
        let pattern = /^([a-z]+)(_)?([a-z0-9]+)$/is;
        return pattern.test(String(firstName).toLowerCase());
    };

    handleCaptchaChange = (value) => {
        this.setState({captcha: value === null ? false : true});
    };

    validateForm = () => {
        const {firstName, lastName, email, inquiry} = this.state;
        const obj = {};

        if (firstName.length === 0) {
            obj.firstName = 'First name is required!';
        } else if (!this.isValidUsername(firstName)) { 
            obj.firstName = 'You cannot use symbols like those !, @, -, / and etc.!';   
        } else if (firstName.length < 2 || firstName.length > 20) {
            obj.firstName ='First name must be between 2 and 20 characters!';
        }
        
        if (lastName.length === 0) {
            obj.lastName = 'Last name is required!';
        } else if (!this.isValidUsername(lastName)) { 
            obj.lastName = 'You cannot use symbols like those !, @, -, / and etc.!';   
        } else if (lastName.length < 2 || lastName.length > 20) {
            obj.lasttName ='Last name must be between 2 and 20 characters!';
        }

        if (email.length === 0) {
             obj.email = "E-mail is required!";                  
        } else if (!isValidEmail(email)) {
            obj.email = 'Please enter valid e-mail address!';             
        }
        
        
        if (inquiry.length === 0) {
            obj.inquiry = 'Inquiry is required!';
        } else if (inquiry.length > 1000) {
            obj.inquiry = 'Inquiry cannot be more than 1000 symbols!';
        } else if (inquiry.length < 10) {
            obj.inquiry = 'Inquiry cannot be less than 10 symbols!'
        }

        if (this.state.captcha === false) {
            obj.captcha = 'Please prove that you are not robot!';
        }

        return obj;
    };

    removeErrors = (event) => {
        this.setState(prevState => ({
            errors: {                                                           
                ...prevState.errors,                                            
                [event.target.name] : ''                       
            }
        }))
    };

    sendData = (e) => {
        e.preventDefault();
        let errors = this.validateForm();
        if (Object.keys(errors).length === 0) {

            this.setState({ loading: true });
        
            const obj = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email:    this.state.email,
                inquiry:  this.state.inquiry
            }
            if (this.state.captcha)
            sendContactForm(obj)
                .then((response) => {
                    this.setState({
                        loading: false,
                        firstName: "",
                        lastName: "",
                        email: "",
                        inquiry: ""
                    })
                    this.showToast();
                    window.grecaptcha.reset();
                })
                .catch((error) => {
                    this.setState({loading: false});
                    errors = error.response.data.data;
                        this.setState({
                            errors: errors,
                            loading: false
                        })
                })
        } else {
            this.setState({errors: errors});
        }   
    }

    render () {

        const {isMobile} = this.context;
        const {firstName, lastName, email, inquiry, loading, errors} = this.state;

        const firstNameClasses = classNames('name-input', 'input', { 
            "is-danger": errors.firstName 
        })

        const lastNameClasses = classNames('name-input', 'input', { 
            "is-danger": errors.lastName 
        })

        const emailClasses = classNames('input-size', 'email', 'input', {
            "is-danger": errors.email
        });
    
        const inquiryClasses = classNames('inquiry', 'input', 'is-fullheight', {
            "is-danger": errors.inquiry
        });

        const buttonClasses = classNames('button', 'blue', 'is-large', {
            "is-loading": loading
        })

        return (
            <div className="Contacts">
                <ToastContainer/>
                <Media            //MOBILE
                    query={{maxWidth: variables.tabletMax}}
                    defaultMatches={isMobile}
                >
                    <section className="section">
                        <h1 className="title has-text-centered">
                            Contact
                            <div className="has-text-sky luck is-block">us</div>
                        </h1>
                        <div className="hero-form-wrapper">
                            <div className="hero-contacts centered">
                                <img 
                                    src="/images/contacts-hero.png" 
                                    alt="chatbot" 
                                    width="300"
                                    height="300"
                                />
                            </div>
                            <div className="form-wrapper">
                                <form id="contactForm" method="post" onSubmit={this.sendData}>
                                    <div className="field">
                                        <label htmlFor="firstName">First name</label>
                                        <div className="control">
                                            <input 
                                                className={firstNameClasses}
                                                type="text" 
                                                value={firstName}
                                                onFocus={this.removeErrors}
                                                onChange={this.handleDataChange}
                                                name="firstName" 
                                                id="firstName" 
                                                placeholder="Enter your first name" 
                                            />
                                            {errors.firstName && (<span className="errors">{errors.firstName}</span>)}
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="lastName">Last name</label>
                                        <div className="control">
                                            <input 
                                                className={lastNameClasses}
                                                type="text" 
                                                value={lastName}
                                                onFocus={this.removeErrors}
                                                onChange={this.handleDataChange}
                                                name="lastName" 
                                                id="lastName" 
                                                placeholder="Enter your last name" 
                                            />
                                            {errors.lastName && (<span className="errors">{errors.lastName}</span>)}
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="email">E-mail</label>
                                        <div className="control">
                                            <input 
                                                className={emailClasses}
                                                type="email" 
                                                value={email}
                                                onFocus={this.removeErrors}
                                                onChange={this.handleDataChange}
                                                name="email"
                                                id="email"
                                                placeholder="Enter your e-mail" 
                                            />
                                            {errors.email && (<span className="errors">{errors.email}</span>)}
                                        </div>
                                    </div>
                                    <div className="field inquiry-field">
                                        <label htmlFor="inquiry">
                                            Inquiry
                                        </label>
                                        <div className="control">
                                            <textarea 
                                                className={inquiryClasses}
                                                value={inquiry}
                                                onFocus={this.removeErrors}
                                                onChange={this.handleDataChange}
                                                name="inquiry" 
                                                id="inquiry" 
                                                placeholder="Send us your thoughts"
                                                cols="40"
                                                rows="5"
                                            />
                                            {errors.inquiry && (<span className="errors">{errors.inquiry}</span>)}
                                        </div>
                                    </div>
                                    <div className="btn-wrapper">
                                        <div>
                                            <ReCAPTCHA
                                                sitekey={RECAPTCHA_KEY}
                                                onChange={this.handleCaptchaChange}
                                            />
                                            <div className="recaptcha-error">
                                                {errors.captcha && (<span className="errors">{errors.captcha}</span>)}
                                            </div>
                                        </div> 
                                        <div className="centered mt-4">
                                            <button 
                                                className={buttonClasses}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div> 

                    </section>
                </Media>

                <Media
                    query={{minWidth: variables.desktopMin}}
                    defaultMatches={!isMobile}
                >
                    <section className="section">
                        <h1 className="title has-text-centered">
                            Contact
                            <div className="has-text-sky luck is-block">us</div>
                        </h1>
                        <ToastContainer/>
                        <div className="hero-form-wrapper">
                            <div className="hero-contacts centered">
                                <img 
                                    src="/images/contacts-hero.png" 
                                    alt="chatbot" 
                                    width="500"
                                    height="500"
                                />
                            </div>
                            <div className="form-wrapper">
                                <form id="contactForm" method="post" onSubmit={this.sendData} autoComplete="off">
                                    <div className="names">
                                        <div className="field is-fullwidth">
                                            <label htmlFor="firstName" className="label">First name</label>
                                            <div className="control">
                                                <input 
                                                    className={firstNameClasses}
                                                    type="text" 
                                                    value={firstName}
                                                    onFocus={this.removeErrors}
                                                    onChange={this.handleDataChange}
                                                    name="firstName" 
                                                    id="firstName" 
                                                    placeholder="Enter your first name" 
                                                />
                                                {errors.firstName && (
                                                    <span className="errors">{errors.firstName}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="field is-fullwidth">
                                            <label htmlFor="lastName" className="label">Last name</label>
                                            <div className="control"> 
                                                <input 
                                                    className={lastNameClasses}
                                                    type="text" 
                                                    value={lastName}
                                                    onFocus={this.removeErrors}
                                                    onChange={this.handleDataChange}
                                                    name="lastName" 
                                                    id="lastName" 
                                                    placeholder="Enter your last name" 
                                                />
                                                {errors.lastName && (
                                                    <span className="errors">{errors.lastName}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="email" className="label">E-mail</label>
                                        <div className="control">
                                            <input 
                                                className={emailClasses}
                                                type="email" 
                                                value={email}
                                                onFocus={this.removeErrors}
                                                onChange={this.handleDataChange}
                                                name="email"
                                                id="email"
                                                placeholder="Enter your e-mail address" 
                                            />
                                            {errors.email && (
                                                <span className="errors">{errors.email}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="inquiry" className="label">
                                            Inquiry
                                        </label>
                                        <div className="control">
                                            <textarea 
                                                className={inquiryClasses}
                                                value={inquiry}
                                                onFocus={this.removeErrors}
                                                onChange={this.handleDataChange}
                                                name="inquiry" 
                                                id="inquiry" 
                                                placeholder="Send us your thoughts"
                                                cols="40"
                                                rows="5"
                                            />
                                            {errors.inquiry && (
                                                <span className="errors">{errors.inquiry}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="btn-wrapper">
                                        <div>
                                            <ReCAPTCHA
                                                sitekey={RECAPTCHA_KEY}
                                                onChange={this.handleCaptchaChange}
                                            />
                                            <div className="recaptcha-error">
                                                {errors.captcha && (
                                                    <span className="errors">{errors.captcha}</span>
                                                )}
                                            </div>
                                        </div> 
                                        <button 
                                            className={buttonClasses}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div> 
                    </section>
                </Media>

                <style global jsx>{`
                    .Contacts {
                        margin-top: ${variables.headerMargin};
                        background-color: ${variables.paleblue};
                        padding: ${variables.paddingDesktop};
                        .section {
                            .hero-form-wrapper {
                                margin-top: 2rem;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                .hero-contacts {
                                    width: 50%; 
                                }
                                .form-wrapper {
                                    padding: 1.5rem;
                                    width: 50%;
                                    .names {
                                        width: 100%;
                                        display: flex;
                                        justify-content: flex-start;
                                        gap: 1rem;
                                        .name-input {
                                            width: 100%;
                                        }
                                    }
                                    .btn-wrapper {
                                        margin-top: 1rem;
                                        display: flex;
                                        justify-content: space-between;
                                    }
                                }
                            }  
                        }
                        @media all and (max-width: ${screenVariables.mobileMax}) {
                            padding-bottom: 0;
                            .section {
                                .contact-us {
                                    margin-top: 0;
                                }
                                .hero-form-wrapper {
                                    flex-direction: column;
                                    .hero-contacts {
                                        width: 100%;
                                    }
                                    .form-wrapper {
                                        width: 100%;
                                        padding: 0;
                                        .names {
                                            flex-direction: column;
                                        }
                                        .inquiry-field {
                                            width: 100%;
                                            textarea {
                                                width: 100%;
                                            }
                                        }
                                        .btn-wrapper {
                                            flex-direction: column;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    @media all and (min-width: ${screenVariables.tabletMin}) and (max-width: ${screenVariables.tabletMax}) {
                        .Contacts {
                            .section {
                                padding: 0rem !important;
                                .hero-form-wrapper {
                                    .hero {
                                        display: none;
                                    }
                                    .form-wrapper {
                                        padding: 1rem;
                                        width: 80%;
                                        form {
                                            max-width: 100%;
                                            width: 100%;
                                            .names {
                                                justify-content: flex-start;
                                                .firstName {
                                                    width: 100%;
                                                    padding-right: 1rem;
                                                }
                                                .lastName {
                                                    width: 100%;
                                                }
                                            }
                                            .email {
                                                width: 100% !important;
                                            }
                                            .inquiry {
                                                width: 100% !important;
                                                padding: 1rem 0rem 0rem 1rem;
                                                &::placeholder {
                                                    position: relative;
                                                    top: -0.5rem;
                                                    left: -0.5rem;
                                                }
                                            }
                                            .btn-wrapper {
                                                width: 100%;
                                                display: flex;
                                                justify-content: space-between;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                `}</style>
            </div> 
        )
    }
}
export default Contacts;

const {STAGES} = require('../config/constants.js');

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

const CONFIG = {
  [STAGES.DEVELOPMENT]: {
    appUrl: "https://beta.rmboto.com",
    apiUrl: "https://beta.rmboto.com/api/v1/"
  },
  [STAGES.STAGING]: {
    appUrl: "https://beta.rmboto.com",
    apiUrl: "https://beta.rmboto.com/api/v1/"
  },
  [STAGES.PRODUCTION]: {
    appUrl: "https://rmboto.com",
    apiUrl: "https://rmboto.com/api/v1/"
  }
}

const APP_CONFIG = {
  appName: 'rmboto.com',
  ...CONFIG[ENVIRONMENT],
  ENVIRONMENT
};

export default APP_CONFIG;
import React from 'react';
import classNames from "classnames";
import DialogOverlay from "./DialogOverlay";
import {disableBodyScroll, enableBodyScroll} from '../../config/utils';
import variables from "../../assets/sass/variables.scss";

class ModalDialog extends React.Component {

	state = {
		windowWidth: 0,
		windowHeight: 0,
		footerHeight: 20,
		headerHeight: 20,
	};
	modalDialog;

    componentDidMount() {

		if (!this.props.withoutOutsideClick) {
			document.addEventListener('mousedown', this.handleOutsideClick);
		}

		if (!this.props.withoutScrollDisable) {
			disableBodyScroll(true, this.props.isMobile);
		}

		setTimeout(() => {
			let modalFooter = document.querySelectorAll('.ModalDialog footer')[0];
			let modalHeader = document.querySelectorAll('.ModalDialog header')[0];

			let footerHeight = 0;
			let headerHeight = 0;

			if (modalFooter) {
				footerHeight = modalFooter.getBoundingClientRect().height;
			}

			if (modalHeader) {
				headerHeight = modalHeader.getBoundingClientRect().height;
			}

			this.setState({
				footerHeight,
				headerHeight
			});
		}, 0)

		this.setState({
			windowWidth: window.innerWidth,
			windowHeight: window.innerHeight
		});

	};

	componentWillUnmount() {
		if (!this.props.withoutOutsideClick) {
			document.removeEventListener('mousedown', this.handleOutsideClick);
		}
		if (!this.props.withoutScrollDisable) {
			enableBodyScroll();
		}
	};

	handleOutsideClick = e => {
		if (
			e.target.className && e.target.className.includes && (
			e.target.className.includes('actions-menu-overlay') ||
			e.target.className.includes('actions-menu-dropdown-wrapper') ||
			e.target.className.includes('actions-menu-overlay') ||
			e.target.className.includes('option') ||
			e.target?.parentElement?.className?.includes('option') ||
			e.target?.className.includes('react-datepicker')
			)
		) {
			return
		}

		if (this.modalDialog && !this.modalDialog.contains(e.target)) this.closeModal();
	};

	closeModal = () => {
		if (this.props.withoutExitAnimation || this.props.passedRef) {
			this.props.closeModal();
		} else {
			this.modalDialog.classList.add('has-exit-animation');
			setTimeout(() => this.props.closeModal(), 250);
		}
	};

	render() {
		let modalClasses = classNames('ModalDialog', {[this.props.classNames]: this.props.classNames});
		let {windowWidth, windowHeight, headerHeight, footerHeight} = this.state;
		let {title, hasCloseButton, icon, closeAskModal} = this.props;

		return (
			<React.Fragment>
				<div className={modalClasses}
					ref={ref => {
						this.modalDialog = ref;
						if (this.props.passedRef) this.props.passedRef(ref);
					}}
				>
					{(hasCloseButton || title) && (
						<header>
							<div className="is-flex is-align-items-center is-justify-content-center p-3">
								{title && (
									<h1 className="modal-title has-text-centered">{title}</h1>
								)}
								{icon && (
									<i className={`icon-font is-size-4 m-r-half-rem ${icon} is-clickable`} onClick={this.closeModal}/>
								)}
							</div>
							{hasCloseButton && !closeAskModal && (
								<i className="icon-font icon-close has-cursor-pointer is-clickable is-size-4 close-btn p-half-rem" onClick={this.closeModal}/>
							)}
							{closeAskModal && (
								<i className="icon-font icon-close has-cursor-pointer is-clickable is-size-4 close-btn p-half-rem" onClick={closeAskModal}/>
							)}
						</header>
					)}
					{this.props.children}
				</div>

				{!this.props.withoutDialogOverlay && (
					<DialogOverlay
						zIndex={this.props.zIndexOverlay || variables.zIndexOverlay}
						removeScrolling={this.props.removeOverlayScroll}
					/>
				)}

				<style jsx global> {`
					::-webkit-scrollbar-track {
						background: ${variables.darkgrey};
					}
					::-webkit-scrollbar-thumb {
						background: ${variables.darkgrey};
						border-radius: ${variables.radiusExtra};
					}
					::-webkit-scrollbar-thumb:hover {
						background: ${variables.darkgrey};
					}
					.ModalDialog {
						position: fixed;
						top: 50%;
						left: 50%;
						width: 40rem;
						transform: translate(-50%, -50%);
						max-width: calc(${windowWidth}px - 2rem);
						max-height: calc(${windowHeight}px - 4rem);
						overflow-x: hidden;
						box-shadow: 0px 0px 20px #0000000D;
						background-color: ${variables.white};
						z-index: ${variables.zIndexOverlay + 1};
						cursor: default;
						border-radius: ${variables.radiusExtra};
						@media all and (min-width: ${variables.desktopMax}) {
						animation: fade-in 0.25s cubic-bezier(0.390, 0.575, 0.565, 1.000);
						@keyframes fade-in {
							0% {
								opacity: 0;
							}
							100% {
								opacity: 1;
							}
						}
						&.has-exit-animation {
							animation: fade-out 0.25s cubic-bezier(0.390, 0.575, 0.565, 1.000) forwards;
							@keyframes fade-out {
								0% {
									opacity: 1;
								}
								100% {
									opacity: 0;
								}
							}
							+ .DialogOverlay {
									animation: fade-out 0.25s cubic-bezier(0.390, 0.575, 0.565, 1.000) forwards;
								}
							}
						}
						&.is-25rem-width {
							width: 25rem;
						}
						&.is-30rem-width {
							width: 30rem;
						}
						&.is-35rem-width {
							width: 35rem;
						}
						&.is-45rem-width {
							width: 45rem;
						}
						&.is-55rem-width {
							width: 55rem;
						}
						&.is-70rem-width {
							width: 70rem;
						}
						&.is-full-screen {
							top: 0;
							width: 100vw;
							height: 100vh;
							max-width: 100vw;
							max-height: 100vh;
							border-radius: 0;
						}
						@media all and (max-width: ${variables.mobileMax}) {
							border-radius: ${variables.radius};
							&.is-full-screen-mobile {
								width: ${windowWidth}px;
								height: ${windowHeight}px;
								max-width: ${windowWidth}px;
								max-height: ${windowHeight}px;
								border-radius: 0;
								.modal-content {
									max-height: calc(${windowHeight}px - ${headerHeight}px - ${footerHeight}px);
									height: calc(${windowHeight}px - ${headerHeight}px - ${footerHeight}px);
								}
							}
						}
						@media all and (min-width: ${variables.tabletMin}) and (max-width: ${variables.tabletMax}){
							&.is-full-screen-tablet {
								width: ${windowWidth}px;
								height: ${windowHeight}px;
								max-width: ${windowWidth}px;
								max-height: ${windowHeight}px;
								border-radius: 0;
								.modal-content {
									max-height: calc(${windowHeight}px - ${headerHeight}px - ${footerHeight}px);
									height: calc(${windowHeight}px - ${headerHeight}px - ${footerHeight}px);
								}
							}
						}
						.modal-content {
							overflow-y: auto;
							width: auto;
							margin: 0;
							padding: 2rem 2rem 1rem 2rem;
							max-height: calc(${windowHeight}px - 8rem - ${footerHeight}px);
							@media all and (max-width: ${variables.mobileMax}) {
								padding: 1.5rem 1.5rem 1rem 1.5rem;
							}
						}
						.modal-icon {
							text-align: center;
							font-size: 4rem;
							color: ${variables.pink};
						}
						header {
							position: sticky;
							top: 0;
							left: 0;
							right: 0;
							height: ${title ? "auto" : "2.25rem"};
							padding: 1rem;
							background: ${variables.white};
							z-index: 1;
							text-align: right;
							@media all and (max-width: ${variables.mobileMax}) {
								padding: 1.25rem;
							}
							.modal-title {
								font-size: 1.5rem;
								font-weight: ${variables.weightBold};
								margin: ${hasCloseButton ? "0 3rem 0 0.25rem" : "0"};
								text-align: left;
								position: relative;
								@media all and (max-width: ${variables.mobileMax}) {
									margin: ${hasCloseButton ? "0 2rem 0 0" : "0"};
								}
							}
							.close-btn {
								position: absolute;
								right: 0;
								top: 0;
								bottom: 0;
								display: flex;
								align-items: center;
								padding-right: 1rem;
								padding-left: 1rem;
								color: ${variables.pink};
								transition: all 0.25s;
								margin: 0;
								&:hover {
									background-color: ${variables.darkgrey};
								}
								@media all and (max-width: ${variables.mobileMax}) {
									padding-right: 0.75rem;
									padding-left: 0.75rem;
								}
							}
						}
						footer {
							position: sticky;
							bottom: 0;
							left: 0;
							right: 0;
							background: inherit;
							z-index: 1;
							padding: 1rem 2rem 2rem 2rem;
							.button {
								width: 50%;
								&:nth-child(2), &:nth-child(3) {
									margin-left: 1rem;
								}
								&.w-100 {
									width: 100%;
								}
							}
						}
					}
				`}</style>
			</React.Fragment>
		)
	}
}

export default ModalDialog;
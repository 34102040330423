import React, {useState, useRef, useEffect} from 'react'
import {useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import variables from '../../assets/sass/variables.scss';
import routes from '../../config/routes';
import {useAppContext} from '../../context/app-context';
import OrderSummary from '../common/OrderSummary';
import {isEmpty} from '../../config/methods'; 
import {sendCustom} from '../../config/ajax';

  const CPU_STEPS = [
    {
      id: 1,
      label: '1 Core',
      value: 1
    },
    {
      id: 2, 
      label: '2 Cores',
      value: 2
    },
    {
      id: 3,
      label: '4 Cores',
      value: 4
    },
    {
      id: 4,
      label: '8 Cores',
      value: 8
    }, 
    {
      id: 5, 
      label: '16 Cores',
      value: 16
    }
  ]
  const RAM_STEPS = [
    {
      id: 1,
      label: '1 GB',
      value: 1
    },
    {
      id: 2, 
      label: '2 GBs',
      value: 2
    },
    {
      id: 3,
      label: '4 GBs',
      value: 4
    },
    {
      id: 4,
      label: '8 GBs',
      value: 8
    }, 
    {
      id: 5, 
      label: '16 GBs',
      value: 16
    },
    {
      id: 6,
      label: '32 GBs',
      value: 32
    }
  ]
  const DISK_STEPS = [
    {
      id: 1,
      label: '10 GBs',
      value: 10
    },
    {
      id: 2, 
      label: '20 GBs',
      value: 20
    },
    {
      id: 3,
      label: '40 GBs',
      value: 40
    },
    {
      id: 4,
      label: '80 GBs',
      value: 80
    }, 
    {
      id: 5, 
      label: '160 GBs',
      value: 160
    },
    {
      id: 6,
      label: '200 GBs',
      value: 200
    }
  ]

const CustomQuery = props => {
  const [range, setRange] = useState({
    cpu: 1,
    ram: 1, 
    disk: 1,
    ip: 1
  });
  const [rangeOutput, setRangeOutput] = useState({
    cpu: {
      label: '1 Core',
      value: 1
    },
    ram: {
      label: '1 GB',
      value: 1
    },
    disk: {
      label: '10 GB',
      value: 10
    }
  })
  const [describe, setDescribe] = useState('');
  const [errors, setErrors] = useState({
    describe: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const {isMobile} = useAppContext();
  const navigate = useNavigate();

  const cpuOutputRef = useRef();
  const cpuRef = useRef();
  const ramOutputRef = useRef();
  const ramRef = useRef();
  const diskOutputRef = useRef();
  const diskRef = useRef();

  useEffect(() => {

    // bulmaSlider.attach();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const cpu = cpuRef.current;
    const cpuOutput = cpuOutputRef.current.getBoundingClientRect();
    const newVal = Number(((cpu.value - cpu.min) * 100) / (cpu.max - cpu.min));

    calcOutputPosition(newVal, cpuOutputRef, cpuOutput);

    let output = CPU_STEPS.find((el) => (+el.id === +range.cpu && el));

    setRangeOutput({
      ...rangeOutput,
      cpu: output
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range.cpu])

  useEffect(() => {
    const ram = ramRef.current;
    const ramOutput = ramOutputRef.current.getBoundingClientRect();
    const newVal = Number(((ram.value - ram.min) * 100) / (ram.max - ram.min));
    calcOutputPosition(newVal, ramOutputRef, ramOutput);

    let output = RAM_STEPS.find((el) => (+el.id === +range.ram && el));
    setRangeOutput({
      ...rangeOutput,
      ram: output
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range.ram])
  
  useEffect(() => {
    const disk = diskRef.current;
    const diskOutput = diskOutputRef.current.getBoundingClientRect();
    const newVal = Number(((disk.value - disk.min) * 100) / (disk.max - disk.min));

    calcOutputPosition(newVal, diskOutputRef, diskOutput);

    let output = DISK_STEPS.find((el) => (+el.id === +range.disk && el));

    setRangeOutput({
      ...rangeOutput,
      disk: output
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range.disk])

  const calcOutputPosition = (newVal, outputRef, output) => {
    switch (newVal) {
      case 0:
        outputRef.current.style.left = `${newVal}%`
        outputRef.current.style.transform = `unset`
        break;
      case 100: 
        outputRef.current.style.left = `calc(${newVal}% - ${output.width}px)`
        outputRef.current.style.transform = `unset`
        break;
      default:
        outputRef.current.style.left = `calc(${newVal}%`
        outputRef.current.style.transform = `translateX(-50%)`
        break;
    }
  };

  const handleRangeChange = e => {
    setRange({
      ...range,
      [e.target.id]: e.target.value
    })
  };

  const handleIPChange = (e) => {
    setRange({
      ...range,
      [e.target.id]: +e.target.dataset.value
    })
  };

  const handleDescribeChange = e => {
    setDescribe(e.target.value);  
  };

  const removeErrors = (e) => {
    setErrors({
      ...errors,
      [e.target.name]: ''
    })
  }

  const validateForm = () => {
    let obj = {};
    if (describe.length === 0) {
      obj.describe = "Description is required!"
    }

    return obj;
  };

  const onClickGetAQuote = () => {
    setIsLoading(true)
    const err = validateForm()

    if (isEmpty(err)) {
      const data = {
        cpu: rangeOutput?.cpu?.value,
        ram: rangeOutput?.ram?.value,
        disk: rangeOutput?.disk?.value,
        ip: +range.ip,
        description: describe
      }

      sendCustom(data)
        .then((res) => {
          console.log(res);      
          setIsLoading(false)
          navigate(routes.querySent)  
        })
        .catch((err) => {
          setIsLoading(false)
          console.log(err);
        })
    } else {
      setErrors(err)
      setIsLoading(false)
    } 
  };

  const describeClasses = classNames('input', 'is-fullheight', {
    'is-danger': errors.describe
  });

  const buttonClasses = classNames('button', 'is-large', 'is-fit-content', 'mt-3', {
    'is-loading': isLoading
  })
  
  const ipTickClasses = (id) => classNames('tick', 'mr-3', {
    'active' : id === range.ip
  });

  return (
    <section className='CustomQuery section'>   
      <h1 className='is-size-3-half has-text-marine is-bold mb-3'>Configure your machine</h1>

      <form className='left'>
        <label className='label'>Drag the sliders to customize your machine</label>
        <div 
          className='radio-wrapper' 
          name='range'
        >
          <div 
            className='radio-panel'
          >
            <div className='mr-3'>
              <img src="/images/cpu.svg" alt="cpu" width={32} height={32}/>
              <label htmlFor='cpu' className='has-text-sky is-bold'>CPU</label>
            </div>
            <div className="is-relative is-fullwidth">
              <input 
                id="cpu" 
                className="slider is-fullwidth is-circle has-output-tooltip"
                step={1}
                min={1}
                max={5}
                type="range"
                value={range.cpu} 
                onChange={handleRangeChange}
                ref={cpuRef}
              />
              <output 
                ref={cpuOutputRef}
                htmlFor="cpu" 
                className=''
              >
                {`${rangeOutput?.cpu?.label}`}
              </output>
            </div>   
          </div>

          <div 
            className='radio-panel'
          >
            <div className='mr-3'>
              <img src="/images/ram.svg" alt="ram" width={32} height={32}/>
              <label htmlFor='ram' className='has-text-sky is-bold'>RAM</label>
            </div>
            <div className="is-relative is-fullwidth">
              <input 
                id='ram'
                type="range" 
                className="slider is-fullwidth is-circle has-output-tooltip"
                step={1}
                min={1}
                max={6}
                value={range.ram} 
                onChange={handleRangeChange}
                ref={ramRef}
              />
              <output 
                ref={ramOutputRef}
                htmlFor="ram" 
                className=''
              >
                {`${rangeOutput?.ram?.label}`}
              </output>
            </div>
          </div>

          <div 
            className='radio-panel'
          >
            <div className='mr-3'>
              <img src="/images/hdd.svg" alt="disk" width={32} height={32}/>
              <label htmlFor="disk" className='has-text-sky is-bold'>Disk</label>
            </div>
            <div className="is-relative is-fullwidth">
              <input 
                ref={diskRef}
                id='disk'
                type="range" 
                className="slider is-fullwidth is-circle has-output-tooltip"
                step={1}
                min={1}
                max={6}
                value={range.disk} 
                onChange={handleRangeChange}
              />
              <output 
                ref={diskOutputRef}
                htmlFor="disk"
                className=''
              >
                {`${rangeOutput?.disk?.label}`}
              </output>
            </div>
          </div>

          <div 
            className='radio-panel'
          >
            <div className='has-text-centered'>
              <img src="/images/ip.svg" alt="ip" width={32} height={32}/>
              <span className='has-text-sky is-bold'>IP</span>
            </div>
            <div className='is-flex is-fullwidth'>
              <div className='is-flex is-align-items-center is-justify-content-center is-flex-grow-1'>
                <div 
                  id="ip"
                  data-value={1}
                  onClick={handleIPChange}
                  className={ipTickClasses(1)}
                />
                <label
                  className='is-clickable'
                  id="ip"
                  data-value={1}
                  onClick={handleIPChange}
                >
                  1 Public
                </label>
              </div>
              <div className='is-flex is-align-items-center is-justify-content-center is-flex-grow-1'>
                <div 
                  className={ipTickClasses(2)} 
                  id="ip"
                  data-value={2}
                  onClick={handleIPChange}
                />
                <label
                  className='is-clickable'
                  id="ip"
                  data-value={2}
                  onClick={handleIPChange}
                > 
                  2 Public
                </label>
              </div>
            </div>
          </div>

        </div>

        <div className="field">
          {isMobile ? 
            <label htmlFor="describe" className="label">Describe how you would like to use<br/> your machine?</label>
            : <label htmlFor="describe" className="label">Describe how you would like to use your machine?</label>
          }
          <textarea 
            onChange={handleDescribeChange}
            onFocus={removeErrors}
            value={describe}
            className={describeClasses} 
            name="describe" 
            id="describe" 
            cols="30" 
            rows="3" 
            placeholder='E.g. I want output fast video converting machine only for output week.'
          />
        {errors.describe && (<span className="errors">{errors.describe}</span>)}
        </div>
      </form>
    
      <div className='right'>
        <OrderSummary
          withoutInfo
          icon='gears'
          type='Custom'
          color='sky'
          cpu={rangeOutput?.cpu?.label}
          ram={rangeOutput?.ram?.label}
          disk={rangeOutput?.disk?.label}
          ip={`${range.ip} public`}
          periodPrice='from $4.95'
        />
      </div>

      <button 
        onClick={onClickGetAQuote}
        className={buttonClasses}
      >
        Get output quote
      </button>

      <style global jsx>{`
        .CustomQuery {
          margin-top: ${variables.headerMargin};
          background-color: ${variables.paleblue};
          flex: 1;
          .left {
            width: 30%;
            .radio-wrapper {
              display: flex;
              gap: 1rem;  
              flex-direction: column;
              margin-bottom: 0.75rem;
            }
          }
          @media all and (max-width: ${variables.mobileMax}) {
            padding: ${variables.mobilePadding};
            padding-bottom: ${variables.footerHeight};
            .left {
              width: 100%;
            }
            button {
              margin: 0 auto;
            }
          }
        }
      `}</style>
    </section>
  )
}

export default CustomQuery
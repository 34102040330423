import {useState, useEffect} from "react";
import classNames from "classnames";
import variables from '../../assets/sass/variables.scss'
import {resendEmail} from "../../config/ajax";

const localSeconds = localStorage.getItem("seconds");
const SECONDS_LEFT = 10;

const EmailVerification = () => {
    const [isClickedResend, setIsClickedResend] = useState(false);
    const [secondsLeft, setSecondsLeft] = useState(localSeconds);
    const [intervalId, setIntervalId] = useState(null);
    const signUpEmail = localStorage.getItem('signupEmail').replaceAll('"', '');

    useEffect(() => {
        if (!!secondsLeft) {
            const interval = setInterval(() => {
                setSecondsLeft(prevState => prevState - 1);
            }, 1000)
            setIntervalId(interval);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (secondsLeft <= 0 && secondsLeft !== null) {
            clearInterval(intervalId);
            setIsClickedResend(false);
            setSecondsLeft(null);
            setIntervalId(null);
            localStorage.removeItem("seconds");
        } 

        return () => {
            if (secondsLeft > 0) {
                localStorage.setItem("seconds", secondsLeft)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secondsLeft]);

    const calculateCounter = (secondsLeft) => {

        let minutes = Math.floor((secondsLeft % (60 * 60)) / 60);
        let seconds = Math.floor(secondsLeft % 60);

        return (
            (minutes < 10 ? '0' + (minutes !== -1 ? minutes : '0') : minutes) + 
            ':' 
            + (seconds < 10 ? '0' + (seconds !== -1 ? seconds : '0') : seconds)
        )
    };

    const onClickResend = () => {
        setIsClickedResend(true);
        if (!intervalId) {
            setSecondsLeft(SECONDS_LEFT);
            localStorage.setItem("seconds", SECONDS_LEFT);
            const interval = setInterval(() => {
                setSecondsLeft(prevState => prevState - 1);
                localStorage.setItem("seconds", secondsLeft);
            }, 1000);
            setIntervalId(interval);
        }                

        if (JSON.stringify(signUpEmail)) {
            resendEmail({email: signUpEmail})
                .then((res) => {
                    console.log('resend to', signUpEmail);
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    };

    const buttonClasses = classNames('resend-link', 'underline', 'ml-2', {
        'disabled': isClickedResend
    });

    return ( 
        <div className="EmailVerification">
            <section className="section">
                <div className="container">
                    <h1 className="title has-text-centered">
                        my
                        <div className="has-text-sky luck is-block">Account</div>
                    </h1>
                    <div className="logo-wrapper">
                        <object 
                            className="404-logo"
                            alt="verification-logo"
                            title="verification-logo"
                            aria-label="verification-logo"
                            aria-labelledby="verification-logo"
                            type="image/svg+xml" 
                            data="../images/message-sent.svg"
                            width="360"
                            height="334"
                        />
                    </div>
                    <div className="is-size-3">
                        Please, check your email to confirm your account!
                    </div>
                    <div className="mt-2">
                        We’ve sent you an email to <span className='signUpEmail'>{signUpEmail}</span> with a link to confirm your account. Check your inbox or spam folder for the email.
                    </div>
                    <div className="mt-2">
                        Didn’t get an email?
                        {!secondsLeft ? 
                            <div 
                                className={buttonClasses}
                                onClick={onClickResend}
                            >
                                {'Resend confirmation.'}
                            </div>
                        :
                            <div
                                className={buttonClasses}
                            >
                                {`Try again after (${calculateCounter(secondsLeft)}) seconds.`}
                            </div> 
                        }
                    </div>
                </div>
            </section>

            <style jsx global>{`
                .EmailVerification {
                    flex-grow: 1;
                    margin-top: ${variables.headerMargin};
                    background: ${variables.paleblue};
                    text-align: center;
                    .signUpEmail {
                        font-weight: 700;
                    }
                    .resend-link {
                        display: inline;
                        cursor: pointer;
                    }
                    .underline {
                        position: relative;
                        color: black;
                        &::after {
                            content: "";  
                            position: absolute;
                            bottom: -0.125rem;
                            left: 0rem;
                            right: 0rem;
                            background: black;
                            border-radius: 2rem;     
                            height: 1px;
                            width: inherit; 
                            transform: translateY(0rem);
                        }
                        &:hover::after {
                            transition: all 0.5s ease;
                            transform: translateY(0.25rem);
                        }
                    }
                }
            `}</style>
        </div>
    )
}
export default EmailVerification;
export const isValidEmail = email => {

    let pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return pattern.test(String(email).toLowerCase());

};

export const isValidMinLength = (value, min) => value.length >= min;

export const isValidMaxLength = (value, max) =>  value.length <= max;

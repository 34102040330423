import React from "react";
import variables from "../../assets/sass/variables.scss";

const Privacy = () => {
    return (
        <div className="Privacy">
            <section className="section">
                    <h1 className="title has-text-centered">
                        Privacy
                        <span className="has-text-sky luck is-block">Policy</span>
                    </h1>

                    <h2>1. INTRODUCTION</h2>
                    <p className="centered">
                        <span>
                            RmBOTO group entity, the details of which are indicated in the RmBOTO’s Terms of Service, (<b>"RmBOTO"</b>) provides you web-hosting, domain name registration, and related products and services.
                            <br/>RmBOTO seeks to ensure the highest level of data privacy when offering its variety of quality products and services to RmBOTO customers and subscribers (<b>"Subscribers"</b>) (collectively, <b>“You”</b>, or <b>"Users"</b>).
                            <br/>At RmBOTO, the privacy and security of our Users is of paramount importance. RmBOTO is committed to protecting the data you share with us.
                            <br/>When this Policy mentions <b>"RmBOTO"</b>, <b>"we"</b>, <b>"us"</b>, or <b>"our"</b>, it refers to RmBOTO that is responsible for the protection of your personal information in line with this Privacy Policy (<b>"Data Controller"</b>).
                            <br/>This Privacy Policy (<b>"Policy"</b>) explains how RmBOTO processes information that can be used to directly or indirectly identify an individual (<b>"Personal Data"</b>) collected on our Site, our services (<b>“Service”</b>), forums and our mobile applications (<b>"Platform"</b>).
                            <br/>All personal data are processed in accordance with the General Data Protection Regulation (EU) 2016/679 (<b>“GDPR”</b>).
                            <br/>For any questions regarding this Policy or any requests regarding the processing of personal data, please contact us at <b>support@rmboto.com</b>.
                        </span>
                    </p>

                    <h2>2. GENERAL PRINCIPLES. CONFIDENTIALITY</h2>
                    <p className="centered">
                        <span>
                            RmBOTO shall process all Personal Data adhering to the general data processing principles:
                            <br/>- lawfully, fairly and in a transparent manner in relation to the data subject (<b>lawfulness, fairness, and transparency</b>);
                            <br/>- collect and process Personal Data only for specified, explicit and legitimate purposes and not further processed in a manner that is incompatible with those purposes (<b>purpose limitation</b>);
                            <br/>- ensure that Personal Data is adequate, relevant and limited to what is necessary for relation to the purposes for which they are processed (<b>data minimization</b>);
                            <br/>- ensure that Personal Data is accurate and, where necessary, kept up to date (<b>accuracy</b>);
                            <br/>- ensure that Personal Data is kept in a form which permits identification of data subjects for no longer than is necessary for the purposes for which the personal data are processed (<b>storage limitation</b>);
                            <br/>- process Personal Data in a manner that ensures appropriate security of the personal data, including protection against unauthorized or unlawful processing and against accidental loss, destruction or damage, using appropriate technical or organizational measures (<b>integrity and confidentiality</b>);
                            <br/>All and any information stored on RmBOTO's Platform is treated as strictly confidential. All information is stored securely and is accessed by qualified and authorized personnel only.
                        </span>
                    </p>

                    <h2>3. INFORMATION WE COLLECT</h2>
                    <p className="centered">
                        <span>
                            3.1. Information You provide to us.
                            <br/>Information that is necessary for the use of the RmBOTO Platform
                            <br/>We ask for and collect the following personal information about you when you use the RmBOTO Platform. This information is necessary for the adequate performance of the contractual arrangement which is in place between you and us and to allow us to comply with our legal obligations. Failing to provide any of this data or decision to delete or object to the processing of any of such data may result in de-activation of your RmBOTO Account.
                            <br/><span className="point">Account Signup Information.</span>When you sign up to RmBOTO, we require you to provide minimum information - email address and password. In some particular cases we ask you to provide more information - such as your first and last name, identity verification, contact details. Such information is required when you are applying for a registration of domain name and indicated as the WHOIS information.
                            <br/><span className="point">Login information.</span>We collect Login scope and Email scope from Google, please see <b>https://developers.google.com/+/web/api/rest/oauth#profile</b> and email from Facebook.
                            <br/><span className="point">Identity Verification.</span> To comply with the rules set forth by the Internet Corporation for Assigned Names and Numbers (<b>"ICANN"</b>) before the registration of the new domain name, RmBOTO may collect identity verification information (such as images of your passport, national ID card, valid driving license or other documents as required or permitted by applicable laws).
                            <br/><span className="point">Payment Information.</span> To order and use features of the RmBOTO Platform (such as web-hosting, domain name registration), we may require you to provide certain financial information in order to facilitate the processing of payments. We use 3rd party (payment processor) services, so we do not collect and store credit card information (we receive information about the payment status and store only last 4 credit card digits).
                            <br/><span className="point">Communications, Chats, Messaging.</span> When you communicate with RmBOTO (using general inquiries window), we collect information about your communication and any information you choose to provide or disclose. In order to respond to your request, we may access information provided in your RmBOTO Account, purchase history, etc.
                            <br/><span className="point">Visitors and users of our User’s websites or services.</span> We may also collect information pertaining to visitors and users of our User’s websites or services (“Users-of-Users”), solely for and on our Users’ behalf.
                            <br/>You may also choose to provide us information when you fill in a form, conduct a search, update or add information to your RmBOTO Account, respond to surveys, post to community forums, participate in promotions, or use other features of the RmBOTO Platform. We advise against posting any information you don’t wish to make public on the RmBOTO Platform. If you upload any content to your account or post it on your Website and provide it in any other way as part of the use of any Service, you do so at your own risk.
                            <br/>RmBOTO processes information you provide to us on the <b>legal basis</b> of:
                            <br/>- Your consent, expressed when voluntarily submitting and filling your Personal Data details in sign-up forms, by e-mail, request or inquiries window, etc.; and
                            <br/>- Conclusion and performance of contractual arrangements and obligations between RmBOTO and the User;
                            <br/>- Pursuance of legitimate interests of RmBOTO, as Data Controller and manager of RmBOTO platform;
                            <br/>- Compliance with a legal obligation to which RmBOTO is subject, including, but not limited to, ICANN rules: <b>https://www.icann.org/resources/pages/approved-with-specs-2013-09-17-en#whois</b>.
                            <br/>Disclaimer
                            <br/>Please always take care and observe at least the following minimum requirements for the protection of your personal information:
                            <br/>- please omit using your name, address, telephone number, e-mail, personal identification number, date of birth, bank Account number, card number, other special (sensitive) data, etc. in the subject of the request or file name;
                            <br/>- please omit using your personal code, payment card number, and other financial information and details, health, family member details, or other specific (sensitive) data, in the texts of requests, e-mails or similar communication to us, and
                            <br/>- please make sure that the remaining personal data is only indicated to the extent necessary for the purposes for which the letter, request or inquiry is sent.
                            <br/>Right to delete your data
                            <br/>You may at any time access and edit, update or delete your contact details by logging into your RmBOTO's platform Account.
                            <br/>Please note that you will only be able to delete your email during de-activation of your RmBOTO Account. To deactivate your Account, please send your request to <b>support@rmboto.com</b>, and you will be provided with further guidance.
                            <br/>Information related to the calls or meetings you participate
                            <br/>In cases where you call RmBOTO or schedule an appointment for a meeting, such conversations will be recorded by RmBOTO International Ltd. for quality management purposes. As a result, we will be able to maintain and improve the quality of RmBOTO services based on RmBOTO quality standards and policies. For this purpose we will process the following data: email address, date and time of conversation, its duration, audio and/or video recording, any other personal data provided by you voluntarily during the conversation. Also, provided email address and / or phone No. may be used for the purposes of allowing you to reach us or contacting you regarding your matter.
                            <br/>In such cases processing is necessary for the purpose of the legitimate interests pursued by RmBOTO (under Article 6(1)( f) of GDPR) or is based on your consent, when you consent to video recording by turning video on, or provide certain data voluntary (under Article 6(1)(a) of GDPR).
                            <br/>The recordings will be kept up to for 30 days and deleted thereafter as soon as technically feasible.
                            <br/>3.2. Information We Collect when You use the Platform
                            <br/>When you use the RmBOTO Platform or contact us directly by any communication channel, we may collect information, including personal information, about the services you use and how you use them.
                            <br/>This information is necessary for the adequate performance of the contract between you and us, to enable us to comply with legal obligations and given our legitimate interest in being able to provide and improve the functionalities of the RmBOTO Platform.
                            <br/>Automatic collection of data
                            <br/><span className="point">Log data and Device information.</span>We automatically collect log data and device information when you access and use the RmBOTO Platform, even if you have not created an RmBOTO Account or logged in. That information includes, among other things: Internet protocol (IP) addresses, browser type, Internet service provider (ISP), referring/exit pages, operating system, date/time stamp and/or clickstream data.
                            <br/><span className="point">Tracking technologies and Cookies.</span>We use cookies, beacons, tags, scripts and other similar technologies, such as CI codes (click tracking), ISC (source tracking), and ITC (item tracking codes). We also automatically collect information about device's operating system, phone model, device ID and customer number. For more detail information on the use of these technologies, see our <b>Cookies Policy</b>.
                            <br/><span className="point">Usage information.</span>We use a tool called "Google Analytics" to collect information about your interactions with the RmBOTO Platform (what pages you visit, such as the pages or content you view, your searches for Listings, bookings you have made, and other actions on the RmBOTO Platform. In consequence, Google, Inc. plants a permanent cookie on your web browser to identify you as a unique user the next time you visit this Site). For more information please visit Google.
                            <br/><span className="point">Geo-location data.</span> We collect information about your approximate location as determined by data such as your IP address to offer you an improved user experience and comply with applicable legal requirements, such as taxes, pricing, etc. Such data may be collected only when you access RmBOTO platform using your device.
                            <br/>RmBOTO processes this information on the legal basis of:
                            <br/>- Your consent;
                            <br/>- Conclusion and performance of contractual arrangements and obligations between RmBOTO and the User;
                            <br/>- Pursuance of legitimate interests of RmBOTO, as Data Controller and manager of RmBOTO platform.
                        </span>
                    </p>

                    <h2>4. HOW WE USE YOUR DATA?</h2>
                    <p className="centered">
                        <span>
                            We use, store, combine and process information, including personal information, about you to provide, understand, improve, and develop the RmBOTO Platform, create and maintain a trusted and safer environment and comply with our legal obligations.
                            <br/><span className="point">To Identify.</span> Personal identification information is collected and processed for the purposes of User identification as well use of services and domain name registration. Additionally, this information may be used to provide Users with support, letting them know about upcoming updates or improvements, providing information regarding changes of any <b>Terms of Use</b> (including changes to this Policy) as well as other important information.
                            <br/><span className="point">To Create and Maintain Trusted Environment.</span> We verify or authenticate information or identifications provided by you (such as ID number, email or phone numbers). We also use collected information to detect and prevent fraud, spam, abuse, security incidents, and other illegal and harmful activities.
                            <br/><span className="point">To Create Aggregated Statistical Data, to Carry out Market Research and Analysis</span> necessary for running out our business, to improve our services we use aggregated and/or inferred non-personal information enabling us to evaluate our customers’ needs, sales and other trends of our activities.
                            <br/><span className="point">To Stay Connected.</span> We use information about data usage, devices, operating systems to diagnose problems with our servers, to administer our Platform, adopt decision when to retire SDK's/OS versions, to identify characteristics of major users so that we may optimize our applications and services and make your browsing and purchasing experience more enjoyable.
                            <br/><span className="point">To Customize Marketing.</span> To provide more customized offers to our Users, we may conduct data analysis based on information you provide to us, your interactions with the RmBOTO Platform and its Users.
                            <br/><span className="point">To Send Service and Billing Messages. </span> RmBOTO may also contact you with important information regarding our Services, or your use thereof. For example, we may send you a notice (through any of the communication channels available to us) if a certain Service is temporarily suspended for maintenance; reply to your support ticket or e-mail; send you reminders or warnings regarding upcoming or late payments for your current or upcoming subscriptions; forward abuse complaints regarding your hosting plan; or notify you of material changes in our Services. Such kind of communication is essential, for this reason, no opt-out possibilities are given to avoid receiving such Service and Billing Messages unless you are no longer a RmBOTO User (which can be done by deactivating your Account).
                            <br/><span className="point">To Register Domain. </span> In certain jurisdictions or pursuant to the rules of the ICANN or certain registries, domain name registration information has to be made available and accessible to the public through a "WHOIS" search. The WHOIS database is a publicly accessible database that lists the domain name registration information for a particular domain name, the name server(s) to which the domain name points, and the domain name's creation and expiration date. The domain name registration information you provide is stored by RmBOTO and is made available to the public through WHOIS searches. RmBOTO may deposit your domain name registration information with a third-party escrow provider to comply with ICANN requirements. At times, Users may receive solicitations that result from searches of the publicly available WHOIS database by other companies or individuals. Any such solicitations or e-mail communications do not come from RmBOTO and RmBOTO is not responsible for the use of WHOIS information by third parties. RmBOTO acts only on the basis of ICANN rules and requirements. For more information see:  <b>https://www.icann.org/resources/pages/approved-with-specs-2013-09-17-en#whois.</b>
                            <br/><span className="point">To contact you. </span> In order to notify you regarding your Account, to troubleshoot problems with your Account, to resolve a dispute, to collect fees or monies owed, to poll your opinions through surveys or questionnaires, to send updates about our company, or as otherwise necessary to contact you to enforce our User Agreement, applicable national laws, and any agreement we may have with you. For these purposes, we may contact you via email, telephone, text messages, and portal.
                            <br/><span className="point">To provide, operate and improve the Services.</span>
                            <br/><span className="point">To enhance data security and to prevent fraud.</span>
                            <br/><span className="point">To comply with applicable laws and regulations.</span>
                         </span>
                    </p>

                    <h2>5. INFORMATION PERTAINING TO VISITORS AND USERS OF OUR USER’S WEBSITES OR SERVICES</h2>
                    <p className="centered">
                        <span>
                            We may collect, store and process certain information pertaining to visitors and users of our User’s websites or services, solely on our Users’ behalf and at their direction. Each of our Users is able to collect and manage information, including personal data, via their website. Such information and personal data are then stored with RmBOTO. For such purposes, within the meaning of the GDPR, RmBOTO may in certain limited cases, as specified in the Terms of Service, be considered as a “data processor” of such information pertaining to visitors and users of their websites or services. The Users controlling and operating such User websites, shall be considered as the “data controllers” of such information, and shall be solely, completely and fully responsible and liable for complying with all laws and regulations that may apply to the collection and control thereof, including all privacy and data protection laws of all relevant jurisdictions.
                            <br/>User shall be solely, completely and fully responsible for the security, integrity and authorized usage of information related to visitors and users of their websites or services, and for obtaining consents, permissions and providing any fair processing notices required for the collection and usage of such information.
                            <br/>RmBOTO has no direct relationship with the individual visitors and users of our User’s websites or services whose personal data it processes. If you are a customer of any of our Users, and would like to make any requests regarding your personal data, please contact such User(s) directly.
                            <br/>For more information about data processing arrangement between us and our Users, please see our Terms of Service. 
                        </span>
                    </p>

                    <h2>6. DIRECT MARKETING AND SERVICE EXPIRATION NOTICES/RENEWAL REMINDERS</h2>
                    <p className="centered">
                        <span>
                            When creating an Account at our Platform, subject to the available preferences, you are free to give consent and/or opt-out from receiving various offers and/or notices directly to your e-mail, phone (SMS and/or Whatsapp messages) or in your Account. We use provided contact details and your preferences for direct marketing (by sending various offers or newsletters) and/or Service expiration notices / renewal reminders.
                            <br/>This communication, depending on your preferences, may be personalized taking into account any other information which you have provided to us (e.g. location, social media profile information, purchase history etc.) or we have collected or generated from other sources as described below.
                            <br/>Please note that in case you subscribe to service expiration notices / renewal reminders via SMS/WhatsApp, it is our right, but not an obligation, to send such notices / reminders for each Service.
                            <br/>Right to object / revoke consent 
                            <br/>If a User wishes to change their preferences communication (revoke consent or object), they are free to exercise such option at any time they wish by (1) following the instructions to unsubscribe in the received email (usually, by clicking on a link for that purpose at the bottom of our e-mail) / text message; (2) visiting and adjusting User’s personal account settings (communication preferences).
                            <br/>You may also at any time refuse to receive information from us by sending an email to <b>support@rmboto.com</b>.
                        </span>
                    </p>

                    <h2>7. OTHER USES OF YOUR PERSONAL DATA</h2>
                    <p className="centered">
                        <span>
                            <span className="point">Developing Platform.</span> We use data, including public feedback, to conduct research and development for the further development of our platform in order to provide you and others with a better, more intuitive and personalized experience, drive membership growth.
                            <br/><span className="point">Customer Support.</span>We use data to help you and fix problems. We use the data (which can include your communications) to investigate, respond to and resolve complaints and issues (e.g., bugs).
                            <br/><span className="point">Aggregate Insights.</span>We use data to generate aggregate insights. We use your data to produce and share aggregated insights that do not identify you.
                            <br/><span className="point">Security and Investigations.</span>We use data for security, fraud prevention and investigations. We use your data (including your communications) if we think it's necessary for security purposes or to investigate possible fraud or other violations of our Terms of User or this Policy and/or attempts to harm our Users.
                        </span>
                    </p>

                    <h2>8. SOURCES OF PERSONAL DATA</h2>
                    <p>
                        <span>
                            We collect and receive your Personal data from yourself (including your device) as well as from the following sources:
                            <br/>Social network operators (such as Facebook, Google, etc.);
                            <br/>Third-party service providers, suppliers and our partners;
                            <br/>Other legal sources.
                        </span>
                    </p>

                    <h2>9. RETENTION AND DELETION</h2>
                    <p className="centered">
                        <span>
                            We generally retain your personal information for as long as necessary to provide our services and to comply with our legal obligations. Where your personal information is no longer required we will ensure it is securely deleted.
                            <br/>If you would like to stop us using your personal information, you shall request that we erase your personal information and close your RmBOTO Account.
                            <br/>Third-party service providers, suppliers and our partners;
                            <br/>After de-activation of Account, we will still retain the following data for the purposes of compliance with applicable legal requirements (such as tax, accounting, legal reporting, AML, other), for as long as we are legally required by virtue of such legal requirements:
                            <br/>- profile with de-personalised information (we will change your email into user@deleted.com, erase you name, surname and contact information) like purchased services, login information, payment information, etc.
                            <br/>Please also note that we may further retain some of your personal information in such cases (i.e., after closure of your RmBOTO Account):
                            <br/>- as long as it is necessary for our legitimate business interests, such as fraud detection and prevention and enhancing safety. If RmBOTO suspends your RmBOTO Account for safety reasons, we may retain certain information from that RmBOTO Account to prevent that User from opening a new RmBOTO Account in the future;
                            <br/>- the extent necessary to comply with our legal obligations. RmBOTO may keep some of your information for tax, legal reporting and auditing obligations;
                            <br/>- forum posts or other publicly visible information may continue to be publicly available on the RmBOTO Platform, even after your RmBOTO Account is deactivated. However, attribution of such information to you will be removed. Additionally, some copies of your information (e.g., log records) may remain in our database, but are disassociated from personal identifiers;
                            <br/>- to resolve disputes;
                            <br/>- to enforce our agreements and/or pursue or protect our legitimate interests;
                            <br/>- as we protect RmBOTO Platform from accidental or malicious loss and destruction, residual copies of your personal information may not be removed from our backup systems for a limited period of time;
                            <br/>- domain name registration data - at the moment of writing this Policy, in order to register domain, we are requested to provide personal information about the owner of domain; this information is shown in WHOIS directory and is public. For more information, please visit <b>https://www.icann.org/registrants</b>.
                        </span>
                    </p>

                    <h2>10. DATA TRANSFERS AND USE OF THIRD-PARTY SERVICES</h2>
                    <p className="centered">
                        <span>
                            While collecting and processing your Personal Data, we may perform data transfers to various third parties.
                            <br/>10.1. With your consent
                            <br/>We will share your Personal Data with companies, organizations or individuals outside our group of companies when we have your consent to do so.
                            <br/>10.2. Where do we process your personal data?
                            <br/>Users' Personal Information may be maintained, processed and stored by RmBOTO and our authorized affiliates and service providers in the United Kingdom, Netherlands, Lithuania or Cyprus, and in other jurisdictions as necessary for the proper delivery of our Services and/or as may be required by law (as further explained below).
                            <br/>RmBOTO International Ltd is based in Cyprus, which is a member of EU and offers an adequate level of protection for the Personal Information of EU Member State residents.
                            <br/>RmBOTO affiliates and service providers (such as third-party data centres, servers, website design, administration services, online traffic and website analysis, statistics, direct marketing services, mailers, messengers, email service provider (when you choose to purchase or use such services), etc.), that store or process your personal data as well as personal data of your users on RmBOTO's behalf are each contractually committed to keep it protected and secured, in accordance with industry standards and regardless of any lesser legal requirements which may apply in their jurisdiction (see below).
                            <br/>Domain name registration data and identification data is shared with third parties in accordance with ICANN rules. For more information see <b>https://www.icann.org/resources/pages/approved-with-specs-2013-09-17-en#whois</b>.
                            <br/>We may share non-personally identifiable information publicly as well as with our partners - publishers, advertisers. For example, we may share information publicly to show trends about the general use of our platform.
                            <br/>If we are involved in a merger, reorganization, acquisition or sale, we will continue to ensure the confidentiality of any Personal Data and give all affected Users appropriate notices.
                            <br/>Some of our servers are located outside EU or European Economic Area (EEA), such as US, Brazil, Singapore, Indonesia, therefore, depending on your choice or the Services, your personal data as well as personal data of your users might be transferred to processors, sub-processors or other data recipients established in such third countries (i.e., data centers and servers, located outside EU or EEA, please see more here).  We will ensure that the said personal data will be transferred only if there is a sufficient basis for this under the GDPR and other applicable legal acts.
                            <br/>Standard contractual clauses for data transfers outside the EU or EEA. Your personal data as well as personal data of your users might be transferred to processors, sub-processors or other data recipients established in third countries (i.e., data centers and servers, located outside EU or EEA, please see more here). For such transfers to be compatible with the requirements of GDPR, we have concluded with the data processors and/or sub-processor relevant agreements on such data transfers outside EU or EEA, which comply with the European Commission approved standard contractual clauses for data transfers from data controllers in the EU to data processors and/or controllers established outside the EU or European Economic Area (EEA). For more see: <b>https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en</b>.  For the said purposes and to the extent relevant we have deemed that the said agreements were concluded on your behalf and under your instructions, as the case may be, all in line with Art. 46 of GDPR.
                            <br/>Other grounds for data transfers outside the EU or European Economic Area (EEA). Your personal data as well as personal data of your users might be transferred to data recipients established in third countries on other legal grounds compatible with the requirements of GDPR (Art. 45-49).
                            <br/>Transfer, disclosure of data to competent authorities.  In certain situations, RmBOTO may be asked to disclose personal data in response to lawful requests by public authorities, other competent authorities, including when it is required to meet national security or law enforcement requirements, and will do so where permitted by local data protection laws.
                            <br/>10.3. Data Localisation Obligations
                            <br/>If you reside in a jurisdiction that imposes "data localisation" or "data residency" obligations (i.e., requiring that Personal Information of its residents be kept within the territorial boundaries of such jurisdiction), and this fact comes to our attention, we may maintain your Personal Information within such territorial boundaries, if we are legally obligated to do so.
                            <br/>You acknowledge that while doing so, we may continue to collect, store and use your Personal Information elsewhere.
                            <br/>10.4. Targeting, advertising
                            <br/>We also use Facebook, Google, as well as other advertising and marketing tools. You should carefully read the privacy policy of these service providers. More information on the targeted advertising, as well as your opt-out rights and other rights, you will find on the respective websites of these service providers - <b>https://lt-lt.facebook.com/policies/ads#</b>; <b>https://policies.google.com/technologies/ads</b>.
                            <br/>10.5. Targeting, advertising
                            <br/>Our Services include certain Social Media features and widgets, single sign on features, such as the "Facebook Connect" or "Google Sign-in", the "Facebook Like" button, the "Share this" button or other interactive mini-programs ("Social Media Features"). These Social Media Features may collect information such as your IP address or which page you are visiting on our Website, and may set a cookie to enable them to function properly. Social Media Features are either hosted by a third party or hosted directly on our Services. Your interactions with these third parties' Social Media Features are governed by their respective policies.
                            <br/>10.6. Our Legal Obligation to Use or Disclose Personal Data
                            <br/>We will reveal your Personal Data to state and public authorities without your prior permission only when we are legally required to provide information, including taking legal action to defend our rights, as well as the cases, where we have a belief in good faith that access, use, preservation or disclosure of the information is reasonably necessary to meet any applicable law, regulation, legal process or enforceable governmental request, enforce applicable Terms of Services, including investigation of potential violations, detect, prevent or otherwise address fraud, security or technical issues.
                            <br/>10.7. Other
                            <br/>We may share your personal data in manners other than as described above, pursuant to your explicit consent, or if we are legally obligated to do so. 
                        </span>
                    </p>

                    <h2>11. SECURITY</h2>
                    <p className="centered">
                        <span>
                            RmBOTO has implemented security measures designed to protect the Personal Information you share with us, including physical, electronic and procedural measures. Among other things, we offer HTTPS secure access to most areas on our Services. We also regularly monitor our systems for possible vulnerabilities and attacks, and regularly seek new ways and Third-Party Services for further enhancing the security of our Services and protection of our Visitors' and Users' privacy.
                            <br/>Regardless of the measures and efforts taken by RmBOTO, we cannot and do not guarantee the absolute protection and security of your Personal Information, or any other User Content you upload, publish or otherwise share with RmBOTO or anyone else.
                            <br/>We therefore encourage you to set strong passwords for your User Account and avoid providing us or anyone with any sensitive information of which you believe its disclosure could cause you substantial or irreparable harm.
                            <br/>If you have any questions regarding the security of our Services, you are welcome to contact us at <b>support@rmboto.com</b>.
                        </span>
                    </p>

                    <h2>12. YOUR RIGHTS</h2>
                    <p className="centered">
                        <span>
                            You are entitled to a range of rights regarding the protection of your Personal Data, which are subject to limitations, restrictions and conditions as laid down in GDPR and applicable law. Those rights are:
                            <br/> - the right to access the information we process about you;
                            <br/> - the right to rectify incorrect/inaccurate information about you;
                            <br/> - the right to transfer all or part of the information collected about you to you or another data controller, where technically feasible (the right to data portability; 
                            <br/> - with limitations and restrictions as specified in the EU General Data Protection Regulation);
                            <br/> - the right to erase any data concerning you. Users may demand erasure of data without undue delay for legitimate reasons, e.g. where data is no longer necessary for the purposes it was collected, or where the data has been unlawfully processed;
                            <br/> - the right to the restriction of data processing. Users, for legitimate purposes, may obtain restriction of data processing from the controller;
                            <br/> - the right to object to the processing of Personal Data when processing is carried out on the basis of legitimate interest, as well as in cases of use of your personal data for direct marketing purposes, as specified above in this Policy.
                            <br/>Some of the rights as above are easy to exercise: i.e., you may at any time access and edit, update or amend your details, opt out of receiving communications from us and our partners by visiting and adjusting your personal account settings, or by e-mail: <b>support@rmboto.com</b>.
                            <br/>When you object to processing of Personal Data when processing is carried out on the basis of legitimate interest, we will carefully consider such a request, which may result in your Account closure or de-activation.
                            <br/>Users have the right to lodge a complaint with the national Data Protection Agency in their country of residence in the event where their rights may have been infringed. However, we recommend attempting to reach a peaceful resolution of the possible dispute by contacting us first.
                        </span>
                    </p>

                    <h2>13. ACCEPTANCE OF THIS POLICY</h2>
                    <p className="centered">
                        <span>
                            We assume that all Users of RmBOTO Platform have carefully read this document and agree to its contents. If someone does not agree with this Policy, they should refrain from using our website, and mobile applications. We reserve the right to change our Policy at any time and inform by using the way as indicated in Section 14. Continued use of RmBOTO's website and mobile applications implies acceptance of the revised Policy.
                            <br/>This Policy is an integral part of RmBOTO's Terms and Conditions.
                            <br/>We will ask for your consent before using information for a purpose other than those set out in this Policy.
                        </span>
                    </p>

                    <h2>14. CONTACTS</h2>
                    <p>
                        <span>
                            For all your requests or comments concerned with this Privacy Policy, please contact: <b>support@rmboto.com</b>.
                        </span>
                    </p>

                    <h2>15. APPLICATION OF POLICY</h2>
                    <p className="centered">
                        <span>
                            For all your requests or comments concerned with this Privacy Policy, please contact: <b>support@rmboto.com</b>.
                            <br/>Our Policy applies to all of the services offered by us and our affiliates, including <span className='company-name'/>, a private limited liability company, organized under the laws of the <span className='company-address'/>, but excludes services that have separate privacy policies that do not incorporate this Policy.
                            <br/>Our Policy does not apply to services offered by other companies or individuals, including products or sites that may be displayed to you in search results, sites that may include our services or other sites linked from our services.
                        </span>
                    </p>

                    <h2>16. AMENDMENTS</h2>
                    <p className="centered">
                        <span>
                            Our Policy may change from time to time. We will post any Policy changes on our website and, if the changes are significant, we may consider providing a more explicit notice (including, for certain services, email notification of Policy changes).
                        </span>
                    </p>

                    <h2>17. FURTHER INFORMATION</h2>
                    <p className="centered">
                        <span>
                            If you have any further questions regarding the data RmBOTO collects, or how we use it, then please feel free to contact Data Protection Officer at the details as indicated above hereof.
                        </span>
                    </p>
            </section>

            <style global jsx>{`
                .Privacy {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    margin-top: ${variables.headerMargin};
                    background: ${variables.paleblue}; 
                    .section {
                        background-color: ${variables.paleblue};
                        h2 {
                            font-size: 1.75rem;
                            font-weight: bold;
                            text-align: left;
                            margin: 1rem 0 0.5rem 0;
                        }
                        hr {
                            display: flex; 
                            justify-content: center;
                        }    
                       
                    }
                    .point {
                        font-weight: bold;
                    } 
                }
            `}</style>      
        </div>
    )
} 
export default Privacy;
import React, {useState} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import {NavLink} from "react-router-dom";
import classNames from "classnames";
import variables from "../../assets/sass/variables.scss";
import {signUpMe} from "../../config/ajax";
import routes from "../../config/routes";
import {RECAPTCHA_KEY} from "../../config/constants";
import {isValidEmail} from '../../config/validations';

const SignUp = props => {
  const [signUpData, setSignUpData] = useState({
    email: "",
    username: "",
    password: "",
    confPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [passwordInput, setPasswordInput] = useState({
    icon: 'eye-slash.svg',
    type: 'password'
  });
  const [confirmPasswordInputRef, setConfirmPasswordInputRef] = useState({
    icon: 'eye-slash.svg',
    type: 'password'
  });

  const changePasswordInputRef = () => {
    if (passwordInput.type === "text") {
      setPasswordInput({
        icon: "eye-slash.svg",    
        type: "password"
      })
    } else {
      setPasswordInput({
        icon: "eye.svg",
        type: "text"
      })
    }
  };

  const changeConfirmPasswordInputRef = () => {
    if (confirmPasswordInputRef.type === "text") {
      setConfirmPasswordInputRef({
        icon: "eye-slash.svg",    
        type: "password"
      })
    } else {
      setConfirmPasswordInputRef({
        icon: "eye.svg",
        type: "text"
      })
    }
  };

  const handleDataChange = (e) => {
    setSignUpData({
      ...signUpData,
      [e.target.id]: e.target.value
    })
  };

  const isValidUsername = username => {
    let pattern = /^([a-z]+)(_)?([a-z0-9]+)$/is;
    return pattern.test(String(username).toLowerCase());
  };

  const removeErrors = (e) => {
    setErrors(prevState => ({                                                
      ...prevState,                                            
      [e.target.id] : ''                       
    }))
  };

  const validateForm = () => {
    const {email, username, password, confPassword} = signUpData;
    const obj = {};

    if (email.length === 0) {
      obj.email = "E-mail is required!";                  
    } else if (!isValidEmail(email)) {
      obj.email = 'Please enter valid e-mail address!';             
    }
    
    if (username.length === 0) {
      obj.username = 'Username is required!';
    } else if (!isValidUsername(username)) { 
      obj.username = 'You cannot use symbols like those !, @, -, / and etc.!';   
    } else if (username.length < 2 || username.length > 20) {
      obj.username ='Username must be between 2 and 20 characters!';
    }
    
    if (password.length === 0) {                                          
      obj.password = 'Password is required!';
    } else if (password.length < 6 || password.length > 20) {
      obj.password = 'Password must be between 6 and 20 characters!';
    }
    
    if (confPassword.length === 0) {
      obj.confPassword = 'Password is required!';
    } else if (confPassword !== password) {
      obj.confPassword = 'Password does not match!';
    }

    if (captcha === false) {
      obj.captcha = 'Please prove that you are not robot!';
    }

    return obj;
  };

  const postData = (e) => {
    e.preventDefault();
    const errors = validateForm();

    if (Object.keys(errors).length === 0) {

      setIsLoading(true); 

      const object = {
        email: signUpData.email, 
        username: signUpData.username, 
        password: signUpData.password, 
        confirmPassword: signUpData.confPassword
      };

      signUpMe(object)   
        .then(() => {
          setIsLoading(true); 
          localStorage.setItem('signupEmail', JSON.stringify(object.email));
          props.navigate(routes.emailVerification);
        })
        .catch((error) => { 
          if (error.response.status === 400) {
            let e = error.response.data.data;
            setErrors(e);
            setIsLoading(false);
          } else if (error.response.status === 404) {
            props.navigate(routes.errorComponent);
          } else if (error.response.status === 422) {
            props.navigate(routes.emailVerification);
          } else {
            setIsLoading(false);
            props.navigate(routes.errorComponent);
          }
        })
    } else {
      setErrors(errors)
      setIsLoading(false)
    }   
  };
  
  const handleCaptchaChange = (value) => {
    setCaptcha(value !== null);
  };

  const emailClasses = classNames('input', {
    "is-danger" : errors.email
  });

  const usernameClasses = classNames('input', {
    'is-danger' : errors.username 
  });

  const passwordClasses = classNames('input', {
    'is-danger' : errors.password 
  });

  const repeatPasswordClasses = classNames('input', {
    'is-danger' : errors.confPassword 
  });

  const buttonClasses = classNames('button', 'blue', 'is-large', 'is-fit-content', {
    'is-loading' : isLoading
  });

  return (
    <div className='SignUp'>
      <section className="section">
        <h1 className="title has-text-centered">
          <div className="has-text-sky luck is-block">my</div>
          Account
        </h1> 
        <div className="login-signup">
          <NavLink to={routes.login} className="links">Login</NavLink>
          <NavLink to={routes.signup} className="links ml-3">Sign up</NavLink>
        </div>
        <form id="myForm" method="get" noValidate autoComplete="off">
          <div className="field">
            <label htmlFor="email" className="label">Your e-mail</label>
            <div className="control">
              <input 
                onChange={handleDataChange}
                value={signUpData.email}
                onFocus={removeErrors}
                className={emailClasses}
                type="email" 
                id="email"
                name="email" 
                placeholder="Enter valid e-mail"
              />
              {errors.email && (<span className="errors">{errors.email}</span>)}
            </div>
          </div>
          <div className="field">
            <label htmlFor="xyz123" className="label">Username</label>
            <div className="control">
              <input 
                onChange={handleDataChange}
                value={signUpData.username}
                onFocus={removeErrors}
                className={usernameClasses}
                type="text" 
                id="username" 
                name="username" 
                placeholder="Enter desired username"
              />
              {errors.username && (
                <span className="errors">{errors.username}</span>
              )}
            </div>
          </div>
          <div className="field">
            <label htmlFor="password" className="label">Password</label>
            <div className="control">
              <input 
                onChange={handleDataChange}
                value={signUpData.password}
                onFocus={removeErrors}
                className={passwordClasses}
                type={passwordInput.type} 
                id="password"
                name="password" 
                placeholder="Set your password"
                autoComplete="new-password"
              />
              <img
                className='eye'
                src={"/images/" + passwordInput.icon}
                onClick={changePasswordInputRef}
                alt="eye"
                width="32"
                height="32"
              />
            </div>
            {errors.password && (<span className="errors">{errors.password}</span>)}
          </div>
          <div className="field">
            <label htmlFor="confPassword" className="label">Confirm password</label>
            <div className="control">
              <input 
                onChange={handleDataChange}
                value={signUpData.confPassword}
                className={repeatPasswordClasses}
                onFocus={removeErrors}
                type={confirmPasswordInputRef.type}  
                autoComplete="off"
                id="confPassword"
                name="password" 
                placeholder="Repeat password"
              />
              <img
                className='eye'
                src={"/images/" + confirmPasswordInputRef.icon}
                onClick={changeConfirmPasswordInputRef}
                alt="eye"
                width="32"
                height="32"
              />
            </div>
            {errors.confPassword && (<span className="errors">{errors.confPassword}</span>)}
          </div>
          <div className="captcha">
            <div className="recap-wrapper">
              <ReCAPTCHA
                sitekey={RECAPTCHA_KEY}
                onChange={handleCaptchaChange}
              />
              <div className="recaptcha-error">
                {errors.captcha && (
                  <span className="errors">{captcha === false ? errors.captcha : ""}</span>
                )}
              </div>
            </div>
            <button 
              onClick={postData}
              className={buttonClasses}
              type="submit" 
            >
              Sign up
            </button>
          </div>
        </form>
      </section>
      <style jsx>{`
        .SignUp {
          flex: 1;
          margin-top: ${variables.headerMargin};
          height: calc(100vh - ${variables.headerMargin} - ${variables.footerHeight});
          background-color: ${variables.paleblue};
          text-align: center;
          .section {  
            .login-signup {
              margin-top: 4rem;
              font-size: 1.75rem;
            }
            form {
              width: 40%;
              margin-top: 2rem;
              margin-inline: auto;
              text-align: left;
              .captcha {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }
            }
          }
          @media all and (max-width: ${variables.mobileMax}) {
            .section {
              form {
                width: 100%;
                .captcha {
                  flex-direction: column;
                  gap: 1rem;
                }
              }
            }
          }
        }
      `}</style>
    </div>
  )
}

export default SignUp
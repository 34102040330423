import React, {useState, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Media from "react-media";
import {useAppContext} from "../../context/app-context";
import {setCookie, getCookie} from "../../config/utils";
import screenVariables from '../../assets/sass/screensVariables.scss';
import variables from '../../assets/sass/variables.scss';
import routes from "../../config/routes";
import {TOKENS} from "../../config/constants";
import {getServers} from "../../config/ajax";
import {getUsername} from "../../config/methods";

const MyAccount = (props) => {
    const [servers, setServers] = useState();
    const navigate = useNavigate();
    const {setLastClickedProduct, isMobile} = useAppContext();

    useEffect(() => {
        getServers()
            .then((res) => {
                setServers(res.data)
            })
            .catch((err) => {
                console.log(err);
            })

    }, [])
    

    const clearAccessToken = () => {
        let accessTokenValue = getCookie(TOKENS.AT);
        setCookie(TOKENS.AT, accessTokenValue, 0);
    };
    
    const clearRefreshToken = () => {
        let refreshTokenValue = getCookie(TOKENS.RT);
        setCookie(TOKENS.RT, refreshTokenValue, 0);
    };

    const logout = () => {
        clearAccessToken();
        clearRefreshToken();
        setCookie("toast-logout", "true", 0.00002000);
        setLastClickedProduct(null);
        navigate(routes.home);
        window.location.reload(false);
    };

    return(
        <div className="MyAccount">
            <Media            //MOBILE
				query={{maxWidth: variables.tabletMax}}
				defaultMatches={isMobile}
			>
                <section className="section">
                    <div className="container">
                        <h1 className="title has-text-centered">
                            <div className="has-text-sky luck is-block">my</div>
                            Account
                        </h1>   
                        <div className="welcome-wrapper">
                            <div className="is-size-4 line-height-1 mb-2">Welcome back</div>
                            <div className="is-size-4 luck has-text-sky line-height-1">{getUsername()}</div>
                        </div>
                        <div className="big-button">
                            <Link className="rectangle" to={routes.myServers}>
                                <img 
                                    src="/images/database.svg" 
                                    alt="database" 
                                    width={48}   
                                    height={46}
                                />
                                <div className="text">
                                    <span className="is-size-5">My servers</span>
                                    <span className="is-size-6 wrap">Click to manage all your servers</span>
                                </div>
                            </Link>
                        </div>
                        <div className="icons-wrapper">
                            <div className="icons-content">
                                <Link className="rectangle first-button" to={routes.passwordChangeLogin}>
                                    <img
                                        src="/images/password.svg"
                                        alt="lock"
                                        width={36}
                                        height={36}
                                    />
                                    <div className="text is-size-6">Change password</div>
                                </Link>
                                <Link className="rectangle second-button" to={routes.billingDetails}>
                                    <img
                                        src="/images/box-open.svg"
                                        alt="box"
                                        width={36}
                                        height={36}
                                    />
                                    <div className="text is-size-6">Billing Details</div>
                                </Link>
                                <div className="rectangle third-button" onClick={logout}>
                                    <img
                                        src="/images/sign-out.svg"
                                        alt="logout"
                                        width={36}
                                        height={36}
                                    />
                                    <div className="text is-size-6">Logout</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Media>

			<Media
				query={{minWidth: variables.desktopMin}}
				defaultMatches={!isMobile}
			>
                <section className="section">
                    <div className="container">
                        <h1 className="title has-text-centered">
                            <div className="has-text-sky luck is-block">my</div>
                            Account
                        </h1>   
                        <div className="welcome-wrapper">
                            <div className="is-size-5">Welcome back</div>
                            <div className="is-size-3 luck has-text-sky">{getUsername()}</div>
                        </div>
                        <div className="big-button">
                        {servers?.length !== 0 ? 
                            <Link className="rectangle" to={routes.myServers}>
                                <img 
                                    src="/images/database.svg" 
                                    alt="database" 
                                    width="48"    
                                    height="48"
                                />
                                <div className="text">
                                    <span className="is-bold">My machines ({servers?.length})</span>
                                    <span>Click to manage all your servers</span>
                                </div>
                            </Link>
                        :
                            <div className="rectangle">
                                <img 
                                    src="/images/database.svg" 
                                    alt="database" 
                                    width="48"    
                                    height="48"
                                />
                                <div className="text">
                                    <span className="is-bold">My machines ({servers?.length})</span>
                                    <span>You have no machines</span>
                                </div>
                                <Link to={routes.products} className="button ml-auto">
                                    Get started
                                </Link>
                            </div>
                        }
                        </div>
                        <div className="icons-wrapper">
                            <div className="icons-content">
                                <Link className="rectangle first-button" to={routes.passwordChangeLogin}>
                                    <img
                                        src="/images/password.svg"
                                        alt="lock"
                                        width={48}
                                        height={48}
                                    />
                                    <div className="text">Change password</div>
                                </Link>
                                <Link className="rectangle second-button" to={routes.billingDetails}>
                                    <img
                                        src="/images/box-open.svg"
                                        alt="box"
                                        width={48}
                                        height={48}
                                    />
                                    <div className="text">Billing Details</div>
                                </Link>
                                <div className="rectangle third-button is-clickable" onClick={logout}>
                                    <img
                                        src="/images/sign-out.svg"
                                        alt="logout"
                                        width={48}
                                        height={48}
                                    />
                                    <div className="text">Logout</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Media>

            <style global jsx>{`
                .MyAccount{
                    background: ${variables.paleblue};
                    margin-top: ${variables.headerMargin};
                    .container {
                        .welcome-wrapper {
                            margin-top: 2rem;
                            line-height: 250%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        }
                        .big-button {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 2rem;
                            .rectangle {
                                display: flex;
                                flex-direction: row; 
                                align-items: flex-end;
                                justify-content: flex-start;
                                width: 50%;
                                padding: 3.5rem;
                                .text {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    margin-left: 2rem;
                                }
                            }
                        }
                        .icons-wrapper {
                            width: 100%;  
                            display: flex;
                            align-items: center;    
                            justify-content: center;
                            .icons-content {
                                margin-top: 4rem;
                                margin-bottom: 4rem;
                                display: flex;
                                justify-content: space-between;
                                gap: 1rem;
                                width: 50%;
                                padding: 0 auto;
                            }  
                        }
                        button {
                            border: 0;
                        }
                    }
                    @media all and (max-width: ${screenVariables.mobileMax}) {
                        flex-grow: 1;
                        .section {
                            .container {
                                width: 100%;
                                margin: 0;
                                .welcome-wrapper {
                                    margin: 1.875rem 0 0 0;
                                }
                                .big-button {
                                    margin-top: 2rem;
                                    .rectangle {
                                        align-items: center;
                                        justify-content: flex-start;
                                        width: 100%;
                                        padding: 2rem 1.25rem;
                                        height: fit-content;
                                        .text {
                                            margin: 0 0 0 1.125rem;
                                            font-size: 1rem;
                                            white-space: nowrap;
                                        }
                                    }
                                }
                                .icons-content {
                                    width: 100%;
                                    margin: 2rem 0 0 0;
                                    .first-button {
                                        height: 7.5rem;
                                        margin-right: 1rem !important;
                                        text-align: center;
                                    }
                                    .second-button {
                                        height: 7.5rem;
                                        margin-right: 1rem !important;
                                        text-align: center;
                                    }
                                    .third-button {
                                        height: 7.5rem;
                                        text-align: center;
                                    } 
                                }
                            }
                        }
                    }
                }
            `}</style>
        </div>
    )
};
export default MyAccount;
import React, {useState, useEffect, useRef, Fragment} from 'react'
import {useNavigate, useLocation, Link} from 'react-router-dom';
import Media from 'react-media';
import classNames from 'classnames';
import {useAppContext} from '../../context/app-context';
import AddCardIFrame from '../common/AddCardIFrame';
import ModalDialog from '../common/ModalDialog';
import CardPanel from '../common/CardPanel';
import OrderSummary from '../common/OrderSummary';
import variables from '../../assets/sass/variables.scss';
import {getCardsInfo, getCard, submitPayment, getServerDetails} from '../../config/ajax';

const Payment = props => {
  const [card, setCard] = useState(null);
  const [isVisibleAddCardModal, setAddCardVisibility] = useState(false);
  const [addCardParams, setAddCardParams] = useState({});
  const [isVisible3dSecureForm, setIsVisible3dSecureForm] = useState(false);
  const [isLoadingCompleteOrderButton, setIsLoadingCompleteOrderButton] = useState(false);
  const [params3dSecureForm, setParams3dSecureForm] = useState(null);
  const [isLoadingAddCardBtn, setAddCardLoadingBtn] = useState(false);
  const [isVisibleInfoModal, setIsVisibleInfoModal] = useState(false);
  const [infoModalMessage, setInfoModalMessage] = useState('');
  const {formData, initialData, setIsPaymentSuccessful, setFormData, setInitialData, isMobile} = useAppContext();
  let navigate = useNavigate();
  const location = useLocation(); 
  const form3dSecure = useRef(null);
  const infoModalTitle = infoModalMessage.split(':').shift();
  const infoModalContent = infoModalMessage.split(':').pop();
  const infoModalURL = `/products/${infoModalMessage.split('/')[2]}/configure`;

  useEffect(() => {
    const id = +location.pathname.split('/').pop();

    getServerDetails(id)
      .then((res) => {
        setInitialData({
          osImages: [],
          product: {
            icon: res.data?.icon,
            type: res.data?.type,
            color: res.data?.color,
            cpu: res.data?.cpu,
            ram: res.data?.ram,
            disk: res.data?.disk
          }
        })
        setFormData({
          OS: res.data?.osImage,
          periodPrice: res.data?.price,
          periodString: res.data?.period,
          name: res.data?.name,
          connectionRadiosId: res.data?.ssh_auth
        })
      })

    getCard()
      .then((res) => {
        setCard(res.data.card)
        if (!res.data.card) {
          getCardsInfo()
            .then(res => {
                setAddCardParams(res.data.addCardParams)
                // setAddCardLoadingBtn(false);
                setAddCardVisibility(true);
            })
            .catch(e => {
                console.log(e);
            })
        }
      })
      .catch((err) => {
        console.log(err);
      })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    
    if (isVisible3dSecureForm && params3dSecureForm !== null) {
      form3dSecure.current.submit(); 
    }
  }, [isVisible3dSecureForm, params3dSecureForm])
  
  const return3dSecureForm = () => {

    let {action_url, post_key, post_data} = params3dSecureForm;

    return (
      <form action={action_url}
        method="post"
        style={{width: "1px", height: "1px", opacity: "0.1", overflow: "hidden"}}
        id="3dSecureForm"
        ref={form3dSecure}
      >
        <div className="has-text-centered has-text-weight-bold">3D Secure Required!</div>
        <input type="hidden" name={post_key} value={post_data}/>
      </form>
    )

};

  const closeAddCard = () => setAddCardVisibility(false);

  const closeInfoModal = () => setIsVisibleInfoModal(false);

  const redirectTo = async (success) => {
    const id = +location.pathname.split('/').pop();
    
    if (success === 1) {
      await setIsPaymentSuccessful(true)
      navigate(`/order-completed/${id}`)
    } else {
      await setIsPaymentSuccessful(false)
      navigate(`/order-completed/${id}`)
    }
  };

  const getCreditCard = () => {
    getCard()
      .then((res) => {
        setCard(res.data.card);
      })
      .catch((err) => {
        console.log(err);
      })
  };

  const onClickCompleteOrder = () => {
    setIsLoadingCompleteOrderButton(true);
    const id = +location.pathname.split('/').pop();

    const data = {
      serverId: id
    }

    submitPayment(data)
      .then((res) => {
        if (res.data?.success === 0) {

          let {type, params} = res.data;

          if (type === "secure" && params["action_url"]) {

            let paymentOrderId = res.data.orderId;

            window.sessionStorage.setItem('paymentOrderId', JSON.stringify(paymentOrderId));
            window.sessionStorage.setItem('formData', {formData});
            window.sessionStorage.setItem('initialData', {initialData});

            setIsVisible3dSecureForm(true)
            setParams3dSecureForm(params)

          } else {
            redirectTo(0)
          }
          setIsLoadingCompleteOrderButton(false);
        } else {
          setIsLoadingCompleteOrderButton(false);
          redirectTo(1)
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 422) {
          setIsVisibleInfoModal(true);
          setInfoModalMessage(err.response.data.message)
        } else {
          redirectTo(0)
        }
        setIsLoadingCompleteOrderButton(false);
      });
  };

  const buttonClasses = classNames('button', 'is-large', {
    'is-loading': isLoadingCompleteOrderButton,
    'mt-5 mr-auto': !isMobile,
    'mx-auto': isMobile 
  });

  return (
    <section className='Payment section'>
      {isVisible3dSecureForm && return3dSecureForm()}

      {isVisibleInfoModal && (
        <ModalDialog 
          isMobile={false}
          title={infoModalTitle}
          hasClosedButton
          icon="icon-close"
          classNames='is-35rem-width'
          zIndex={1000}
          closeModal={closeInfoModal}
        >
          <div className='has-text-centered p-4'>
            {infoModalContent}
          </div>
          <footer className='is-flex is-justify-content-space-between is-align-items-center'>
            <Link 
              to={infoModalURL} 
              className="links"
            >
              Go back
            </Link>
            <button 
              className='button' 
              onClick={onClickCompleteOrder}
            >
              Try again
            </button>
          </footer>
        </ModalDialog>
      )}

      {!card || isVisibleAddCardModal ?
        <div className='left'>
          <h1 className='is-size-3-half has-text-marine is-bold'>Add credit/debit card</h1> 
          {isVisibleAddCardModal && (
            <AddCardIFrame 
              closeDialog={closeAddCard}
              getCard={getCreditCard}
              addCardParams={addCardParams}
            />
          )}
        </div>
      : 
        <div className='left'>
          <Media            //MOBILE
            query={{maxWidth: variables.tabletMax}}
            defaultMatches={isMobile}
          >
            <Fragment>
              <h1 className='is-size-3-half has-text-marine is-bold'>Confirm payment</h1> 

              <div className='mc-warning-container'>
                <div className='shield-icon mr-2'/>
                <div>
                  <div className='is-flex'>
                    <span>We can accept only</span>
                    <span className='mc-logo'/>
                  </div>
                  <div>
                    at this point. Please, tap the three dots and try with another card. We are sorry for the inconvenience!
                  </div>
                </div>
              </div>

              <CardPanel
                card={card}
                setAddCardParams={setAddCardParams}
                setAddCardVisibility={setAddCardVisibility}
                setAddCardLoadingBtn={setAddCardLoadingBtn}
                isLoadingAddCardBtn={isLoadingAddCardBtn}
              />

              <div className='is-flex is-justify-content-center mt-4'>
                <button 
                  className={buttonClasses}
                  onClick={onClickCompleteOrder}
                >
                  Complete order
                </button>
              </div>

            </Fragment>
          </Media>

          <Media
            query={{minWidth: variables.desktopMin}}
            defaultMatches={!isMobile}
          >
            <Fragment>
              <h1 className='is-size-3-half has-text-marine is-bold'>Confirm payment</h1> 

              <CardPanel
                card={card}
                setAddCardParams={setAddCardParams}
                setAddCardVisibility={setAddCardVisibility}
                setAddCardLoadingBtn={setAddCardLoadingBtn}
                isLoadingAddCardBtn={isLoadingAddCardBtn}
              />

              <div className='mc-warning-container'>
                <div className='shield-icon'/>
                <div>
                  <div className='is-flex'>
                    <div>We can accept only</div>
                    <div className='mc-logo'/>
                    <span>at this point Please, tap the </span>
                  </div>
                  <div>
                    three dots and try with another card. We are sorry for the inconvenience!
                  </div>
                </div>
              </div>

              <button 
                className={buttonClasses}
                onClick={onClickCompleteOrder}
              >
                Complete order
              </button>
            </Fragment>
          </Media>
        </div>
      }
      <div className='right'>
        <OrderSummary
          icon={initialData?.product?.icon}
          type={initialData?.product?.type}
          color={initialData?.product?.color}
          cpu={initialData?.product?.cpu}
          ram={initialData?.product?.ram}
          disk={initialData?.product?.disk}
          OS={formData?.OS}
          periodPrice={formData?.periodPrice}
          periodString={formData?.periodString}
          name={formData?.name}
          connectionRadiosId={formData?.connectionRadiosId}
        />
      </div>
      <style global jsx>{`
        .Payment {
          background-color: ${variables.paleblue};
          margin-top: ${variables.headerMargin};
          flex-grow: 1;
          display: flex;
          justify-content: flex-start;
          .mc-warning-container {
            display: flex;
            align-items: center;
            padding: 0.75rem;
            margin-top: 1rem;
            background-color: #F4F7FA;
            border: 1px dashed #E03C6B;
            border-radius: 10px;
            font-size: 0.875rem;
            .warning-message {
              white-space: nowrap;
            }
            .shield-icon {
              background-image: url('/images/shield-exclamation.svg');
              background-repeat: no-repeat;
              background-position: center;
              width: 2rem;
              height: 1.5rem;
            }
          }
          .mc-logo {
            background-image: url('/images/MC-logo.svg');
            background-repeat: no-repeat;
            width: 8rem;
            height: 1.5rem;
            margin-left: 0.5rem;
          }
          .add-card {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 0.5rem;
            padding: 2rem;
            margin-block: 2rem;
            color: #302D2C;
            border-radius: 2rem;
            background-color: #F4F7FA;
            box-shadow: 20px 20px 35px rgba(71, 168, 189, 0.2), -20px -20px 35px #ffffff, inset 0px 0px 0px rgba(71, 168, 189, 0.2), inset 0px 0px 0px #ffffff;
            .creditCard {
              width: 3rem;
              height: 2.25rem;
              background-image: url('/images/credit-card.svg');
            }
          }
          @media all and (max-width: ${variables.mobileMax}) {
            .mc-warning-container {
              .warning-message {
                white-space: nowrap;
              }
              .shield-icon {
                width: 4rem;
              }
            }
          }
        }
      `}</style>
    </section>
  )
}

export default Payment
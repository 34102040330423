import React from "react";
import {Link} from "react-router-dom";
import variables from "../../assets/sass/variables.scss"
import routes from "../../config/routes";

const ToLoginSignup = () => {
    return (
        <div className="ToLoginSignup">
            <section className="section"> 
                <div className="container">
                    <div className="block">
                        <img  
                            className="hero"
                            src="/images/login-signup-hero2x.png" 
                            alt="hero" 
                            width={400} 
                            height={400}    
                        />
                        <h1 className="title has-text-centered">
                            Login or Sign up
                            <div className="has-text-sky luck is-block">to continue</div>
                        </h1> 
                        <span className="is-size-5 my-2">Enjoy all of RMBoto by being a member of our community!</span>
                    </div>
                    <div className="button-wrapper">
                        <Link to={routes.login} className="button paleblue is-large">
                            <img src="/images/sign-out-alt.svg" alt="circle-icon" width={30} height={32}/>
                            Login
                        </Link>
                        <Link to={routes.signup} className="button is-large">
                            <img src="/images/circle-user.svg" alt="circle-icon" width={30} height={32}/>
                            Sign up
                        </Link>
                    </div>
                </div>
            </section> 
            <style global jsx>{`
                .ToLoginSignup {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 5rem;
                    background: ${variables.paleblue};
                    margin-top: ${variables.headerMargin};
                    height: 80vh;
                    .section {
                        .container {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            .block {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: flex-end;
                                background-color: white;
                                border-radius: ${variables.radiusExtra};
                                box-shadow: 0px 0px 20px #0000000D;
                                margin: 0 auto;
                                min-height: 20rem;
                                padding: 2rem;
                                .hero {
                                    position: absolute;
                                    top: -12rem;
                                    left: calc(50% - 200px);
                                }
                            }
                            .button-wrapper { 
                                margin-top: 2rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 1rem;
                                .button {
                                    width: 11.25rem;
                                }
                                .with-icon {
                                    padding: 1.5rem 2.5rem;
                                }
                            }
                        }
                    }
                    @media all and (max-width: ${variables.mobileMax}) {
                        flex-grow: 1;
                        height: auto;
                        margin-top: unset;
                        .section {
                            padding: 0;
                            .container {
                                .block {
                                    background-color: unset;
                                    box-shadow: unset;
                                    .hero {
                                        position: relative;
                                        top: unset;
                                        left: unset;
                                    }
                                }
                                .button-wrapper {
                                    margin: 0 0 2rem 0;
                                    .button {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }  
                }
            `}</style>
        </div>
    )
}

export default ToLoginSignup;
import {getCookie} from "./utils";
import {TOKENS} from "./constants";

export const parseJwt = (token) => {
	var base64Url = token?.split('.')[1];
	var base64 = base64Url?.replace(/-/g, '+')?.replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(window?.atob(base64)?.split('')?.map(function(c) {
		return '%' + ('00' + c?.charCodeAt(0)?.toString(16))?.slice(-2);
	})?.join(''));
	
	return JSON.parse(jsonPayload);
};

export const getUserId = () => {
	let accessToken = getCookie(TOKENS.AT);
	let jwt = parseJwt(accessToken);
	return (jwt?.sub);
};

export const getUsername = () => {
	let accessToken = getCookie(TOKENS.AT);
	let jwt = parseJwt(accessToken);
	return (jwt?.username);
};

export const isEmpty = (param) => {
	if (!param) {
		return true;
	} else {
		if (Array.isArray(param)) {
			return param.length === 0;
		} else if (typeof param === 'object') {
			return Object.keys(param).length === 0;
		} else if (typeof param === 'string') {
			return param.trim().length === 0;
		}
		return false;
	}
};
import React, {Fragment, useState} from 'react';
import Media from 'react-media';
import classNames from 'classnames';
import {useAppContext} from '../../context/app-context';
import variables from '../../assets/sass/variables.scss';
import {getCardsInfo} from '../../config/ajax';

const CardPanel = ({card, setAddCardParams, setAddCardVisibility, setAddCardLoadingBtn, isLoadingAddCardBtn}) => {
  const [isOpenCardDots, setIsOpenCardDots] = useState(false);
  const {isMobile} = useAppContext();

  const openAddCard = () => {    
        
    setAddCardLoadingBtn(true);

    getCardsInfo()
      .then(res => {
        setAddCardParams(res.data.addCardParams);
        setAddCardVisibility(true);
        setAddCardLoadingBtn(false);
      })
      .catch(e => {
        console.log(e);
      })
  };

  const toggleCardMenu = () => setIsOpenCardDots(!isOpenCardDots);

  const dotsDropdownClasses = classNames('dots-dropdown', {
    'is-open': !isOpenCardDots
  });

  const addCardBtnClasses = classNames("button", {
    "is-loading": isLoadingAddCardBtn
  });

  return (
    <div className="cardPanel">
      {card ?
        <Fragment>
          <Media            //MOBILE
            query={{maxWidth: variables.tabletMax}}
            defaultMatches={isMobile}
          >
            <Fragment>
              <div>
                <div className="creditCard"/>

                <div>
                  <div>Card number</div>
                  <div className='is-bold'>{card.number}</div>
                </div>
              </div>

              <div>
                <div>
                  <div>Name on card</div>
                  <div className='is-bold'>{card.name}</div>
                </div>

                <div>
                  <div>Expiry</div>
                  <div className='is-bold'>{card.expiry}</div>
                </div>
              </div>

              <div className="dots is-clickable" onClick={toggleCardMenu}>
                <img 
                  className="user pointer-events-none"
                  src="/images/menu-dots-vertical.svg" 
                  alt="dots" 
                  width={5}
                  height={24}  
                />
                <ul className={dotsDropdownClasses}>
                  <li onClick={openAddCard}>Replace</li>
                </ul>
              </div>
            </Fragment>
          </Media>

          <Media
            query={{minWidth: variables.desktopMin}}
            defaultMatches={!isMobile}
          >
            <Fragment>
              <div className="creditCard"/>

              <div>
                <div>Card number</div>
                <div className='is-bold'>{card.number}</div>
              </div>
              
              <div>
                <div>Expiry</div>
                <div className='is-bold'>{card.expiry}</div>
              </div>

              <div>
                <div>Name on card</div>
                <div className='is-bold'>{card.name}</div>
              </div>

              <div className="dots is-clickable" onClick={toggleCardMenu}>
                <img 
                  className="user pointer-events-none"
                  src="/images/menu-dots-vertical.svg" 
                  alt="dots" 
                  width={5}
                  height={24}  
                />
                <ul className={dotsDropdownClasses}>
                  <li onClick={openAddCard}>Replace</li>
                </ul>
              </div>
            </Fragment>
          </Media>
        </Fragment>
      :
        <Fragment>
          <Media            //MOBILE
            query={{maxWidth: variables.tabletMax}}
            defaultMatches={isMobile}
          >
            <div>
              <div className='is-flex mb-4'>
                <div className="creditCard no-card mr-3"/>
                <div>
                  <div className="is-bold">Card on file</div>
                  <div className='nowrap'>You have no card added</div>
                </div>
              </div>
              <div 
                className={addCardBtnClasses}
                onClick={openAddCard}
              >
                Add a card
              </div>
            </div>
          </Media>

          <Media
            query={{minWidth: variables.desktopMin}}
            defaultMatches={!isMobile}
          >
            <Fragment>
              <div className="creditCard"/>
              <div>
                <div className="is-bold">Card on file</div>
                <div>You have no card added</div>
              </div>
              <div 
                className={addCardBtnClasses}
                onClick={openAddCard}
              >
                Add a card
              </div>
            </Fragment>
          </Media>
        </Fragment>
      }
      <style jsx global>{`
        .cardPanel {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 2rem; 
          margin-block: 2rem;
          color: #302D2C;
          border-radius: 2rem;
          background-color: #F4F7FA;
          box-shadow: 20px 20px 35px rgba(71, 168, 189, 0.2), -20px -20px 35px #ffffff, inset 0px 0px 0px rgba(71, 168, 189, 0.2), inset 0px 0px 0px #ffffff;
          .creditCard {
            width: 3rem;
            height: 2.25rem;
            background-image: url('/images/credit-card.svg');
          }
          @media all and (max-width: ${variables.mobileMax}) {
            .creditCard {
              width: 4rem;
              height: 3.5rem;
              background-image: url('/images/credit-card.svg');
              background-repeat: no-repeat;
              background-size: 3rem 3.5rem;
            } 
            .dots {
              .dots-dropdown {
                top: -4rem;
                right: 1rem;
              }
            }
          }
        }
      `}</style>
    </div>
  )
}

export default CardPanel
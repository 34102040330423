import {useRef, useEffect} from "react";

const Burger = (props) => {
  const {toggleNavbar, isNavbarOpen} = props;
  const checkboxRef = useRef();

  useEffect(() => {
    checkboxRef.current.checked = isNavbarOpen;
  }, [isNavbarOpen])

  return (
    <div className="Burger">
      <input type="checkbox" id="checkbox" className="checkbox is-hide" ref={checkboxRef} onClick={toggleNavbar}/>
      <label htmlFor="checkbox">
        <div className="hamburger">
          <span className="bar1"/>
          <span className="bar2"/>
          <span className="bar3"/>
          <span className="bar4"/>
        </div>
      </label>
      
      <style global jsx>{`
        .Burger {
          .is-hide {
            position: absolute; 
            overflow: hidden; 
            height: 1px; 
            width: 1px; 
            padding: 0; 
            border: 0; 
          }
          .hamburger {
            margin: 0 auto;
            width: 30px;
            height: 30px;
            position: relative;
          }
          .hamburger span {
            padding: 0;
            height: 4px;
            background-color: #000000;
            display: block;
            border-radius: 2px;
            transition: all 0.4s ease-in-out;
            position: absolute; 
          }
          .bar1 {
            width: 30px;
            top: 0;
          }
          .bar2, .bar3 {
            top: 13.5px;
          }
          .bar3 {
            width: 22px;
            left: 0;
          }
          .bar4 {
            width: 14px;
            bottom: 0;
          }
          .checkbox:checked + label {
            .bar1 {
              transform: translateX(40px);
              background-color: transparent;
            }
            .bar2{
              width: 30px;
              transform: rotate(45deg);
            }
            .bar3{
              width: 30px;
              transform: rotate(-45deg);
            }
            .bar4{
              transform: translateX(-40px);
              background-color: transparent;
            }  
          }
        }
      `}</style>
    </div>
  )
}

export default Burger;
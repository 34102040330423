const routes = {
  home: "/",
  products: "/products",
  about: "/about",
  contacts: "/contacts",
  login: "/login",
  signup: "/signup",
  paymentMethods: "/payment-methods",
  billingDetails: '/billing-details',
  orderCompleted: "/order-completed/:id",
  product: "/product",
  emailVerification: "/email-verification",
  errorComponent: "/404",
  forgotPassword: "/users/forgot/password", //1
  landingPage: "/users/password/forgot", //2
  emailConfirmation: "/users/email/confirm",
  passwordChangeLogin: "/users/change-password",
  loginOrSignup: "/login-or-signup",
  myAccount: "/my-account",
  myServers: "/my-servers",
  configureServer: "/products/:id/configure",
  configuredServerPayment: "/products/:id/configure/:createdId", 
  customServer: '/products/custom',
  querySent: '/query-sent',
  privacy: "/privacy-policy",
  termsOfUse: "/terms-of-use",
  cookiePolicy: "/cookie-policy",
};

export default routes;
import React, {Fragment, useEffect, useState} from 'react'
import {useLocation, Link} from 'react-router-dom';
import {useAppContext} from '../../context/app-context';
import OrderSummary from '../common/OrderSummary'
import variables from "../../assets/sass/variables.scss";
import routes from '../../config/routes';
import {checkPayment, getServerDetails} from '../../config/ajax';

const ThankYou = props => {
  const [isLoading, setIsLoading] = useState(false);
  const {isPaymentSuccessful, setIsPaymentSuccessful, initialData, setInitialData, formData, setFormData} = useAppContext();
  const location = useLocation();
  
  useEffect(() => {
    let paymentOrderId = sessionStorage.getItem("paymentOrderId");

    if (paymentOrderId) {
      setIsLoading(true)

      let data = {
        orderId: paymentOrderId
      }

      checkPayment(data)
        .then(() => {
          setIsPaymentSuccessful(true)
          setIsLoading(false)
        })
        .catch(() => { 
          setIsPaymentSuccessful(false)
          setIsLoading(false)
        })

      sessionStorage.removeItem("paymentOrderId");
    }

    const id = +location.pathname.split('/').pop();

    getServerDetails(id)
      .then((res) => {
        setInitialData({
          osImages: [],
          product: {
            icon: res.data?.icon,
            type: res.data?.type,
            color: res.data?.color,
            cpu: res.data?.cpu,
            ram: res.data?.ram,
            disk: res.data?.disk
          }
        })
        setFormData({
          OS: res.data?.osImage,
          periodPrice: res.data?.price,
          periodString: res.data?.period,
          name: res.data?.name,
          connectionRadiosId: res.data?.ssh_auth
        })
      })

    return () => {
      setFormData({
        osImageId: 1,
        OS: '',
        periodId: 1,
        periodString: '',
        periodPrice: '',
        name: '',
        connectionRadiosId: 0,
        username: '',
        sshAuth: 0,
        sshKeyContent: '',
        password: ''
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='ThankYou section'>
      {isLoading ? (
        <div className='current-status is-loading is-bigger m-auto'/>
      ) : (
        <Fragment>
          {isPaymentSuccessful ?
            <div className='container has-text-centered'>
              <h1 className='title has-text-centered'>
                Order
                <span className='has-text-sky luck line-height-1 is-block'>completed</span>
              </h1>
              
              <p className='my-4'>
                Thank you!<br/>
                We’ve received your payment. Bellow you can find your order details. You can also find your order history in your account as well as manage all your machines.<br/>
                *Note that your machine may take 
                <span className='is-bold ml-1'>
                  up to 8 hours for activation.
                </span>
              </p>

              <OrderSummary
                isCentered
                withoutArrow
                notFixed
                icon={initialData?.product?.icon}
                type={initialData?.product?.type}
                color={initialData?.product?.color}
                cpu={initialData?.product?.cpu}
                ram={initialData?.product?.ram}
                disk={initialData?.product?.disk}
                OS={formData?.OS}
                periodPrice={formData?.periodPrice}
                periodString={formData?.periodString}
                name={formData?.name}
                connectionRadiosId={formData?.connectionRadiosId}
              />

              <Link to={routes.myAccount} className='button is-large is-fit-content mt-5 mx-auto'>
                Go to Dashboard
              </Link>
            </div>
          : 
            <div className='container has-text-centered'>
              <h1 className='title has-text-centered'>
                Payment
                <span className='has-text-sky luck line-height-1 is-block'>failed</span>
              </h1>

              <div className='mt-6'>
                <div className='is-size-4	is-bold'>
                  There was an error with the payment and we couldn’t process your payment.
                </div>
                <div>
                  Please, check your payment details and try again. 
                </div>
                <Link to={routes.myAccount} className='button is-large is-fit-content mt-5 mx-auto'>
                  Go to Dashboard
                </Link>
              </div>
            </div>
          }
        </Fragment>
      )
      }
      <style global jsx>{`
        .ThankYou {
          display: flex;
          flex: 1;
          margin-top: ${variables.headerMargin};
          background: ${variables.paleblue};
          @media all and (max-width: ${variables.mobileMax}) {
            display: block;
          }
        }
      `}</style>
    </div>
  )
}

export default ThankYou
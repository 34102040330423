export const getCookie = (cname) => {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
    while (c.charAt(0) === ' ') {
        c = c.substring(1);
    }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};
  
export const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + "; path=/";
};

export const disableBodyScroll = (hideScroll, isMobile, unsetTopPosition) => {

    const body = document.body;
    const html = document.documentElement;
    const scrollY = html.style.getPropertyValue('--scroll-y');
    const header = document.getElementById('header');

    if (!hideScroll && document.body.scrollHeight > window.innerHeight) {
        html.style.overflowY = 'scroll';
    }

    body.style.position = 'fixed';

    if (hideScroll && !isMobile && document.body.scrollHeight > window.innerHeight) {

        body.style.left = '0';
        body.style.right = '0.5rem';
        if (header) header.style.paddingRight = '1.25rem';

    }

    if (!unsetTopPosition) {
        body.style.top = `-${scrollY}`;
    }

};

export const enableBodyScroll = () => {

    const body = document.body;
    const html = document.documentElement;
    const scrollY = body.style.top;
    const header = document.getElementById('header');

    if (document.body.scrollHeight > window.innerHeight) {
        html.style.overflowY = '';
    }

    body.style.position = '';
    body.style.top = '';
    body.style.left = '';
    body.style.right = '';

    if (header) header.style.paddingRight = '0.75rem';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);

};

export const clearNumber = (value = '') => value.replace(/\D+/g, '');
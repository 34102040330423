import React, {Fragment, useState} from "react";
import {Link} from "react-router-dom";
import {BarLoader} from "react-spinners";
import classNames from "classnames";
import variables from "../../assets/sass/variables.scss";
import screenVariables from "../../assets/sass/screensVariables.scss";
import routes from "../../config/routes";
import {isEmpty} from "../../config/methods";
import {forgotPassword} from "../../config/ajax";
import {isValidEmail} from "../../config/validations";

const ForgotPassword = props => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const handleDataChange = (e) => setEmail(e.target.value);

    const validateForm = () => {
        let errorStr;

        if (email.length === 0) {
            errorStr = "Email is required!";
        } else if (!isValidEmail(email)) {
            errorStr = 'Please enter valid e-mail address!';             
        } 
        return errorStr;
    };

    const removeErrors = () => setError('');

    const sendEmail = (e) => { 
        e.preventDefault();

        let errors = validateForm();
        
        const obj = {email: email};

        if (isEmpty(errors)) {
            setIsLoading(true);

            forgotPassword(obj)
                .then((res) => {
                    setIsLoading(false);
                    setIsEmailSent(true);
                })
                .catch((error) => {
                    if (error.response.status === 400) {
                        errors = error.response.data.data;
                        setError(errors);
                        setIsLoading(false);
                    }
                });
        } else {
            setError(errors);
        }
    };

    const resendEmail = (e) => {
        e.preventDefault();
        setIsVisible(true);

        let obj = {email: email};

        forgotPassword(obj)
            .then((res) => {
                setIsVisible(false);
            })
            .catch((err) => {
                setIsVisible(false);
            });
    };
        
  const inputClasses = classNames('username', 'input', {
    'is-danger': error 
  });
  
  const buttonClasses = classNames('button', 'is-large', 'blue', 'mb-3', 'mx-auto', 'is-fit-content', {
    'is-loading': isLoading,
    'is-large': props.isMobile
  });

  return (
    <div className="ForgotPassword">
        <section className="section">
            {!isEmailSent ? (
                <Fragment>
                    <h1 className="title has-text-centered">
                        <div className="has-text-sky luck is-block">my</div>
                        Account
                    </h1>
                    <div className="text-wrapper">
                        <div>
                            Forgot your password?
                        </div>
                        <div>
                            Please enter your email and we will send you reset password instructions.
                        </div>
                    </div>
                    <form noValidate>
                        <div className="user-password">
                            <div className="field mt-3 has-text-left">
                                <label htmlFor="email" className="label">Email</label>
                                <div className="control">
                                    <input 
                                        onChange={handleDataChange}
                                        value={email}
                                        className={inputClasses}
                                        onFocus={removeErrors}
                                        type="text" 
                                        id="email" 
                                        name="email" 
                                        placeholder="Enter your email"
                                    />
                                </div>
                                {error && (<span className="errors">{error}</span>)}
                            </div>  
                        </div>
                        <button 
                            className={buttonClasses}      
                            onClick={sendEmail}
                        >
                            Reset password
                        </button>
                        <Link className="links is-size-5-half" to={routes.login}>Back to Login</Link>
                    </form>
                </Fragment>
            ) : (
                <Fragment>
                    {isVisible ?
                        <div className="loader-wrapper">
                            <BarLoader 
                                color={variables.sky}
                                width={250}
                                height={4}
                            />
                        </div>
                    : 
                        <Fragment>
                            <h1 className="title has-text-centered">
                                <div className="has-text-sky luck is-block">my</div>
                                Account
                            </h1>
                            <div className="text-wrapper mt-6">
                                <div className="is-bold is-size-4">
                                    Reset password email sent.
                                </div>
                                <div>
                                    Please check your email and follow the instructions to reset your password.
                                </div>
                            </div>
                            <Link 
                                className="button btn is-large is-fit-content mx-auto"
                                to={routes.login}
                            >
                                Back to Login
                            </Link>
                            <div className="text-wrapper">
                                <div>
                                    Didn’t get an email?
                                </div>
                                <div>
                                    Please check your email and follow the instructions to reset your password. - 
                                    <span className="is-bold is-size-5-half ml-2" onClick={resendEmail}> 
                                        Resend email
                                    </span>
                                </div>
                            </div>
                        </Fragment>
                    }
                </Fragment>
            )}
        </section>
        <style jsx global>{`
            .ForgotPassword {
                flex-grow: 1;
                margin-top: ${variables.headerMargin};
                background-color: ${variables.paleblue};
                .section {
                    text-align: center;
                    .text-wrapper {
                        margin-block: 2rem;
                    }
                    form {
                        width: 40%;
                        margin-inline: auto;
                        button {
                            margin-block: 1rem;
                        }
                    }
                }  
                @media all and (max-width: ${screenVariables.mobileMax}) {
                    .section {
                        form {
                            width: 100%;
                        }
                    }
                }
            }
        `}</style>
    </div>  
  )
}

export default ForgotPassword;
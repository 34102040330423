import React, {useState} from 'react'
import {Link, NavLink} from "react-router-dom";
import {useAppContext} from "../../context/app-context";
import classNames from "classnames";
import variables from "../../assets/sass/variables.scss";
import screenVariables from '../../assets/sass/screensVariables.scss';
import {logMe} from "../../config/ajax";
import {setCookie} from "../../config/utils";
import {isEmpty} from "../../config/methods";
import {TOKENS_DURATION} from '../../config/constants';
import routes from "../../config/routes";

const Login = props => {
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
    remember: 0
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [passwordInputRef, setPasswordInputRef] = useState({
    icon: "eye-slash.svg",    
    type: "password"
  });
  const [notification, setNotification] = useState({message: ''});
  
  const {email, password, remember} = loginData;
  const {lastClickedProduct, setLastClickedProduct} = useAppContext();
  
  const changePasswordInputRef = e => {
    if (passwordInputRef.type === "text") {
      setPasswordInputRef({
        icon: "eye-slash.svg",    
        type: "password"
      })
    } else {
      setPasswordInputRef({
        icon: "eye.svg",
        type: "text"
      })
    }
  };

  const handleDataChange = e => setLoginData({
    ...loginData,
    [e.target.name]: e.target.value
  });

  const handleCheckboxChange = e => setLoginData({
    ...loginData,
    remember: !e.target.checked ? 0 : 1
  });

  const removeError = (e) => {
    setErrors(prevState => ({
      ...prevState,
      [e.target.name]: ''
    }));
  };

  const validateForm = () => {
    const obj = {};

    if (email?.length === 0) {
      obj.email = "Email is required!"
    }

    if (password?.length === 0) {
      obj.password = "Password is required!"
    }

    return obj;
  };

  const sendLogInfo = e => {
    e.preventDefault();

    const newErrors = validateForm();

    if (!isEmpty(newErrors)) {
      setErrors(newErrors)
    } else {
      setIsLoading(true)

      const obj = {
        email: email,
        password: password,
        remember: remember
      };
        
      logMe(obj)
        .then(response => {
          setIsLoading(false);
          setCookie("refreshToken", response.data.refreshToken, TOKENS_DURATION.ONE_WEEK);
          setCookie("accessToken", response.data.accessToken, TOKENS_DURATION.ONE_DAY);
          setCookie("toast", "true", TOKENS_DURATION.TWO_SECONDS);
          if (lastClickedProduct) {
            props.navigate(`/products/${lastClickedProduct}/configure`, { replace: true });
            setLastClickedProduct(null);
          } else {
            props.navigate(routes.home, { replace: true });
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            setNotification({message: error.response.data.message});
            setIsLoading(false);
          } else {
            console.log(error);
            setNotification({
              message: error.response.data.message
            });
            setErrors(error.response.data.data)
            setIsLoading(false)
          }
        });
    } 
  };

  const usernameClasses = classNames("username", "input", {
    "is-danger": errors.email
  });

  const passwordClasses = classNames('password', 'input', {
    "is-danger": errors.password 
  });

  const notificationWrapperClasses = classNames('notification-wrapper', {
    'is-hidden': !notification.message
  })

  const buttonClasses = classNames('button', 'blue', 'is-large', 'is-fit-content', 'mx-auto',{
    'is-loading': isLoading
  });   
  
  return (
    <div className='Login'>
      <section className="section">
        <h1 className="title has-text-centered">
          <div className="has-text-sky luck is-block">my</div>
          Account
        </h1>   
        <div className="login-signup">
          <NavLink to={routes.login} className="links">Login</NavLink>
          <NavLink to={routes.signup} className="links ml-3">Sign up</NavLink>
        </div>
        <form 
          id="myForm" 
          method="get" 
          noValidate
        >
          <div className={notificationWrapperClasses}>
            <div className="notification"> 
              <div className="notification-text">{notification.message}</div>
            </div>
          </div>
          <div className="user-password has-text-left">
            {!isEmpty(errors) && (<span className="errors">{errors.message}</span>)}
            <div className="field">
              <label htmlFor="email" className="label">Username or e-mail</label>
              <div className="control">
                <input 
                  onChange={handleDataChange}
                  value={email}
                  className={usernameClasses}
                  onFocus={removeError}
                  type="text" 
                  id="email" 
                  name="email" 
                  placeholder="Enter your username"
                />
              </div>
              {errors.email && (<span className="errors">{errors.email}</span>)}
            </div>  
            <div className="field">
              <label htmlFor="password" className="label">Password</label>
              <div className="control">
                <input 
                  onChange={handleDataChange}
                  value={password}
                  className={passwordClasses}
                  onFocus={removeError}
                  type={passwordInputRef.type} 
                  id="password" 
                  name="password" 
                  placeholder="Enter your password"
                />
                <img
                  className="eye"
                  src={"/images/" + passwordInputRef.icon}
                  onClick={changePasswordInputRef}
                  alt="eye"
                  width="32"
                  height="32"
                />
              </div>
              {errors.password && (<span className="errors">{errors.password}</span>)}
            </div>
          </div>
          <div className="buttons-wrapper">
            <div className="field">
              <input 
                checked={remember}
                onChange={handleCheckboxChange}
                id="remember" 
                type="checkbox" 
                name="switchRoundedDefault" 
                className="switch is-rounded" 
              />
              <label htmlFor="remember">
                Remember me 
              </label>
            </div>
            <button 
              onClick={sendLogInfo}
              className={buttonClasses} 
              type="submit"
            >
              Login
            </button>
          </div>
        </form>
        <Link className="links is-size-5-half" to={routes.forgotPassword}>Forgot password?</Link>
      </section>
      <style jsx>{`
        .Login {
          flex: 1;
          margin-top: ${variables.headerMargin};
          height: calc(100vh - ${variables.headerMargin} - ${variables.footerHeight});
          background-color: ${variables.paleblue};
          text-align: center;
          .section {  
            .login-signup {
              margin-top: 4rem;
              font-size: 1.75rem;
            }
            .notification-wrapper {
              margin-block: 2rem;
              .notification {
                background: rgba(189, 25, 96, 0.1);
                border-radius: ${variables.radiusLarge};
                .notification-text {
                  color: ${variables.burgundy};
                }
              }
            }
            form {
              width: 40%;
              margin-top: 2rem;
              margin-inline: auto;
              .buttons-wrapper {
                margin-top: 1rem;
                margin-bottom: 1rem;
                .checkbox {
                  margin: 0.5rem;
                  display: flex;
                }
              }
            }
          }
          @media all and (max-width: ${screenVariables.mobileMax}) {
            .section {
              form {
                width: 100%;
              }
            }
          }
        }
      `}</style>
    </div>
  )
}

export default Login
import React from 'react';
import {Link} from 'react-router-dom';
import variables from '../../assets/sass/variables.scss';
import routes from '../../config/routes';

const QuerySent = props => {

  return (
    <section className='QuerySent'>
      <h1 className="title has-text-centered mb-6">
        Order
        <span className="has-text-sky luck is-block">received</span>
      </h1>

      <div className='wrapper'>
        <div className='is-bold is-size-4 mb-3 nowrap'>Thank you for your order.</div>
        <div className='is-size-6-half mb-4'>We’ve received your inquiry and we will get back to you as soon as possible with a personalized offer for your needs.</div>
        <Link to={routes.home} className='button is-fit-content mx-auto'>Return Home</Link>
      </div>

    <style global jsx>{`
      .QuerySent {
        flex: 1; 
        text-align: center;
        margin-top: ${variables.headerMargin};
        padding: ${variables.desktopPadding};
        background-color: ${variables.paleblue};
        position: relative; 
        .wrapper {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        @media all and (max-width: ${variables.mobileMax}) {
          padding: ${variables.mobilePadding};
        }
      }
    `}</style>
    </section>
  )
}

export default QuerySent
import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import Media from "react-media";
import {useAppContext} from "../../context/app-context";
import routes from "../../config/routes";
import variables from "../../assets/sass/variables.scss";
import screenVariables from '../../assets/sass/screensVariables.scss';

const ErrorComponent = () => {
    
    const {isMobile} = useAppContext();

    return( 
        <div className="Error">
			<Media            //MOBILE
				query={{maxWidth: variables.tabletMax}}
				defaultMatches={isMobile}
			>
				<Fragment>
                    <div className="ops-wrapper">
                        <h1 className="title has-text-centered">
                            <div className="has-text-sky luck is-block">Ooops...</div>
                            Something went wrong
                        </h1>
                    </div>
                    <div className="logo-wrapper">
                        <img
                            src="/images/404.svg"
                            alt="error-hero"
                            width={400}
                            height={400}
                        />
                    </div>
                    <div className="text-wrapper">
                        <div className="is-size-5">This page can not be found…</div>
                        <div className="is-size-5-half">
                            …browse some of our
                            <Link to={routes.products} className="links is-size-5-half active"> 
                                &nbsp;products&nbsp;
                            </Link>
                            or&nbsp; 
                            <Link to={routes.home} className="links is-size-5-half active">
                                return home.&nbsp;
                            </Link>
                        </div>
                    </div>
				</Fragment>
			</Media>

			<Media
				query={{minWidth: variables.desktopMin}}
				defaultMatches={!isMobile}
			>
				<Fragment>
                    <h1 className="title has-text-centered">
                        <div className="has-text-sky luck is-block">Ooops...</div>
                        Something went wrong
                    </h1>
                    <div className="logo-wrapper">
                        <img
                            src="/images/404.svg"
                            alt="error-hero"
                            width={400}
                            height={400}
                        />
                    </div>
                    <div className="text-wrapper">
                        <div className="is-size-3">This page can not be found, in the meantime…</div>
                        <div className="is-size-5">
                            …browse some of our&nbsp;
                            <Link to={routes.products} className="links active"> 
                                products&nbsp;
                            </Link>
                            or&nbsp; 
                            <Link to={routes.home} className="links active">
                                return home.&nbsp;
                            </Link>
                        </div>
                    </div>
				</Fragment>
			</Media>

            <style jsx global>{`
                .Error {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    padding-top: 2.5rem;
                    margin-top: ${variables.headerMargin};
                    background: ${variables.paleblue};
                    .logo-wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .text-wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        padding-bottom: 3rem;
                        .links {
                            color: black;
                            transition: all 400ms ease;
                            &:after {
                                border-color: black;
                            }
                            &:hover {
                                color: ${variables.sky};
                                &:after {
                                    border-color: ${variables.sky};
                                }
                            }
                        }
                    }
                    @media all and (max-width: ${screenVariables.mobileMax}) {  
                        min-height: fit-content;
                        .logo-wrapper {
                            margin-top: 1rem;
                        }
                        .text-wrapper {
                            padding: 0 1rem;
                            margin-bottom: 1rem;
                            margin-top: 2rem;
                        }
                    }
                }
            `}</style>
        </div>
    )
}
export default ErrorComponent;
import axios from "axios";
import {getCookie, setCookie} from "./utils"
import APP_CONFIG from "./appConfig";
import {SERVER_ERRORS, TOKENS} from "./constants";

let isRefreshingToken = false;
let refreshSubscribers = [];
 
const subscribeTokenRefresh = cb => {
  refreshSubscribers.push(cb);
};
 
const onTokenRefreshed = token => {
  refreshSubscribers.forEach(cb => cb(token));
  refreshSubscribers = [];
};

let refreshToken = getCookie(TOKENS.RT);
let accessToken = getCookie(TOKENS.AT);

export const getAccess = () => accessToken.length !== 0 ? `Bearer ${getCookie(TOKENS.AT)}` : null;
export const getRefresh = () => refreshToken.length !== 0 ? `Bearer ${getCookie(TOKENS.RT)}` : null;

const api = axios.create({
  baseURL: APP_CONFIG.apiUrl
});

const authApi = axios.create({
  baseURL: APP_CONFIG.apiUrl,
  headers: {Authorization: getAccess()}
});

const authApiR = axios.create({
  baseURL: APP_CONFIG.apiUrl,
});

authApi.interceptors.request.use(config => {
  config.headers["Authorization"] = `Bearer ${getCookie(TOKENS.AT)}`;
  return config;
});

authApi.interceptors.response.use(
  res => res, 
  e => {
    const originalRequest = e.config;
    
    if (e?.response?.status === 403) {
    
      let {code} = e.response.data;

      if (code === SERVER_ERRORS.ERROR_BAD_TOKEN) {

        let refreshToken = getCookie(TOKENS.RT);
        
        if (refreshToken) {

          console.log('isRefreshingToken:  ', isRefreshingToken);

          if (!isRefreshingToken) {
            isRefreshingToken = true;
            
            authApiR.defaults.headers.common["Authorization"] = 'Bearer ' + refreshToken;

            refreshTokens()
              .then((res) => {
                setCookie("accessToken", res?.data?.accessToken, 1);
                setCookie("refreshToken", res?.data?.refreshToken, 1);
                onTokenRefreshed(res?.data?.accessToken);

                authApi.defaults.headers.common["Authorization"] = 'Bearer ' + res?.data?.accessToken;
                
                isRefreshingToken = false;
                
                // return authApi({
                //   ...e.config,
                //   headers: {
                //     ...e.config.headers,
                //     'Authorization': `Bearer ${res?.data?.accessToken}`
                //   }
                // });
              })
              .catch(error => {
                console.log(error);
                isRefreshingToken = false;
              })
          }

          const retryOriginalRequest = new Promise(resolve => {
            subscribeTokenRefresh(token => {
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
              resolve(authApi(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
      } else 
      return Promise.reject(e);
    } else {
      return Promise.reject(e);
    }
  }
);

  //COUNTRIES
  export const getAllCountries = () => api.get('/countries');

  //EMAIL CONFIRMATION
  export const confirmEmail = (token) => api.post(`/users/email/confirm?token=${token}`);
  
  export const resendConfirmationEmail = (token) => api.post(`/users/email/resend-confirmation?token=${token}`);


  //SIGNUP and LOGIN and AUTHENTICATION
  export const logMe = (data) => api.post('/login', data);

  export const signUpMe = (data) => api.post('/signup', data);

  export const resendEmail = (email) => api.post('/users/email/resend-confirmation', email);

  export const refreshTokens = () => authApiR.get(`/token/refresh`);

  export const forgotPassword = (email) => api.post('/users/password/forgot', email);

  export const verifyPasswordResetToken = (token) => api.get(`/users/password/verify-token?token=${token}`);

  export const changePassword = (data) => api.post('/users/password/change', data);

  export const onLoginChangePassword = (id, data) => authApi.post(`/users/${id}/change-password`, data); 
  
  //CONTACT US 
  export const sendContactForm = (data) => api.post("/contact", data);

  //?????????????????????????????????????????????????????????????????
  export const getBillingDetails = (id) => authApi.get(`/users/${id}/billing-details`);
  
  export const createPayment = (link, data) => authApi.post(link, data);
  
  export const orderHistory = (id) => authApi.get(`/orders/${id}`);
  //??????????????????????????????????????????????????????????????????

  //PRODUCTS
  export const getProducts = () => api.get('/products');

  export const getProduct = (id) => api.get(`/products/${id}`);

  export const getProductDetails = (id) => authApi.get(`/products/${id}`);

  export const getConfigureInitialData = (id) => authApi.get(`/products/${id}/configure`);


  //SERVERS 
  export const addServer = (data) => authApi.post('/servers', data);

  export const getServers = () => authApi.get('/servers');

  export const getServerDetails = (id) => authApi.get(`/servers/${id}`);

  export const deleteServer = (id) => authApi.delete(`/servers/${id}`);

  export const startServer = (id) => authApi.patch(`/servers/${id}/start`);

  export const stopServer = (id) => authApi.patch(`/servers/${id}/stop`);

  export const sendCustom = (data) => authApi.post('/servers/custom', data);

  //CARDS & ORDERS
  export const getCard = () => authApi.get(`/billing-details`);

  export const submitPayment = (data) => authApi.post('/payments', data);

  export const checkPayment = (orderId) => authApi.post('/payments/confirm3d', orderId);

  export const createOrder = (data) => authApi.post('/orders', data);

  export const getCardsInfo = () => authApi.get('/cards/info');
import React, {memo, useEffect} from 'react';
import classNames from "classnames";
import PropTypes from 'prop-types';
import variables from '../../assets/sass/variables.scss';
import {enableBodyScroll, disableBodyScroll} from '../../config/utils';

const DialogOverlay = (props) => {

	useEffect(() => {

		if (props.removeScrolling) {
			disableBodyScroll(Boolean(props.hideScroll));
			return () => enableBodyScroll();
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let style = {
		zIndex: props.zIndex ?? variables.zIndex
	};

	let dialogClasses = classNames('DialogOverlay', 'has-cursor-default', 'is-clipped-x',
		{'is-fixed-overlay': !props.isAbsolute},
		{'is-absolute-overlay': props.isAbsolute},
		{[props.className]: props.className}
	);

	return (
		<div 
			className={dialogClasses}
			style={style}
			onClick={e => e.stopPropagation()}
		>
		<style jsx>{`
			.DialogOverlay {
				background-color: rgba(transparent, 0.2); 
				backdrop-filter: ${props.withoutBlur ? "none" : "blur(20px)"};
				&.is-fixed-overlay {
					position: fixed;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
				}
				&.is-absolute-overlay {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
				}
			}
		`}</style>
		</div>
	)
};

DialogOverlay.propTypes = {
	zIndex: PropTypes.any,
	opacity: PropTypes.number,
	isAbsolute: PropTypes.bool,
	hideScroll: PropTypes.bool,
	removeScrolling: PropTypes.bool,
	withoutBlur: PropTypes.bool
};

export default memo(DialogOverlay);
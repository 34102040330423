import {createContext, useState, useContext} from 'react';
import MobileDetect from 'mobile-detect';

export const AppContext = createContext();
AppContext.displayName = 'AuthContext';

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({children}) => {
    const userAgent = window.navigator.userAgent;
    const md = new MobileDetect(userAgent);

    const [formData, setFormData] = useState({
        osImageId: 0,
        OS: '',
        periodId: 0,
        periodString: '',
        periodPrice: '',
        name: '',
        connectionRadiosId: 0,
        username: '',
        sshAuth: 0,
        sshKeyContent: '',
        password: '',
    });
    const [initialData, setInitialData] = useState([]);
    const [newOrder, setNewOrder] = useState();
    const [isMobile] = useState(Boolean(md.mobile()));
    const [isTablet] = useState(Boolean(md.tablet()));
    const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
    const [lastClickedProduct, setLastClickedProduct] = useState(null);


    const contextValue = {
        formData,
        setFormData,
        initialData,
        setInitialData,
        newOrder,
        setNewOrder,
        isMobile, 
        isTablet,
        isPaymentSuccessful,
        setIsPaymentSuccessful,
        lastClickedProduct, 
        setLastClickedProduct
    };

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    );
};
import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import TagManager from 'react-gtm-module';
import './assets/sass/app.scss';
import App from './components/common/App';
import {GTM_ID} from './config/constants';

const _JSXStyle = require('styled-jsx/style').default

if (typeof global !== 'undefined') {
  Object.assign(global, { _JSXStyle })
}

const tagManagerArgs = {
    gtmId: GTM_ID
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<BrowserRouter>
		<App/>
	</BrowserRouter> 
);
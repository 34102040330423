import React, {useState, useEffect, Fragment} from "react";
import {Link, useNavigate} from "react-router-dom";
import Media from "react-media";
import classNames from "classnames";
import {ToastContainer ,toast} from "react-toastify";
import ModalDialog from "../common/ModalDialog";
import {useAppContext} from "../../context/app-context";
import variables from '../../assets/sass/variables.scss';
import screenVariables from '../../assets/sass/screensVariables.scss';
import {SERVER_STATUS, STATUS_RUNNING, STATUS_STOPPED} from '../../config/constants';
import {getServers, deleteServer, startServer, stopServer, getProducts} from "../../config/ajax";
import routes from "../../config/routes";

const MyServers = (props) => {
  const {isMobile} = useAppContext(); 
  const [isOpenMenu, setIsOpenMenu] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleteServerId, setDeleteServerId] = useState();
  const [deleteInput, setDeleteInput] = useState('');
  const [deleteServerName, setDeleteServerName] = useState(''); 
  const [deleteInputError, setDeleteInputError] = useState('');
  const [isCollapse, setIsCollapse] = useState({
    state: false,
    id: 0
  });
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {

    getServers()
      .then((res) => {
        setInitialData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      })
  }, [])

  const onClickDots = (e) => {
    setIsOpenMenu(prevState => ({
      [e.target.id]: !prevState[e.target.id]
    }));
  };

  const toggleCollapsing = (e) => {
    setIsCollapse(prevState => ({
        state: isCollapse.state && isCollapse.id === +e.target.id ? false : true, 
        id: prevState.id === +e.target.id ? null : +e.target.id
      })
    );
  };

  const onClickStartServer = (e) => {

    // const chosenServer = initialData.find((el) => (
    //   +el.id === +e.target.id && el
    // ));

    // console.log(+chosenServer?.status === STATUS_PENDING_PAYMENT);

    startServer(e.target.id)
      .then((res) => {
        // console.log(res);
        toast(`You just START your machine with ID: ${e.target.id}`, {
          className: "toast-login",
          position: "bottom-center",
          autoClose: 5000,
          closeButton: false,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }; 

  const onClickStopServer = (e) => {
    stopServer(e.target.id)
      .then((res) => {
        console.log(res);
        toast(`You just STOP your machine with ID: ${e.target.id}`, {
          className: "toast-login",
          position: "bottom-center",
          autoClose: 5000,
          closeButton: false,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }; 

  const onClickDeleteServer = (e) => {
    setIsOpenDeleteModal(true);
    setDeleteServerId(+e.target.id);
    setDeleteServerName(e.target.dataset.name)
  };

  const onClickModalDeleteButton = (e) => {
    if (deleteInput.length === 0) {
      setDeleteInputError('Machine name is required!');
    } else if (deleteServerName !== deleteInput) {
      setDeleteInputError('Entered machine name is not correct!');
    } else {
      deleteServer(deleteServerId)
        .then((res) => {
          getServers()
            .then((res) => {
              setInitialData(res.data);
            })
          
            toast(`You just delete your machine with ID: ${deleteServerId}`, {
              className: "toast-login",
              position: "bottom-center",
              autoClose: 5000,
              closeButton: false,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

          closeDialog()
        })
        .catch((err) => {
          console.log(err);
        })
    }
  };

  const closeDialog = () => {
    setIsOpenDeleteModal(false)
    setDeleteInput('')
  };

  const onClickPayButton = e => {
    const id = +e.target.dataset.id;
    const type = e.target.dataset.type;
    const price = e.target.dataset.price

    getProducts()
      .then((res) => {
        const products = res.data.find((el) => (type === el.type && el.products))

        const productId = products.products.find((el) => (price === el.price && el.id))

        navigate(`/products/${+productId.id}/configure/${id}`)
      })
      .catch((err) => {
        console.log(err);
      })
  };

  const removeErrors = () => {
    setDeleteInputError('')
  };

  const handleDeleteInput = (e) => {setDeleteInput(e.target.value)};
  
  const dotsDropdownClasses = (id) => classNames('dots-dropdown', {
    'is-open': !isOpenMenu[id]
  });

  const currentStatusClasses = (status) => classNames('current-status', {
    'is-red': status === 20 || status === 5,
    'is-green': status === 15,
    'is-loading': status === 10 || status === 25,
    'is-bold': !isMobile
  });

  const serverClassesMobile = classNames('server-mobile', {
    'is-open': isCollapse.state
  });

  const arrowClassesMobile = (id) => classNames('arrow', {
    'is-revert': +isCollapse.id === +id
  });

  const inputClasses = classNames('input', {
    'is-danger': deleteInputError
  });

  return(
    <div className="MyServers">
      <ToastContainer/>
      {isLoading ? 
        <div className='current-status is-loading is-bigger is-centered'/>
      : 
        <Fragment>
          {isOpenDeleteModal && (
            <ModalDialog 
              isMobile={false}
              title='Delete machine'
              hasClosedButton
              icon="icon-close"
              classNames='is-30rem-width'
              zIndex={1000}
              closeModal={closeDialog}
            >
              <div className='modal-content'>   
                Are you sure you want to delete permanently <span className="is-bold">{deleteServerName}</span>? This action cannot be undone!
                <div className="field mt-3">
                  <label htmlFor="name" className="label input-labels">Confirm machine name</label>
                  <div className="control">
                    <input 
                      onChange={handleDeleteInput}
                      onFocus={removeErrors}
                      className={inputClasses}
                      value={deleteInput}
                      type="text" 
                      name="name" 
                      id="name" 
                      placeholder="Enter your machine name"
                    />
                  </div>
                  {deleteInputError && (<span className="errors">{deleteInputError}</span>)}
                </div>
              </div>
              <footer className="is-flex">
                <button className="button is-align-self-flex-end" onClick={onClickModalDeleteButton}>Delete</button>
              </footer>
            </ModalDialog>
          )}

          <Media                          //MOBILE
            query={{maxWidth: variables.tabletMax}}
            defaultMatches={isMobile}
          >
            <section className="section">
              <div className="container">
                <Link to={routes.myAccount} className="back-arrow-wrapper">
                  <img 
                    src="/images/back-arrow.svg" 
                    alt="back-arrow"
                    className="back-arrow"
                    width={16}
                    height={32}
                  />
                </Link>

                <h1 className="title has-text-centered">
                  <div className="has-text-sky luck is-block">my</div>
                  Machines
                </h1>   

                <div className="servers-wrapper">
                  {initialData.map((el) => (
                    <Fragment key={el.id}>
                      <div className={serverClassesMobile} key={el.id}>
                        <div className="is-flex is-justify-content-space-between">
                          <div className="my-auto">
                            <img 
                              className="is-block mx-auto mb-2"
                              src={`/images/${el.icon}.svg`}  
                              alt="server" 
                              width={36}
                              height={36}
                            />
                            <div className={`luck is-size-5 has-text-${el.color} has-text-centered`}>{el.type}</div>
                          </div>
                          <div>
                            <div className="is-size-3-half is-size-4-touch nowrap line-height-1 mb-3 is-bold">{el.name}</div>
                            <div className="status-wrapper">
                              <span className={currentStatusClasses(el.status)}>{SERVER_STATUS[el.status]}</span>
                            </div>
                          </div>
                          <div>
                            <div className="dots" onClick={onClickDots} id={el.id}>
                              <img 
                                id={el.id}
                                src="/images/menu-dots-vertical.svg" 
                                alt="dots" 
                                width={5}
                                height={24}  
                              />
                            </div>
                            <div className=''>
                              <img 
                                onClick={toggleCollapsing}
                                className={arrowClassesMobile(el.id)}
                                id={el.id}
                                src="/images/arrow-down.png" 
                                alt="arrow" 
                                width={18} 
                                height={9}
                              />
                            </div>
                          </div>
                          <ul className={dotsDropdownClasses(el.id)} id={el.type}>
                            <li 
                              id={el.id} 
                              onClick={+el.status === STATUS_STOPPED ? onClickStartServer : undefined}
                            >
                              Start
                            </li>
                            <li 
                              id={el.id} 
                              onClick={+el.status === STATUS_RUNNING ? onClickStopServer : undefined}
                            >
                              Stop
                            </li>
                            <li 
                              id={el.id} 
                              data-name={el.name} 
                              onClick={onClickDeleteServer}
                            >
                              Delete
                            </li>
                          </ul>
                        </div>

                        {isCollapse.state && isCollapse.id === el.id && (
                          <ul>
                            <li><span className="is-bold mr-2">ID:</span>{el.id}</li>
                            <li><span className="is-bold mr-2">Type:</span>{el.type}</li>
                            <li><span className="is-bold mr-2">RAM:</span>{el.ram}</li>
                            <li><span className="is-bold mr-2">Disc:</span>{el.disk}</li>
                            <li><span className="is-bold mr-2">CPU:</span>{el.cpu}</li>
                            <li><span className="is-bold mr-2">OS:</span>Ubuntu</li>
                            <li><span className="is-bold mr-2">OS Version:</span>22.04 LTS x64</li>
                            <li><span className="is-bold mr-2">IP:</span>{el.ip}</li>
                          </ul>
                        )}
                      </div>
                    </Fragment>
                  ))}
                </div>
              </div>
            </section>
          </Media>

          <Media
            query={{minWidth: variables.desktopMin}}
            defaultMatches={!isMobile}
          >
            <section className="section">   
              <div className="container">
                <div className="label-wrapper">
                  <Link to={routes.myAccount} className="back-arrow-wrapper">
                    <img 
                      src="/images/back-arrow.svg" 
                      alt="back-arrow"
                      className="back-arrow"
                      width={16}
                      height={32} 
                    />
                    <span className="back-arrow-text">Back to My Account</span>
                  </Link>
                  <h1 className="title has-text-centered">
                    <div className="has-text-sky luck is-block">my</div>
                    Machines
                  </h1>   
                </div>
                <div className="servers-wrapper">
                  {initialData.length === 0 && (
                    <div className="has-text-centered">
                      <img 
                        src="/images/database.svg" 
                        className="mb-3"
                        alt="database" 
                        width="72"    
                        height="72"
                      />
                      <div className="is-size-3 is-bold mb-3">
                        :( It looks like you haven’t got any machines yet.
                      </div>
                      <div className="mb-6">You can visit our products page and choose one to get started.</div>
                      <Link to={routes.products} className="button is-large is-fit-content mx-auto">
                        Go to Products
                      </Link>
                    </div>
                  )}
                  {initialData.map((el) => (
                    <div className="server" key={el.id}>
                      <div className="icon-type-wrapper">
                        <img 
                          src={`/images/${el.icon}.svg`}  
                          alt="server" 
                          width={36}
                          height={36}
                        />  
                        <span className={`has-text-${el.color} luck is-size-5`}>{el.type}</span>
                      </div>
                      <div className="label-info-wrapper">
                        <span className="is-size-3-half is-bold">{el.name}</span>
                        <div className="info">
                          <div className="is-size-5"><span className="is-bold mr-2">ID:</span>{el.id}</div>
                          <div className="is-size-5"><span className="is-bold mr-2">Type:</span>{el.type}</div>
                          <div className="is-size-5"><span className="is-bold mr-2">RAM:</span>{el.ram}</div>
                          <div className="is-size-5"><span className="is-bold mr-2">Disc:</span>{el.disk}</div>
                          <div className="is-size-5"><span className="is-bold mr-2">CPU:</span>{el.cpu}</div>
                          <div className="is-size-5"><span className="is-bold mr-2">OS:</span>{el.os}</div>
                          <div className="is-size-5"><span className="is-bold mr-2">IP:</span>{el.ip}</div>
                        </div>
                      </div>
                      <div className="status-wrapper">
                        <span>Status</span>
                        <span className={currentStatusClasses(el.status)}>{SERVER_STATUS[el.status]}</span>
                        {SERVER_STATUS[el.status] === 'Pending Payment' && (
                          <button 
                            className="button is-fit-content mt-2" 
                            data-id={el.id}
                            data-type={el.type} 
                            data-price={el.price} 
                            onClick={onClickPayButton}
                          >
                            Pay now
                          </button>
                        )}
                      </div>
                      <div className="dots" id={el.id} onClick={onClickDots}>
                        <img 
                          className="user pointer-events-none"
                          src="/images/menu-dots-vertical.svg" 
                          alt="dots" 
                          width={5}
                          height={24}  
                        />
                        <ul className={dotsDropdownClasses(el.id)} id={el.type}>
                          <li 
                            id={el.id} 
                            className={`${+el.status !== STATUS_RUNNING && 'is-disabled'}`} 
                            onClick={+el.status === STATUS_STOPPED ? onClickStartServer : undefined}
                          >
                            Start
                          </li>
                          <li 
                            id={el.id} 
                            className={`${+el.status !== STATUS_RUNNING && 'is-disabled'}`} 
                            onClick={+el.status === STATUS_RUNNING ? onClickStopServer : undefined}
                          >
                            Stop
                          </li>
                          <li 
                            id={el.id} 
                            data-name={el.name} 
                            onClick={onClickDeleteServer}
                          >
                            Delete
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </Media>
        </Fragment>
      }

      <style jsx global>{`
        .MyServers {
          flex-grow: 1;
          padding-left: 8.75rem;
          padding-right: 8.75rem;
          margin-top: ${variables.headerMargin};
          background-color: ${variables.paleblue};
          .label-wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .back-arrow-wrapper {
              display: flex;
              align-items: center;
              position: absolute;
              top: 0;
              left: 0;
              padding: 1rem;
              width: 10%;
              cursor: pointer;
              color: black;
              border-radius: ${variables.radiusLarge};
              transition: all 0.5s ease;
              .back-arrow-text {
                display: none;
                margin-left: 1rem;
                white-space: nowrap;
                transition: all 0.6s ease;
              }
              &:hover {
                background: white;
                width: 17%;
                .back-arrow-text {
                  width: fit-content;
                  display: block;
                  white-space: nowrap;
                }
              }
            }
          }
          .servers-wrapper {
            margin-top: 2rem;
            .server {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 1.75rem;
              background: ${variables.paleblue};
              border-radius: ${variables.radiusLarge};
              margin-top: 2rem;
              box-shadow: 20px 20px 35px #47a8bd59,
                          -20px -20px 35px #ffffff;
              transition: all 1s ease;
              .icon-type-wrapper {
                display: flex;
                flex-direction: column; 
                align-items: center;
                justify-content: center;
                gap: 1rem;
              }
              .label-info-wrapper {
                .info {
                  white-space: nowrap;
                  display: flex;
                  justify-content: space-between;
                  gap: 1rem; 
                }
              }
              .status-wrapper {
                display: flex;
                flex-direction: column;
                background: white;
                border-radius: ${variables.radiusLarge};
                padding: 1rem;
                width: 14rem;
                font-size: 1.125rem;
                white-space: nowrap;
              }
            }
          }
          @media all and (min-width: ${screenVariables.desktopMin}) and (max-width: ${screenVariables.widescreenMax}) {
            padding-inline: 2rem;
          }
          @media all and (max-width: ${screenVariables.mobileMax}) {
            height: 100vh;
            padding: 0;
            .section {
              padding: 1rem;
              .container {
                position: relative;
                .back-arrow-wrapper {
                  display: flex;
                  align-items: center;
                  position: absolute;
                  top: 1rem;
                  left: 1rem;
                  width: 1rem;
                  cursor: pointer;
                  color: black;
                  border-radius: ${variables.radiusLarge};
                  transition: all 0.5s ease;
                }
                .label-wrapper {
                  width: 100%;
                }
                .servers-wrapper {
                  height: 100%;
                  .server-mobile {
                    position: relative;
                    width: 100%;
                    margin-bottom: 1.5rem;
                    padding: 0.875rem 1rem;
                    background: #F4F7FA;
                    border-radius: 1rem;
                    box-shadow: 20px 20px 35px #47a8bd59, -20px -20px 35px #ffffff;
                    height: 100%;
                    transition: all 1000ms ease;
                    &.is-open {
                      height: 100%;
                    }
                    .status-wrapper {
                      width: 13rem;
                      padding: 0.5rem 0.75rem;
                      background-color: white;
                      border-radius: ${variables.radiusLarge};
                    }
                    .arrow {
                      position: relative;
                      transition: all 25ms ease;
                      &.is-revert {
                        position: absolute;
                        bottom: 1rem;
                        right: 1rem;
                        transform: rotate(180deg);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}</style>
    </div>
  )
};

export default MyServers;
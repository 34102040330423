import React, {useEffect, useState} from "react";
import {useLocation, Link} from "react-router-dom";
import {BarLoader} from "react-spinners";
import classNames from "classnames";
import variables from "../../assets/sass/variables.scss"
import screenVariables from "../../assets/sass/screensVariables.scss";
import routes from "../../config/routes"
import {confirmEmail} from "../../config/ajax";
import {setCookie} from "../../config/utils";
import {SERVER_ERRORS} from "../../config/constants";

const EMAIL_CONFIRMATION_STATUSES = {
    SUCCESSFUL_VERIFIED: 3,
    ALREADY_VERIFIED: 2,
    EXPIRED_OR_INCORRECT: 1
}

const EmailConfirmation = ({setIsLoggedIn}) => { 
    const [emailConfirmationStatus, setEmailConfirmationStatus] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const search = location.search;
    const token = search.substring(search.lastIndexOf("=") + 1); 

    useEffect(() => {
        confirmEmail(token)
            .then((response) => {
                setIsLoading(false);
                const accessToken = response.data.accessToken;
                const refreshToken = response.data.refreshToken;
                setCookie("accessToken", accessToken, 1);
                setCookie("refreshToken", refreshToken, 1);
                setEmailConfirmationStatus(EMAIL_CONFIRMATION_STATUSES.SUCCESSFUL_VERIFIED); 
                setIsLoggedIn(true);
                window.location.reload(false);
            })
            .catch((error) => {
                if (error?.response?.status === 422 && error?.response?.data?.code === SERVER_ERRORS.ERROR_TOKEN_EXPIRED) {
                    setIsLoading(false);
                    setEmailConfirmationStatus(EMAIL_CONFIRMATION_STATUSES.EXPIRED_OR_INCORRECT);
                } else if (error?.response?.status === 422 && error?.response?.data?.code === SERVER_ERRORS.ERROR_TOKEN_CONFIRMED) {
                    setIsLoading(false);
                    setEmailConfirmationStatus(EMAIL_CONFIRMATION_STATUSES.ALREADY_VERIFIED);
                } else {
                    setIsLoading(false);
                }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loaderWrapperClasses = classNames('loader-wrapper', {
        'is-hidden': !isLoading
    });

    const successfullyVerifiedScreenClasses = classNames({
        "is-hidden": emailConfirmationStatus !== EMAIL_CONFIRMATION_STATUSES.SUCCESSFUL_VERIFIED
    });

    const alreadyVerifiedScreenClasses = classNames({
        "is-hidden": emailConfirmationStatus !== EMAIL_CONFIRMATION_STATUSES.ALREADY_VERIFIED
    });

    const expiredOrIncorrectScreenClasses = classNames({
        "is-hidden": emailConfirmationStatus !== EMAIL_CONFIRMATION_STATUSES.EXPIRED_OR_INCORRECT
    });

    return (
        <div className="EmailConfirmation">
            <div className="container">
                <div className={loaderWrapperClasses}>
                    <BarLoader 
                        color={variables.sky}
                        width={250}
                        height={4}
                    />
                </div>
                
                {(emailConfirmationStatus === EMAIL_CONFIRMATION_STATUSES.EXPIRED_OR_INCORRECT || emailConfirmationStatus === EMAIL_CONFIRMATION_STATUSES.ALREADY_VERIFIED) && (
                    <h1 className="title has-text-centered">
                        ooops...
                        <div className="has-text-sky luck is-block">Something went wrong</div>
                    </h1>
                )}

                {emailConfirmationStatus === EMAIL_CONFIRMATION_STATUSES.SUCCESSFUL_VERIFIED && (
                    <h1 className="title has-text-centered">
                        Email
                        <div className="has-text-sky luck is-block">verified</div>
                    </h1>
                )}

                <div className={successfullyVerifiedScreenClasses}>
                    <div className="logo-wrapper">
                        <object 
                            className="email-confirmed"
                            type="image/svg+xml" 
                            data="/images/email-confirmed-animate.svg"
                            title="email-confirmed"
                            aria-label="email-confirmed"
                            aria-labelledby="email-confirmed"
                            width="400"
                            height="400"
                        />
                    </div>
                    <div className="text-wrapper">
                        <div className="quick-font is-size-3 is-size-4-mobile">Your email has been successfully verified...</div>
                        <div>
                            <Link to={routes.myAccount} className="links is-size-5 is-size-6-mobile">
                                Go to Dashboard
                            </Link>
                        </div>
                    </div>
                </div>

                <div className={alreadyVerifiedScreenClasses}>
                    <div className="logo-wrapper">
                        <object 
                            className="email-confirmed"
                            type="image/svg+xml" 
                            data="/images/expired-token.svg"
                            title="email-confirmed"
                            aria-label="email-confirmed"
                            aria-labelledby="email-confirmed"
                            width="400"
                            height="400"
                        />
                    </div>
                    <div className="text-wrapper">
                        <div className="quick-font is-size-3 is-size-4-mobile">This email has been already verified...</div>
                        <div>
                            <Link to={routes.home} className="links is-size-5 is-size-6-mobile">
                                return home
                            </Link>
                        </div>
                    </div>
                </div>
            
                <div className={expiredOrIncorrectScreenClasses}>
                    <div className="logo-wrapper">
                        <object 
                            className="email-has-expired"
                            type="image/svg+xml" 
                            data="/images/expired-token.svg"
                            title="email-expired"
                            aria-label="email-expired"
                            aria-labelledby="email-expired"
                            width="400"
                            height="400"
                        />
                    </div>
                    <div className="text-wrapper">
                        <div className="quick-font is-size-3 is-size-4-mobile">Looks like the link has expired or is incorrect...</div>
                        <div>
                            <Link to={routes.home} className="links is-size-5 is-size-6-mobile">
                                return home
                            </Link>
                        </div>
                    </div>
                </div> 
            </div>
            <style global jsx>{`
                .EmailConfirmation {
                    flex: 1;
                    margin-top: ${variables.headerMargin};
                    background: ${variables.paleblue};
                    padding: ${variables.desktopPadding};
                    .logo-wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .text-wrapper {
                        text-align: center;
                    }
                    @media all and (max-width: ${screenVariables.mobileMax}) {
                        padding: ${variables.mobilePadding};
                    }
                }
            `}</style>
        </div>
    )
}
export default EmailConfirmation;
import {Fragment, useState} from "react";
import {NavLink, Link} from "react-router-dom";
import Media from "react-media";
import classNames from "classnames";
import Burger from "./Burger";
import {useAppContext} from "../../context/app-context";
import variables from "../../assets/sass/variables.scss";
import {setCookie} from "../../config/utils";
import routes from "../../config/routes";

const Header = (props) => {
  const {isMobile} = useAppContext();
  const {isLoggedIn} = props;    
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const {setLastClickedProduct} = useAppContext();

  const clearCookies = () => {
    setCookie("accessToken", "", 0);
    setCookie("refreshToken", '', 0);
    setCookie("toast-logout", "true", 0.00002000);

    document.cookie = "username=accessToken; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "username=refreshToken; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  };
    
  const logout = () => {
    clearCookies(); 
    setIsNavbarOpen(false);
    setLastClickedProduct(null);
    props.navigate(routes.home, {
      replace: true
    });
  };

  //MOBILE
  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const closeNavbar = () => {
    isNavbarOpen && setIsNavbarOpen(false);
  };

  let navbarClasses = classNames("Header", {
    "open" : isNavbarOpen
  });

  return (
    <header className={navbarClasses}>
      <Media            //MOBILE
        query={{maxWidth: variables.tabletMax}}
        defaultMatches={isMobile}
      >
        <Fragment>
          <div className="navbar-wrapper"> 
            <NavLink to={routes.home}>
              <img 
                className="logo"
                src="/images/rmboto-logo.svg" 
                alt="logo" 
                width="200" 
                height="70"
              />  
            </NavLink>
            <div className='is-flex is-align-items-center'>
              {!isLoggedIn && (
                <Link to={routes.loginOrSignup}>
                  <div className='profile-icon mr-3'/>
                </Link>
              )}
              <Burger 
                isNavbarOpen={isNavbarOpen}
                toggleNavbar={toggleNavbar}
              />
            </div>
          </div>
          {isNavbarOpen && 
            <div className="content-wrapper">
              <NavLink 
                to={routes.home} 
                className='links' 
                onClick={closeNavbar}
              > 
                Home
              </NavLink>
              <NavLink 
                to={routes.products} 
                className='links' 
                onClick={closeNavbar}
              >
                Products
              </NavLink>
              <NavLink 
                to={routes.about} 
                className='links nowrap' 
                onClick={closeNavbar}
              >
                About us
              </NavLink>
              <NavLink 
                to={routes.contacts} 
                className='links'
                onClick={closeNavbar}
              >
                Contacts
              </NavLink>
              {isLoggedIn ? (
                <Fragment>
                  <NavLink 
                    to={routes.myAccount} 
                    className='links nowrap' 
                    onClick={closeNavbar}
                  > 
                    My Account
                  </NavLink>
                  <div 
                    className='links is-clickable' 
                    onClick={logout}
                  >
                    Logout
                  </div> 
                </Fragment>
              ) : (
                <Fragment>
                  <NavLink 
                    to={routes.login} 
                    className='links' 
                    onClick={closeNavbar}
                  > 
                    Login
                  </NavLink>
                  <NavLink 
                    to={routes.signup} 
                    className='button is-large' 
                    onClick={closeNavbar}
                  >
                    SignUp
                  </NavLink>
                </Fragment> 
              )}
            </div>
          }
        </Fragment>
      </Media>

      <Media
        query={{minWidth: variables.desktopMin}}
        defaultMatches={!isMobile}
      >
        <Fragment>
          <NavLink to={routes.home}>
            <img 
              className="logo"
              src="/images/rmboto-logo.svg" 
              alt="logo" 
              width="200" 
              height="70"
            />  
          </NavLink>
          <div className="links-wrapper">
            <NavLink to={routes.home} className='links'> 
              Home
            </NavLink>
            <NavLink 
              to={routes.products} 
              className='links' 
            >
              Products
            </NavLink>
            <NavLink to={routes.about} className='links nowrap'>
              About us
            </NavLink>
            <NavLink to={routes.contacts} className='links'>
              Contacts
            </NavLink>
            {isLoggedIn ? (
              <Fragment>
                <NavLink to={routes.myAccount} className='links nowrap'> 
                  My Account
                </NavLink>
                <div className='links is-clickable' onClick={logout}>
                  Logout
                </div> 
              </Fragment>
            ) : (
              <Fragment>
                <NavLink to={routes.login} className='links'> 
                  Login
                </NavLink>
                <NavLink to={routes.signup} className='button'>
                  SignUp
                </NavLink>
              </Fragment> 
            )}
          </div>
        </Fragment>
      </Media>

      <style jsx global>{`
        .Header {
          z-index: 1000;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 2rem;
          background: rgba(255, 255, 255, 0.56);
          backdrop-filter: blur(20px);
          -webkit-backdrop-filter: blur(20px);
          box-shadow: 0px 3px 10px #0000000D;
          .logo {
            padding: 1rem;
          }
          .links-wrapper {
            display: flex;
            align-items: center;
            gap: 0.75rem;
            .products-menu {
              display: flex;
              flex-wrap: wrap;
              gap: 0.25rem;
              position: absolute;
              top: 1.5rem;
              left: 0;
              min-width: 21rem;
              background: white;
              padding: 0.75rem;
              box-shadow: 0px 2px 10px #0000000D;
              border-radius: ${variables.radiusLarge};
              .product {
                min-width: 9.5rem;
                max-width: auto;
                padding: 0.25rem;
                &:hover {
                  background: ${variables.paleblue};
                  border-radius: ${variables.radius};
                }
              }
            }
          }
          @media all and (max-width: ${variables.mobileMax}) { 
            padding: 0;
            display: flex;
            height: ${variables.headerMargin};
            flex-direction: column;
            justify-content: flex-start;
            transition: all 250ms ease;
            &.open {
              height: 100%;
            }
            .navbar-wrapper { 
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              height: fit-content;
              padding: 0 1rem;
              margin-top: 1rem;
              .logo {
                padding: 0;
                width: 8.625rem;
              }
              .profile-icon {
                width: 30px;
                height: 30px;
                padding: 1.5rem;
                border-radius: 50%;
                background-color: #F4F7FA;
                background-image: url('/images/circle-user-black.svg');
                background-position: center;
                background-repeat: no-repeat;
              }
            }
            .content-wrapper {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 1.5rem;
              .links {
                font-size: 2.25rem;
              }
              .delete-wrapper {
                width: 1.875rem;
                height: 2rem;
              }
            }
          }
        }
      `}</style>
    </header>
  )
}

export default Header;